import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import FormHandler from '@/components/ui/FormHandler/FormHandler';

const FormSingleVideo = ({ parameters = {}, onParametersChange, onQuerySubmit }) => {
    // Effect: Set default values
    useLayoutEffect(() => {
        const nextParameters = {};
        nextParameters.videoID = parameters.videoID || '';
        nextParameters.submit = parameters.submit || 'Submit';
        onParametersChange(nextParameters);
    }, []);

    // Event Handlers
    const onChange = (name, value) => {
        const nextParameters = { ...parameters };
        nextParameters[name] = value;
        // Trim video ID
        if (name === 'videoID') {
            nextParameters.videoID = value.trim();
        }
        onParametersChange(nextParameters);
    };

    // Constants
    const fields = [
        {
            name: 'videoID',
            label: 'Video ID',
            type: 'text',
            required: true,
            wrapperClass: 'wrapper lg',
            inputClass: 'input',
            value: parameters.videoID,
        },
        {
            name: 'submit',
            type: 'submit',
            value: parameters.submit,
        },
    ];

    return (
        <div className='queryForm'>
            <FormHandler
                fields={fields}
                formOptions={{
                    formClass: 'formStyleB',
                }}
                callbacks={{
                    onSubmit: (data) => onQuerySubmit(data),
                    onChange: onChange,
                }}
            />
        </div>
    );
};

FormSingleVideo.propTypes = {
    parameters: PropTypes.object,
    onParametersChange: PropTypes.func.isRequired,
    onQuerySubmit: PropTypes.func.isRequired,
};

export default FormSingleVideo;
