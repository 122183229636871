import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setCurrentAppPath } from '@/services/app';

const PathHandler = () => {
    // Selector
    const currentAppPath = useSelector((state) => state.app.currentAppPath);

    // useLocation
    const location = useLocation();
    const pathname = location.pathname;

    useEffect(() => {
        // Update store app path
        if (currentAppPath !== pathname) {
            setCurrentAppPath(pathname);
        }
    }, [pathname]);

    return '';
};

export default PathHandler;
