import React, { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { initApp } from '@/services/app';
import MainRouter from '@/router/MainRouter';
import Loader from '@/pages/system/Loader/Loader';
import classnames from 'classnames';

const App = () => {
    // Selectors
    const appReady = useSelector((state) => state.app.appReady);

    // Effects
    useLayoutEffect(() => {
        // Init App
        initApp().then();
    }, []);

    // Render
    return (
        <main id='main' className={classnames('App', 'theme-light')}>
            {appReady ? <MainRouter /> : <Loader size='large' />}
        </main>
    );
};

export default App;
