import React from 'react';
import { Route, Routes } from 'react-router-dom';
import * as endpoints from './_endpoints';
import PathHandler from './PathHandler';
import RedirectHandler from './RedirectHandler';
import PrivateRoute from './PrivateRoute';
import NotFound from '@/pages/system/NotFound/NotFound';
import Dashboard from '@/pages/authenticated/Dashboard/Dashboard';
import Login from '@/pages/account/Login';
import ForgotPassword from '@/pages/account/ForgotPassword';
import ResetPassword from '@/pages/account/ResetPassword';
import FormHandlerTestPage from '@/pages/test/FormHandlerTestPage';

const MainRouter = () => {
    return (
        <>
            <RedirectHandler />
            <PathHandler />

            <Routes>
                {/* Private Routes */}
                <Route path='/*' element={<PrivateRoute element={<Dashboard />} />} />
                <Route path={endpoints.DASHBOARD + '*'} element={<PrivateRoute element={<Dashboard />} />} />

                {/* Test Pages*/}
                <Route path={endpoints.TEST_FORM_HANDLER} element={<PrivateRoute element={<FormHandlerTestPage />} />} />

                {/* Public Routes */}
                <Route path={endpoints.LOGIN} element={<Login />} />
                <Route path={endpoints.FORGOT_PASSWORD} element={<ForgotPassword />} />
                <Route path={endpoints.RESET_PASSWORD} element={<ResetPassword />} />
                <Route path='*' element={<NotFound />} />
            </Routes>
        </>
    );
};

export default MainRouter;
