import { createSlice } from '@reduxjs/toolkit';
import { actionResetStore } from '../actions';

const initialState = [];
const blankValue = [];

const slice = createSlice({
    name: 'reportItems',
    initialState: initialState,
    reducers: {
        fetchedReportItems: (state, action) => {
            const index = state.findIndex((object) => {
                return object.key === action.payload.key;
            });
            if (index === -1) {
                state.push({ key: action.payload.key, data: action.payload.data });
            } else {
                state[index] = { key: action.payload.key, data: action.payload.data };
            }
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(actionResetStore, (state, action) => {
            return initialState;
        });
    },
});

// Actions
export const { fetchedReportItems } = slice.actions;

// Reducer
export default slice.reducer;

// Selectors
export const selectorReportItems = (state, key) => {
    const entry = state.reportItems.find((object) => {
        return object.key == key;
    });
    return entry ? entry.data : blankValue;
};
