import {
    clearTimeAndTimezoneFromDate,
    offsetDateByDays,
    percentageIncreaseDecrease,
    valueRatioToTotal
} from "@/util/format";

export const getSalesRelativeToDate = (sales, date, offsetDays) => {
    const anchorTime = new Date( date ).getTime();
    const cutoffTime = anchorTime + (offsetDays * 86400 * 1000);

    const startTime = anchorTime > cutoffTime ? cutoffTime : anchorTime;
    const endTime = anchorTime > cutoffTime ? anchorTime : cutoffTime;

    const periodSales = [];

    for( const sale of sales ){
        const saleTime = new Date( sale.datetime ).getTime();
        if( saleTime >= startTime && saleTime < endTime ){
            periodSales.push(sale);
        }else if( saleTime >= endTime ){
            continue;
        }
    }

    return periodSales;
}

export const processVideoArray = (videos, sales, analyticsDay, salesDays) => {

    return videos.map( (video) => {
        const videoObj = {};
        const publishDate = new Date( video.datetime );

        videoObj['title'] = video["title"];
        videoObj['description'] = video["content"];
        videoObj['videoId'] = video["postID"];
        videoObj['url'] = video["meta"].url;
        videoObj['thumbnailUrl'] = video["meta"].hasOwnProperty("thumbnails") ? video["meta"].thumbnails.medium.url : "";
        videoObj['publishDate'] = video["datetime"];
        videoObj['preSales'] = getSalesRelativeToDate( sales, publishDate, -salesDays );
        videoObj['postSales'] = getSalesRelativeToDate( sales, publishDate, salesDays );
        videoObj['salesDiff'] = percentageIncreaseDecrease( videoObj['preSales'].length, videoObj['postSales'].length );
        videoObj['analytics'] = getVideoAnalytics( video, analyticsDay );

        return videoObj;
    } );
}

export const getVideoAnalytics = (video, daysSincePublishing) => {

    const publishDate = new Date( video.datetime );
    const offsetDate = offsetDateByDays(publishDate, daysSincePublishing);
    const targetDate = clearTimeAndTimezoneFromDate(offsetDate);


    // Process Analytics
    const analytics = {
        views: 0,
        averageViewDuration: null,
        averageViewPercentage: null,
        estimatedMinutesWatched: 0,
        subscribersGained: 0,
        subscribersLost: 0,
        likes: 0,
        dislikes: 0,
        shares: 0,
        comments: 0
    }

    const filteredAnalytics = video.meta.analytics.filter( (analyticsEntry) => {
        const screenshotDate = new Date( analyticsEntry.day );
        return screenshotDate.getTime() <= targetDate.getTime();
    });

    const averageViewDurationArray = [];
    const averageViewPercentageArray = [];

    filteredAnalytics.forEach( (analyticsEntry) => {
        analytics.views += analyticsEntry.views;
        analytics.estimatedMinutesWatched += analyticsEntry.estimatedMinutesWatched;
        analytics.subscribersGained += analyticsEntry.subscribersGained;
        analytics.subscribersLost += analyticsEntry.subscribersLost;
        analytics.likes += analyticsEntry.likes;
        analytics.dislikes += analyticsEntry.dislikes;
        analytics.shares += analyticsEntry.shares;
        analytics.comments += analyticsEntry.comments;

        averageViewDurationArray.push( analyticsEntry.averageViewDuration );
        averageViewPercentageArray.push( analyticsEntry.averageViewPercentage );
    });

    analytics.averageViewDuration = averageViewDurationArray.reduce((acc, value) => acc + value, 0) / averageViewDurationArray.length;
    analytics.averageViewPercentage = Math.round(averageViewPercentageArray.reduce((acc, value) => acc + value, 0) / averageViewPercentageArray.length);

    analytics.netSubscribers = analytics.subscribersGained - analytics.subscribersLost;
    analytics.likeDislikeRatio = valueRatioToTotal( analytics.likes, analytics.dislikes );

    return analytics;
}
