import React from 'react';
import PropTypes from 'prop-types';
import { pdf } from '@react-pdf/renderer';
import { createPDFFileDownload } from '@/util/files';
import { Button } from '@/components/ui/FormFields';

// Create Document Component
const ExportReportPDF = ({ buttonTitle, document, filename }) => {
    const onExport = async () => {
        // Open new tab with PDF
        const blob = await pdf(document).toBlob();
        createPDFFileDownload(blob, filename);
    };

    return (
        <Button
            field={{
                name: 'exportButtonPdf',
                value: buttonTitle,
                inputClass: 'primary',
                wrapperClass: 'exportButton',
                onClick: onExport,
            }}
        />
    );
};

ExportReportPDF.propTypes = {
    buttonTitle: PropTypes.string.isRequired,
    document: PropTypes.element.isRequired,
    filename: PropTypes.string.isRequired,
};

export default ExportReportPDF;
