import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './DropdownButton.module.scss';
import { hideFadeOut, showFadeIn } from '@/animations';

/** Renders a button that reveals the items on dropdown */
const DropdownButton = (props) => {
    // State
    const [showDropdown, setShowDropdown] = useState(false);

    // Refs
    const refDropdownButton = React.createRef();
    const refDropdown = React.createRef();

    // Register click event
    useEffect(() => {
        document.addEventListener('mousedown', eventClicked);
        return () => {
            document.removeEventListener('mousedown', eventClicked);
        };
    });

    // Animation
    useEffect(() => {
        showDropdown ? showFadeIn(refDropdown.current, 0) : hideFadeOut(refDropdown.current, 0);
    }, [showDropdown]);

    // Event Listener
    const eventClicked = (e) => {
        if (
            refDropdownButton.current &&
            !refDropdownButton.current.contains(e.target) &&
            refDropdown.current &&
            !refDropdown.current.contains(e.target)
        ) {
            setShowDropdown(false);
        }
    };

    const dropdownItems = props.children.map((item, key) => {
        return (
            <div
                key={key}
                className='dropdownItem'
                onClick={() => {
                    setShowDropdown(false);
                }}>
                {item}
            </div>
        );
    });

    return (
        <div className={classnames(styles.module, props.className)}>
            <button
                ref={refDropdownButton}
                className={showDropdown ? 'active' : ''}
                onClick={() => {
                    setShowDropdown(!showDropdown);
                }}>
                {props.label}
                <span className='icon'>
                    <i className='fas fa-caret-down' />
                </span>
            </button>
            <div ref={refDropdown} className='dropdown'>
                {dropdownItems}
            </div>
        </div>
    );
};

DropdownButton.propTypes = {
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
};

export default DropdownButton;
