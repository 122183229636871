import React, { useState, useEffect, Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './LogViewer.module.scss';
import { useSelector } from 'react-redux';
import { selectorLogEntries } from '@/store/slices/logEntries';
import { selectorToolSettings } from '@/store/slices/tools';
import Pagination from '../Pagination';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const LogViewer = ({ tool }) => {
    // State
    const [currentPage, setCurrentPage] = useState(1);
    const [pageLimit, setPageLimit] = useState(100);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [selectedLogBook, setSelectedLogBook] = useState(null);
    const [selectedLogGroup, setSelectedLogGroup] = useState(null);
    const [logCount, setLogCount] = useState(0);

    // Selector
    const logEntries = useSelector((state) => selectorLogEntries(state, tool.uid));
    const settings = useSelector((state) => selectorToolSettings(state, tool.uid));

    // Constants
    const pageCount = useMemo(() => Math.ceil(logCount / pageLimit), [logCount]);

    // Pagination
    const pagination = {
        page: currentPage,
        pageSize: pageLimit,
    };

    // Get log entries
    useEffect(() => {
        tool.getLogEntries(selectedLogBook, selectedLogGroup, pagination).then((res) => {
            if (res.pagination) {
                setLogCount(res.pagination.total);
            }
            setExpandedKeys([]);
        });
    }, [currentPage, pageLimit, selectedLogBook, selectedLogGroup]);

    // Toggle expanded
    const toggleExpanded = (key) => {
        if (expandedKeys.includes(key)) {
            setExpandedKeys(expandedKeys.filter((activeKey) => activeKey !== key));
        } else {
            setExpandedKeys([...expandedKeys, key]);
        }
    };

    // Expand all
    const expandAll = () => {
        setExpandedKeys(logEntries.map((entry) => entry.id));
    };

    // Collapse all
    const collapseAll = () => {
        setExpandedKeys([]);
    };

    // Table data rows
    const logEntryRows = logEntries.map((entry) => {
        const dataString = JSON.stringify(entry.data, null, 2);
        const date = new Date(entry.datetime);
        const dateString = date.toLocaleDateString();
        const timeString = date.toLocaleTimeString();

        return (
            <Fragment key={entry.id}>
                <div className='gridItem itemStyleCell' onClick={() => toggleExpanded(entry.id)}>
                    {dateString} {timeString}
                </div>
                <div className='gridItem itemStyleCell' onClick={() => toggleExpanded(entry.id)}>
                    {entry.label}
                </div>
                <div className='gridItem itemStyleCell' onClick={() => toggleExpanded(entry.id)}>
                    <span
                        className='editAction'
                        onClick={(e) => {
                            e.stopPropagation();
                            setSelectedLogBook(entry.logbook);
                            setSelectedLogGroup(null);
                        }}>
                        {entry.logbook}
                    </span>
                </div>
                <div className='gridItem itemStyleCell' onClick={() => toggleExpanded(entry.id)}>
                    <span
                        className='editAction'
                        onClick={(e) => {
                            e.stopPropagation();
                            setSelectedLogBook(entry.logbook);
                            setSelectedLogGroup(entry.group);
                        }}>
                        {entry.group}
                    </span>
                </div>
                {expandedKeys.includes(entry.id) && (
                    <div className='gridItem itemStyleCell spanRow'>
                        <SyntaxHighlighter language='json' style={vs2015}>
                            {dataString}
                        </SyntaxHighlighter>
                    </div>
                )}
            </Fragment>
        );
    });

    return (
        <div className={styles.module}>
            <div className='logEntryTable'>
                <div className='gridItem itemStyleCell spanRow filters'>
                    <div className='filterWrapper'>
                        <span>
                            <select
                                value={selectedLogBook ? selectedLogBook : ''}
                                onChange={(e) => {
                                    const nextLogbook = e.target.value ? e.target.value : null;
                                    setSelectedLogBook(nextLogbook);
                                }}>
                                <option value=''>All Logbooks</option>
                                {settings.logBooks.map((logBook) => {
                                    return (
                                        <option key={logBook.key} value={logBook.key}>
                                            {logBook.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </span>
                        <span>
                            <span>Search</span>
                            <input
                                type='text'
                                value={selectedLogGroup ? selectedLogGroup : ''}
                                onChange={(e) => setSelectedLogGroup(e.target.value)}
                                placeholder='Search Group'
                            />
                        </span>
                        <span
                            className='editAction'
                            onClick={() => {
                                setSelectedLogBook(null);
                                setSelectedLogGroup(null);
                                setCurrentPage(1);
                            }}>
                            Clear Filters
                        </span>
                        <span className='editAction' onClick={() => expandAll()}>
                            Expand All
                        </span>
                        <span className='editAction' onClick={() => collapseAll()}>
                            Collapse All
                        </span>
                    </div>
                    <div className='filterWrapper'>
                        <Pagination page={currentPage} limit={pageLimit} total={logCount} setPage={setCurrentPage} setLimit={setPageLimit} />
                    </div>
                </div>
                <div className='gridItem itemStyleHeader'>Datetime</div>
                <div className='gridItem itemStyleHeader'>Label</div>
                <div className='gridItem itemStyleHeader'>Logbook</div>
                <div className='gridItem itemStyleHeader'>Group</div>
                {logEntryRows}
            </div>
        </div>
    );
};

LogViewer.propTypes = {
    tool: PropTypes.object.isRequired,
};

export default LogViewer;
