import store from '@/store';
import { fetchedReports, updatedReport, deletedReport } from '@/store/slices/reports';
import { fetchedReportItems } from '@/store/slices/reportItems';
import * as api from '@/services/strapi/rest';
import { logError } from '@/util/debugger';

/** Default Query */
export const defaultQuery = {
    pagination: {
        page: 1,
        pageSize: 100,
    },
};

// Count reports
export const countReports = async (uid, body = new FormData()) => {
    const endpoint = `${uid}/reports/count`;
    const response = await api.restStrapiPostRequest(endpoint, true, body);
    if (response.error) {
        logError(response);
        return { error: response.error.message };
    } else {
        return response.count;
    }
};

// Get historical reports
export const getReports = async (uid, query = {}) => {
    // Create query string
    const queryString = api.createQueryString(defaultQuery, query);

    const endpoint = `${uid}/reports/?${queryString}`;
    const response = await api.restStrapiGetRequest(endpoint, true);
    if (response.error) {
        logError(response);
        return { error: response.error.message };
    } else {
        store.dispatch(fetchedReports({ key: uid, data: response.results }));
        return response;
    }
};

// Schedule report
export const scheduleReport = async (uid, id = null, body = new FormData()) => {
    const endpoint = `${uid}/schedule-report${id ? `/${id}` : ''}`;
    const response = await api.restStrapiPostRequest(endpoint, true, body);
    if (response.error) {
        logError(response.error);
        return { error: response.error.message };
    } else {
        store.dispatch(updatedReport({ key: uid, data: response }));
        return response;
    }
};

// Edit existing report
export const editReport = async (uid, id, body = new FormData()) => {
    const endpoint = `${uid}/report/${id}`;
    const response = await api.restStrapiPutRequest(endpoint, true, body);
    if (response.error) {
        logError(response.error);
        return { error: response.error.message };
    } else {
        store.dispatch(updatedReport({ key: uid, data: response }));
        return response;
    }
};

// Delete existing report
export const deleteReport = async (uid, id) => {
    const endpoint = `${uid}/report/${id}`;
    const response = await api.restStrapiDeleteRequest(endpoint, true);
    if (response.error) {
        logError(response.error);
        return { error: response.error.message };
    } else {
        store.dispatch(deletedReport({ key: uid, data: response }));
        return response;
    }
};

// Stop report
export const stopReport = async (uid, id) => {
    const endpoint = `${uid}/report/${id}/stop`;
    const response = await api.restStrapiPostRequest(endpoint, true);
    if (response.error) {
        logError(response.error);
        return { error: response.error.message };
    } else {
        store.dispatch(updatedReport({ key: uid, data: response }));
        return response;
    }
};

// Resume report
export const resumeReport = async (uid, id) => {
    const endpoint = `${uid}/report/${id}/resume`;
    const response = await api.restStrapiPostRequest(endpoint, true);
    if (response.error) {
        logError(response.error);
        return { error: response.error.message };
    } else {
        store.dispatch(updatedReport({ key: uid, data: response }));
        return response;
    }
};

// Retry report
export const retryReport = async (uid, id) => {
    const endpoint = `${uid}/report/${id}/retry`;
    const response = await api.restStrapiPostRequest(endpoint, true);
    if (response.error) {
        logError(response.error);
        return { error: response.error.message };
    } else {
        store.dispatch(updatedReport({ key: uid, data: response }));
        return response;
    }
};

// Ger report items
export const getReportItems = async (uid, id, query = {}, body = new FormData()) => {
    const results = [];
    let total = 0;
    let pages = 1;

    // Check if pagination pageSize is larger than 1000
    if (query.pagination && query.pagination.pageSize > 1000) {
        pages = Math.ceil(query.pagination.pageSize / 1000);
        query.pagination.page = 1;
        query.pagination.pageSize = 1000;
    }

    // Loop through pages
    for (let i = 0; i < pages; i++) {
        // Set Page
        query.pagination.page += i;
        // Create query string
        const queryString = api.createQueryString(defaultQuery, query);
        const endpoint = `${uid}/report/${id}/get-report-items/?${queryString}`;
        const response = await api.restStrapiPostRequest(endpoint, true, body);
        if (response.error) {
            logError(response.error);
            return { error: response.error.message };
        } else {
            results.push(...response.results);
            total = response.pagination.total;
            if (results.length >= total) {
                break;
            }
        }
    }

    store.dispatch(fetchedReportItems({ key: id, data: results }));
    return { results, pagination: { total: total } };
};
