import React from 'react';
import { useSelector } from 'react-redux';
import * as endpoints from '@/router/_endpoints';
import { filterCompileMenuItems } from '@/components/nav/_navFunctions';
import MenuList from '@/components/nav/partials/MenuList';

const MenuTopRight = () => {
    // User
    const user = useSelector((state) => state.auth.user);

    // Menu model
    const menuItems = [
        {
            id: 'userDisplayName',
            label: user ? user.displayName : 'No User',
            icon: 'fas fa-user-circle',
            hideDropdownOnClickOutside: true,
            submenu: [
                {
                    id: 'logout',
                    label: 'Logout',
                    link: endpoints.LOGOUT,
                },
            ],
        },
    ];

    const hooks = {
        afterItemLabel: (item) => (item.icon ? <span className='icon'>{item.icon ? <i className={item.icon} /> : ''}</span> : ''),
    };

    const filteredItems = filterCompileMenuItems(menuItems);

    return <MenuList menuItems={filteredItems} hooks={hooks} />;
};

export default MenuTopRight;
