import React, { useLayoutEffect, createRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { getAncestorItems } from '@/components/nav/_navFunctions';
import MenuItem from '@/components/nav/partials/MenuItem';
import MenuDropdown from '@/components/nav/partials/MenuDropdown';

const MenuList = ({ menuItems, hooks = {} }) => {
    // Selectors
    const currentPath = useSelector((state) => state.app.curentPath);
    const currentUser = useSelector((state) => state.auth.user);

    // State
    const [renderedItems, setRenderedItems] = useState([]);

    // Hook: Create rendered items from menu items property
    useLayoutEffect(() => {
        // Filter items that are restricted;
        const accessibleItems = menuItems.filter((item) => {
            if (item.hasOwnProperty('roleAccess')) {
                return item.roleAccess.includes(currentUser.role.type);
            }
            return true;
        });

        // Create refs for each item
        const nextRenderedItems = accessibleItems.map((item) => {
            return { ...item, ref: createRef() };
        });

        setRenderedItems(nextRenderedItems);
    }, [menuItems]);

    // Hook: Update rendered items based on current path
    useLayoutEffect(() => {
        if (renderedItems.length === 0) {
            return;
        }

        let nextRenderedItems = renderedItems.map((item) => {
            const isCurrent = item.link && currentPath.startsWith(item.link);
            return { ...item, isCurrent: isCurrent };
        });

        const currentItems = nextRenderedItems.filter((item) => item.isCurrent);

        currentItems.forEach((currentItem) => {
            const parentItems = getAncestorItems(nextRenderedItems, currentItem);
            const parentIds = parentItems.map((item) => item.id);
            nextRenderedItems = nextRenderedItems.map((item) => {
                return {
                    ...item,
                    hasCurrent: parentIds.includes(item.id),
                };
            });
        });

        if (hooks.filterBeforeItemsRender) {
            nextRenderedItems = hooks.filterBeforeItemsRender(nextRenderedItems);
        }

        setRenderedItems(nextRenderedItems);
    }, [currentPath]);

    // Event: On item click
    const onItemClick = (clickedItem) => {
        const nextRenderedItems = renderedItems.map((item) => {
            if (item.id === clickedItem.id) {
                return item.submenu ? { ...item, showDropdown: !item.showDropdown } : item;
            } else {
                return item;
            }
        });
        setRenderedItems(nextRenderedItems);
    };

    // Event: On click outside dropdown
    const onClickOutsideDropdown = (clickedItem) => {
        if (clickedItem.hideDropdownOnClickOutside) {
            const nextRenderedItems = renderedItems.map((item) => {
                if (item.id === clickedItem.id) {
                    return { ...item, showDropdown: false };
                } else {
                    return item;
                }
            });
            setRenderedItems(nextRenderedItems);
        }
    };

    // Render function
    const renderMenu = (items) => {
        const renderMenuItem = (item) => {
            const submenuItems = items.filter((submenuItem) => submenuItem.parentID === item.id && item.id !== 0);
            const className = classnames(
                'menuItem',
                item.class,
                item.isCurrent && 'isCurrent',
                item.hasCurrent && 'hasCurrent',
                item.showDropdown && 'showDropdown'
            );
            return (
                <li ref={item.ref} key={item.id} className={className}>
                    <MenuItem item={item} hooks={{ ...hooks, onClick: () => onItemClick(item) }} />
                    {submenuItems.length > 0 && (
                        <MenuDropdown refParent={item.ref} show={item.showDropdown} hooks={{ onClickOutside: () => onClickOutsideDropdown(item) }}>
                            {renderSubmenuItems(submenuItems)}
                        </MenuDropdown>
                    )}
                </li>
            );
        };

        const renderSubmenuItems = (submenuItems) => {
            return submenuItems.map((item) => {
                return renderMenuItem(item);
            });
        };

        const topLevelItems = items.filter((item) => item.parentID === 0);
        return topLevelItems.map((item) => renderMenuItem(item));
    };

    return <ul className='menuList'>{renderMenu(renderedItems)}</ul>;
};

MenuList.propTypes = {
    menuItems: PropTypes.array.isRequired,
    hooks: PropTypes.shape({
        beforeItemLabel: PropTypes.func,
        afterItemLabel: PropTypes.func,
        filterBeforeItemsRender: PropTypes.func,
    }),
};

export default MenuList;
