import React, { useState, useLayoutEffect, Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectorCurrentUserPermissions } from '@/store/slices/auth';
import { userHasPolicies } from '@/services/strapi/permissions';
import { useIsMounted } from '@/util/hooks';
import FormHandler from '@/components/ui/FormHandler/FormHandler';
import { syncNowFormFields, setDefaultSyncFields, createCronJobFields } from './_forms';
import LoadingIcon from '@/components/ui/LoadingIcon/LoadingIcon';
import { SegmentLine } from '@/components/ui';
import styles from './SyncSettings.module.scss';

const SyncSettings = ({ account }) => {
    // State
    const [fetching, setFetching] = useState(true);
    const [accountSyncCronJobs, setAccountSyncCronJobs] = useState([]);
    const [accountSyncConfig, setAccountSyncConfig] = useState([]);

    // Selectors
    const user = useSelector((state) => state.auth.user);
    const currentUserPermissions = useSelector((state) => selectorCurrentUserPermissions(state, account.slug));

    // Hooks
    const isMounted = useIsMounted();

    useLayoutEffect(() => {
        account.getSyncCronJobs().then((data) => {
            setAccountSyncCronJobs(data);
            isMounted() && setFetching(false);
        });

        account.getSyncConfig().then((data) => {
            setAccountSyncConfig(data);
            isMounted() && setFetching(false);
        });
    }, [account]);

    // On Sync Now Submit
    const onSyncNowSubmit = async (fields) => {
        const res = await account.startCustomDataSync(fields);

        if (res.error) {
            return {
                status: 'error',
                message: `Error: ${res.error}`,
                clearFields: false,
            };
        } else {
            return {
                status: 'success',
                message: 'Sync started.',
                clearFields: true,
            };
        }
    };

    // On Cron Form Submit
    const onCronFormSubmit = async (fields) => {
        const res = await account.addSyncCronJob(fields);

        if (res.error) {
            return {
                status: 'error',
                message: `Error: ${res.error}`,
                clearFields: false,
            };
        } else {
            return {
                status: 'success',
                message: 'Cron Job Created',
                clearFields: true,
            };
        }
    };

    // On Default Sync Submit
    const onDefaultSyncSubmit = async (fields) => {
        const res = await account.setDefaultSync(fields);

        if (res.error) {
            return {
                status: 'error',
                message: `Error: ${res.error}`,
                clearFields: false,
            };
        } else {
            return {
                status: 'success',
                message: 'Default Sync Set',
                clearFields: false,
            };
        }
    };

    // On Delete Cron Job
    const onDeleteCronJob = async (key) => {
        await account.deleteSyncCronJob(key);
    };

    // Set Default Sync Form
    const renderDefaultSyncForm = useCallback(() => {
        const fields = setDefaultSyncFields.map((field) => {
            const thisField = { ...field };
            if (accountSyncConfig['defaultSync'] && accountSyncConfig['defaultSync'].hasOwnProperty(field.name)) {
                thisField.value = accountSyncConfig['defaultSync'][field.name];
            }
            return thisField;
        });
        return (
            <div className='cronJobForm'>
                <FormHandler
                    fields={fields}
                    formOptions={{
                        formClass: 'formStyleB',
                    }}
                    callbacks={{
                        onSubmit: onDefaultSyncSubmit,
                    }}
                />
            </div>
        );
    }, [accountSyncConfig]);

    // Set Default Sync Form
    const renderSyncNowForm = () => {
        return (
            <>
                <p>Run a custom sync manually.</p>
                <div className='cronJobForm'>
                    <FormHandler
                        fields={syncNowFormFields}
                        formOptions={{
                            formClass: 'formStyleB',
                        }}
                        callbacks={{
                            onSubmit: onSyncNowSubmit,
                        }}
                    />
                </div>
            </>
        );
    };

    // Add Cron Job Form
    const renderCronForm = () => {
        return (
            <>
                <h6>Create New Scheduled Sync</h6>
                <div className='cronJobForm'>
                    <FormHandler
                        fields={createCronJobFields}
                        formOptions={{
                            formClass: 'formStyleB',
                        }}
                        callbacks={{
                            onSubmit: onCronFormSubmit,
                        }}
                    />
                </div>
            </>
        );
    };

    const renderDefaultSyncOverview = () => {
        if (!accountSyncConfig.hasOwnProperty('defaultSync')) {
            return <p>No default sync has been set.</p>;
        } else {
            const defaultSync = accountSyncConfig.defaultSync;
            if (defaultSync.type && defaultSync.type === 'full') {
                return <h6>Current Behavior: Full data sync {defaultSync.cleanSync ? 'with' : 'without'} deletion of unmatched items.</h6>;
            } else if (defaultSync.type && defaultSync.type === 'partial') {
                return <h6>Current Behavior: Partial data sync of the last {defaultSync.hours} hours.</h6>;
            } else {
                return <p>Invalid Behavior</p>;
            }
        }
    };

    const renderCronJobsOverview = () => {
        const cronJobsTable = () => {
            const types = createCronJobFields.find((field) => field.name === 'type').options;
            const cronTimes = createCronJobFields.find((field) => field.name === 'cronTime').options;
            const cronJobs = [];

            for (const option of cronTimes) {
                if (accountSyncCronJobs.hasOwnProperty(option.value)) {
                    const cronJobObj = accountSyncCronJobs[option.value];
                    cronJobs.push({
                        type: cronJobObj.type,
                        typeLabel: types.find((type) => type.value === cronJobObj.type).name,
                        frequency: option.value,
                        frequencyLabel: option.name,
                        hours: !cronJobObj.hours || cronJobObj.hours === 0 ? '-' : cronJobObj.hours,
                        cleanSync: cronJobObj.cleanSync ? <i className='fas fa-check'></i> : <i className='fas fa-times'></i>,
                    });
                }
            }

            return cronJobs.map((cronJob) => {
                return (
                    <Fragment key={cronJob.frequency}>
                        <div className='gridItem itemStyleCell'>{cronJob.frequencyLabel}</div>
                        <div className='gridItem itemStyleCell'>{cronJob.typeLabel}</div>
                        <div className='gridItem itemStyleCell centerAlign'>{cronJob.hours}</div>
                        <div className='gridItem itemStyleCell centerAlign'>{cronJob.cleanSync}</div>
                        <div className='gridItem itemStyleCell centerAlign'>
                            <span className='editAction' onClick={() => onDeleteCronJob(cronJob.frequency)}>
                                Delete
                            </span>
                        </div>
                    </Fragment>
                );
            });
        };

        return (
            <>
                <div className='cronList'>
                    <div className='gridItem itemStyleHeader'>Frequency</div>
                    <div className='gridItem itemStyleHeader'>Type</div>
                    <div className='gridItem itemStyleHeader centerAlign'>Hours</div>
                    <div className='gridItem itemStyleHeader centerAlign'>Clean Sync</div>
                    <div className='gridItem itemStyleHeader centerAlign'>Edit</div>
                    {cronJobsTable()}
                </div>
            </>
        );
    };

    if (fetching) {
        return <LoadingIcon />;
    }

    // User Access Check
    if (!userHasPolicies(user, currentUserPermissions, ['can-edit-sync-config'])) {
        return "You don't have the required permissions to access this page.";
    }

    return (
        <div className={styles.module}>
            <h2>Account Synchronization Settings</h2>
            <p>Configure synchronization behaviors and schedules for this account.</p>
            <SegmentLine />
            <h4>Custom Sync Now</h4>
            {renderSyncNowForm()}
            <SegmentLine />

            <h4>Default Sync Behavior</h4>
            <p>The default behaviour of the Sync Recent Data button.</p>
            {renderDefaultSyncOverview()}
            {renderDefaultSyncForm()}
            <SegmentLine />

            <h4>Scheduled Syncs</h4>
            <p>These syncs will run automatically at the specified schedule.</p>
            {renderCronForm()}
            <h6>Current Scheduled Syncs</h6>
            {renderCronJobsOverview()}
        </div>
    );
};

SyncSettings.propTypes = {
    account: PropTypes.object.isRequired,
};

export default SyncSettings;
