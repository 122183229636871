import React, { useEffect, useState } from 'react';
import { generatePassword } from '@/util/passwordGenerator';
import PropTypes from 'prop-types';
import styles from '../Fields.module.scss';
import classnames from 'classnames';
import Label from './partials/Label';
import { detectAutofill } from '@/components/ui/FormFields';

const Password = ({ field }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [autofilled, setAutofilled] = useState(false);

    useEffect(() => {
        if (field.inputRef && field.inputRef.current) {
            detectAutofill(field.inputRef.current).then((data) => {
                setAutofilled(data);
            });
        }
    }, [field.inputRef, field.value]);

    const generateNewPassword = () => {
        const password = generatePassword(12);
        field.onChange(field.name, password);
        setShowPassword(true);
    };

    const passwordShowButton = (
        <span className='passwordShowButton' onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? <i className='fas fa-eye' /> : <i className='fas fa-eye-slash' />}
        </span>
    );

    const generatePasswordButton = (
        <button type='button' className='generatePasswordButton' onClick={generateNewPassword}>
            <i className='fas fa-key' /> Generate Password
        </button>
    );

    return (
        <div
            ref={field.wrapperRef}
            className={classnames(
                'fieldWrapper typePassword',
                styles.typePassword,
                field.wrapperClass,
                !field.value && !autofilled && 'empty',
                field.focused && 'focused',
                field.disabled && 'disabled'
            )}
            onFocus={() => field.onFocus && field.onFocus(field.name)}
            onBlur={() => field.onBlur && field.onBlur(field.name)}
            tabIndex={-1}>
            <Label field={field} />
            <div className={classnames('field', field.fieldClass)}>
                <input
                    ref={field.inputRef}
                    id={field.name}
                    className={field.inputClass}
                    name={field.name}
                    value={field.value ? field.value : ''}
                    type={showPassword ? 'text' : 'password'}
                    placeholder={field.placeholder}
                    required={field.required && 'required'}
                    autoComplete={field.autoComplete}
                    onChange={(e) => field.onChange(field.name, e.target.value)}
                />
                {field.enablePasswordShow && passwordShowButton}
                {field.afterInput}
            </div>
            {field.enablePasswordGenerator && generatePasswordButton}
        </div>
    );
};

Password.propTypes = {
    field: PropTypes.shape({
        // Base props
        name: PropTypes.string,
        value: PropTypes.string,
        placeholder: PropTypes.string,
        required: PropTypes.bool,
        autoComplete: PropTypes.string,
        // Ref props
        inputRef: PropTypes.object,
        wrapperRef: PropTypes.object,
        // Callback props
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        onClick: PropTypes.func,
        // Class props
        wrapperClass: PropTypes.string,
        fieldClass: PropTypes.string,
        inputClass: PropTypes.string,
        // Label props
        label: PropTypes.string,
        labelClass: PropTypes.string,
        hideLabel: PropTypes.bool,
        hideRequiredSign: PropTypes.bool,
        // Other props
        afterInput: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
        // Type specific props
        enablePasswordShow: PropTypes.bool,
        enablePasswordGenerator: PropTypes.bool,
    }),
};

export default Password;
