import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectorReport } from '@/store/slices/reports';
import { selectorReportItems } from '@/store/slices/reportItems';
import ReportItem from '../../partials/ReportItem';
import Pagination from '../../partials/Pagination';
import GroupSelector from '../../partials/GroupSelector';
import ExportKeywordBatch from './ExportKeywordBatch';
import { LoadingIcon } from '@/components/ui';

const ResultsKeywordBatch = ({ tool }) => {
    // Router
    const { reportId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    // Pagination
    const currentPage = searchParams.get('page') ? parseInt(searchParams.get('page')) : 1;
    const pageSize = searchParams.get('pageSize') ? parseInt(searchParams.get('pageSize')) : 10;

    // State
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [groupOptions, setGroupOptions] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);

    // Selectors
    const report = useSelector((state) => selectorReport(state, tool.uid, reportId));
    const reportItems = useSelector((state) => selectorReportItems(state, reportId));

    // Effect: Get report items
    useEffect(() => {
        if (!report) return;
        setLoading(true);
        const pagination = { page: currentPage, pageSize: pageSize };
        const data = { group: { $in: selectedGroups } };
        tool.getReportItems(report.id, pagination, {}, {}, data).then((res) => {
            if (res.pagination) {
                setTotal(res.pagination.total);
            }
            setLoading(false);
        });
    }, [report, currentPage, pageSize, selectedGroups]);

    // Effect: Set group options
    useEffect(() => {
        if (!report) return;
        const { keywords } = report.parameters;
        const keywordsArr = keywords ? keywords.split('\n') : [];
        const options = keywordsArr.map((keyword) => {
            return { value: keyword, name: keyword };
        });
        setGroupOptions(options);
        setSelectedGroups(keywordsArr);
    }, [reportId]);

    // Handler: Group selection change
    const onGroupSelectionChange = (groups) => {
        setSelectedGroups(groups);
    };

    // Effect: Set curreny page
    const setCurrentPage = (val) => {
        searchParams.set('page', val);
        setSearchParams(searchParams);
    };

    // Effect: Set page size
    const setPageSize = (val) => {
        searchParams.set('pageSize', val);
        setSearchParams(searchParams);
    };

    // Bail if no report id found
    if (!report) {
        return null;
    }

    const { results } = report;
    const startingIndex = (currentPage - 1) * pageSize + 1;

    // Stats
    const reportStats = results && results.stats ? results.stats : null;
    const itemsTotal = reportStats ? reportStats.total : 0;
    const itemsDone = reportStats ? reportStats.done : 0;
    const itemsRetry = reportStats ? reportStats.retry : 0;
    const itemsError = reportStats ? reportStats.error : 0;
    const itemsShorts = reportStats ? reportStats.shorts : 0;

    const ReportItems = () => {
        if (!reportItems || reportItems.length === 0) {
            return <p className='noResults'>No results found.</p>;
        }

        return selectedGroups.map((group) => {
            const groupItems = reportItems.filter((item) => item.group === group);
            if (groupItems.length === 0) return null;
            return (
                <div key={group} className='group'>
                    <h5 className='groupHeading'>"{group}"</h5>
                    {groupItems.map((item, index) => {
                        const reportItemNo = startingIndex + index;
                        return <ReportItem key={item.id} item={item} index={reportItemNo} />;
                    })}
                </div>
            );
        });
    };

    return (
        <>
            <ExportKeywordBatch tool={tool} startingIndex={startingIndex} />
            <div className='results'>
                <div className='resultsHeader'>
                    {/* <h4 className='heading'>{report.parameters.keyword && `"${report.parameters.keyword}"`}</h4> */}
                    <GroupSelector options={groupOptions} selectedOptions={selectedGroups} onChange={onGroupSelectionChange} />
                    <p className='loading'>{loading && <LoadingIcon />}</p>
                    <p className='info'>
                        <span>Total Vids: {itemsTotal}</span>|<span>Done: {itemsDone}</span>|<span>Retry: {itemsRetry}</span>|
                        <span>Error: {itemsError}</span>|<span>Shorts: {itemsShorts}</span>
                    </p>
                    <Pagination page={currentPage} limit={pageSize} total={total} setPage={setCurrentPage} setLimit={setPageSize} />
                </div>
                <ReportItems />
                <div className='resultsFooter'>
                    <p className='loading'>{loading && <LoadingIcon />}</p>
                    <Pagination page={currentPage} limit={pageSize} total={total} setPage={setCurrentPage} setLimit={setPageSize} />
                </div>
            </div>
        </>
    );
};

ResultsKeywordBatch.propTypes = {
    tool: PropTypes.object.isRequired,
};

export default ResultsKeywordBatch;
