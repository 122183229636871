import React from 'react';
import PropTypes from 'prop-types';
import ReportStatWrapper from '@/components/metrics-account/reports/ui/ReportStatWrapper/ReportStatWrapper';
import { formatNumberWithCommas } from '@/util/format';

const SummaryStats = ({ salesSummary, selectedChartSalesTypeOptions }) => {
    return (
        <div className='reportStats'>
            <ReportStatWrapper className={'statRevenue'}>
                <strong>Total Revenue</strong>
                <p className='stat-positive'>
                    <strong>${formatNumberWithCommas(salesSummary.totalRevenue)}</strong>
                </p>
            </ReportStatWrapper>

            <ReportStatWrapper className={'statSales'}>
                <strong>Total Transactions</strong>
                <p>
                    {selectedChartSalesTypeOptions.map((saleType, key) => {
                        return (
                            <span key={key}>
                                <span style={{ color: saleType.fill }}>
                                    {`${salesSummary.sales[saleType.key] ? salesSummary.sales[saleType.key].transactions : '0'} ${saleType.label}`}
                                </span>
                                {key < selectedChartSalesTypeOptions.length - 1 && <span className='delimiter'>|</span>}
                            </span>
                        );
                    })}
                </p>
            </ReportStatWrapper>
        </div>
    );
};

SummaryStats.propTypes = {
    salesSummary: PropTypes.object.isRequired,
    selectedChartSalesTypeOptions: PropTypes.array.isRequired,
};

export default SummaryStats;
