import React, { useRef, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import styles from '../Fields.module.scss';
import classnames from 'classnames';
import Label from './partials/Label';

const File = ({ field }) => {
    // State
    const [blockBlur, setBlockBlur] = useState(false);

    // Ref
    const fieldRef = field.inputRef ? field.inputRef : useRef();

    // Hooks
    useLayoutEffect(() => {
        if (!field.value && fieldRef.current) {
            fieldRef.current.value = '';
        }
    });

    const onFileChange = () => {
        const fileInput = fieldRef.current;
        if (fileInput.files[0]) {
            const file = fileInput.files[0];
            const extension = file.name.substr(file.name.lastIndexOf('.'));

            if (!field.extensions || field.extensions.includes(extension)) {
                field.onChange && field.onChange(field.name, file.name, { files: [file] });
                return;
            }
        }
        field.onChange && field.onChange(field.name, '', []);
    };

    const onClear = () => {
        fieldRef.current.value = '';
        onFileChange();
    };

    const onSetFile = () => {
        fieldRef.current.click();
        setBlockBlur(true);
    };

    const onUploaderFocus = () => {
        setBlockBlur(false);
        field.onFocus && field.onFocus(field.name);
    };

    const onUploaderBlur = () => {
        if (!blockBlur) {
            field.onBlur && field.onBlur(field.name);
        }
    };

    return (
        <div
            ref={field.wrapperRef}
            className={classnames('fieldWrapper typeFile', styles.typeFile, field.wrapperClass, field.disabled && 'disabled')}
            onFocus={() => field.onFocus && field.onFocus(field.name)}
            onBlur={() => field.onBlur && field.onBlur(field.name)}
            tabIndex={-1}>
            <Label field={field} />
            <div className={classnames('field', field.disabled && 'disabled', field.fieldClass)}>
                <input
                    ref={fieldRef}
                    id={field.name}
                    name={field.name}
                    type='file'
                    required={field.required && 'required'}
                    onChange={onFileChange}
                    accept={field.extensions && field.extensions.join(',')}
                />
                {!field.value ? (
                    <button className='setButton' tabIndex='0' onFocus={onUploaderFocus} onBlur={onUploaderBlur} onClick={onSetFile}>
                        <i className='fas fa-file' />
                        {field.placeholder}
                    </button>
                ) : (
                    <button className='clearButton' tabIndex='0' onFocus={onUploaderFocus} onBlur={onUploaderBlur} onClick={onClear}>
                        {field.value}
                    </button>
                )}
                {field.afterInput}
            </div>
        </div>
    );
};

File.propTypes = {
    field: PropTypes.shape({
        // Base props
        name: PropTypes.string,
        value: PropTypes.string,
        placeholder: PropTypes.string,
        required: PropTypes.bool,
        extensions: PropTypes.array,
        // Ref props
        inputRef: PropTypes.object,
        wrapperRef: PropTypes.object,
        // Callback props
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        onClick: PropTypes.func,
        // Class props
        wrapperClass: PropTypes.string,
        fieldClass: PropTypes.string,
        inputClass: PropTypes.string,
        // Label props
        label: PropTypes.string,
        labelClass: PropTypes.string,
        hideLabel: PropTypes.bool,
        hideRequiredSign: PropTypes.bool,
        // Other props
        afterInput: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    }),
};

export default File;
