import React from 'react';
import PropTypes from 'prop-types';
import { SelectMultiple } from '@/components/ui/FormFields';
import classnames from 'classnames';

const CurrencySelect = ({ currencyOptions = [], selectedCurrencies = [], onChange }) => {
    // On Change
    const onSelectionChange = (keys) => {
        const nextSelectedCurrencyIDs = currencyOptions.filter((option) => keys.includes(option.value)).map((option) => option.value);
        onChange(nextSelectedCurrencyIDs);
    };

    return (
        <div className={classnames('CurrencySelect')}>
            <SelectMultiple
                field={{
                    options: currencyOptions,
                    value: selectedCurrencies,
                    onChange: (name, value) => onSelectionChange(value),
                    summaryLabels: {
                        blank: 'Select Currencies',
                        multiple: '{count} Currencies Selected',
                        all: 'All Currencies',
                    },
                }}
            />
        </div>
    );
};

CurrencySelect.propTypes = {
    currencyOptions: PropTypes.array.isRequired,
    selectedCurrencies: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default CurrencySelect;
