import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./ReportStatWrapper.module.scss";

const ReportStatWrapper = ({className, children}) => {

    return(
        <div className={classnames("ReportStatWrapper", styles.module, className)}>
            {children}
        </div>
    )
}

ReportStatWrapper.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
}

export default ReportStatWrapper;