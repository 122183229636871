import React from "react";
import styles from "./SegmentLine.module.scss"
import classnames from "classnames";

const SegmentLine = () => {

    return(
        <div className={classnames("segmentLine", styles.module)} />
    )
}

export default SegmentLine;