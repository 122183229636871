export const jsonToCSV = (json) => {};

export const createCSVFileDownload = (content, filename) => {
    const filenameWithExtension = filename + '.csv';

    const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filenameWithExtension);
    } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filenameWithExtension);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
};

export const createPDFFileDownload = (blob, filename) => {
    const filenameWithExtension = filename + '.pdf';

    if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filenameWithExtension);
    } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filenameWithExtension);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
};
