import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import ResetPasswordForm from '@/components/forms/account/ResetPasswordForm';
import * as endpoints from '@/router/_endpoints';
import PageWrapper from '@/pages/PageWrapper';
import classnames from 'classnames';
import styles from './Account.module.scss';

const ResetPassword = () => {
    // useLocation
    const location = useLocation();

    // Get current user from store
    const user = useSelector((state) => state.auth.user);

    // Get password reset code from query string
    const queryParams = Object.fromEntries(new URLSearchParams(location.search).entries());
    const code = queryParams.code ? queryParams.code : '';

    // Get redirect path after login (either previous page or home page)
    const redirectPath = location.state ? location.state.from.pathname : endpoints.HOME_PAGE;

    // If user is logged in, redirect to selected path
    if (user) {
        return <Navigate to={redirectPath} />;
    }

    return (
        <PageWrapper pageClass={classnames('pageResetPassword', styles.module)}>
            <div className='contentWrapper'>
                <ResetPasswordForm code={code} />
            </div>
        </PageWrapper>
    );
};

export default ResetPassword;
