import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as endpoints from '@/router/_endpoints';
import PageWrapper from '@/pages/PageWrapper';
import styles from './NotFound.module.scss';
import classnames from 'classnames';

const NotFound = () => {
    // Store
    const user = useSelector((state) => state.auth.user);

    return (
        <PageWrapper pageClass={classnames('404', styles.module)}>
            <div>
                <h3>404: Page Not Found</h3>
                {user ? <Link to={endpoints.DASHBOARD}>Return to Dashboard</Link> : <Link to={endpoints.LOGIN}>Log In</Link>}
            </div>
        </PageWrapper>
    );
};

export default NotFound;
