import qs from 'qs';
import { getStoredUserToken } from './auth';
import { logError } from '@/util/debugger';

const host = process.env.REACT_APP_STRAPI_API_HOST;

// Returns request Headers
const getRequestHeaders = (authenticated) => {
    // Default headers
    let headers = { Accept: 'application/json' };
    // Authentication (if applicable)
    if (authenticated) {
        const jwt = getStoredUserToken();
        headers['Authorization'] = 'Bearer ' + jwt;
    }
    return headers;
};

// Returns query
export const createQueryString = (baseQuery, overrideQuery = {}) => {
    const query = Object.assign({ ...baseQuery }, { ...overrideQuery });
    return qs.stringify(query);
};

/** GET request to strapi endpoint */
export const restStrapiGetRequest = async (endpoint, authenticated) => {
    const headers = getRequestHeaders(authenticated);
    const res = await fetch(`${host}/${endpoint}`, {
        method: 'GET',
        headers: headers,
    });
    try {
        const json = await res.json();
        return json;
    } catch (error) {
        logError(endpoint, error);
        return { error: error };
    }
};

/** POST request to strapi endpoint */
export const restStrapiPostRequest = async (endpoint, authenticated, body) => {
    const headers = getRequestHeaders(authenticated);
    const res = await fetch(`${host}/${endpoint}`, {
        method: 'POST',
        headers: headers,
        body: body,
    });
    try {
        const json = await res.json();
        return json;
    } catch (error) {
        logError(endpoint, error);
        return { error: error };
    }
};

/** PUT request to strapi endpoint */
export const restStrapiPutRequest = async (endpoint, authenticated, body) => {
    const headers = getRequestHeaders(authenticated);
    const res = await fetch(`${host}/${endpoint}`, {
        method: 'PUT',
        headers: headers,
        body: body,
    });
    try {
        const json = await res.json();
        return json;
    } catch (error) {
        logError(endpoint, error);
        return { error: error };
    }
};

/** DELETE request to strapi endpoint */
export const restStrapiDeleteRequest = async (endpoint, authenticated) => {
    const headers = getRequestHeaders(authenticated);
    const res = await fetch(`${host}/${endpoint}`, {
        method: 'DELETE',
        headers: headers,
    });
    try {
        const json = await res.json();
        return json;
    } catch (error) {
        logError(endpoint, error);
        return { error: error };
    }
};
