import React from 'react';
import PropTypes from 'prop-types';
import styles from '../Fields.module.scss';
import classnames from 'classnames';
import Label from './partials/Label';

const Text = ({ field }) => {
    return (
        <div
            ref={field.wrapperRef}
            className={classnames(
                'fieldWrapper typeText',
                styles.typeText,
                field.wrapperClass,
                !field.value && 'empty',
                field.focused && 'focused',
                field.disabled && 'disabled'
            )}
            onFocus={() => field.onFocus && field.onFocus(field.name)}
            onBlur={() => field.onBlur && field.onBlur(field.name)}
            tabIndex={-1}>
            <Label field={field} />
            <div className={classnames('field', field.fieldClass)}>
                <input
                    ref={field.inputRef}
                    id={field.name}
                    className={field.inputClass}
                    name={field.name}
                    value={field.value ? field.value : ''}
                    type='text'
                    placeholder={field.placeholder}
                    required={field.required && 'required'}
                    autoComplete={field.autoComplete}
                    onChange={(e) => field.onChange(field.name, e.target.value)}
                />
                {field.afterInput}
            </div>
        </div>
    );
};

Text.propTypes = {
    field: PropTypes.shape({
        // Base props
        name: PropTypes.string,
        value: PropTypes.string,
        placeholder: PropTypes.string,
        required: PropTypes.bool,
        autoComplete: PropTypes.string,
        // Ref props
        inputRef: PropTypes.object,
        wrapperRef: PropTypes.object,
        // Callback props
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        onClick: PropTypes.func,
        // Class props
        wrapperClass: PropTypes.string,
        fieldClass: PropTypes.string,
        inputClass: PropTypes.string,
        // Label props
        label: PropTypes.string,
        labelClass: PropTypes.string,
        hideLabel: PropTypes.bool,
        hideRequiredSign: PropTypes.bool,
        // Other props
        afterInput: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    }),
};

export default Text;
