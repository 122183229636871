import * as api from '@/services/strapi/rest';
import { logError } from '@/util/debugger';

/** Define basename */
const basename = 'users-permissions';

/** Get user roles */
export const getUserRoles = async () => {
    const data = await api.restStrapiGetRequest(`${basename}/roles`, true);
    if (data.error) {
        logError(data);
        return [];
    }
    return data.roles;
};

// Check if current user is admin
export const userIsAdmin = (user) => {
    return user.role.type === 'admin';
};

// Check if user has policies
export const userHasPolicies = (user, userPermissions, requiredPolicies) => {
    if (userIsAdmin(user)) return true;
    if (!userPermissions || !userPermissions.policies) return false;

    for (let i = 0; i < requiredPolicies.length; i++) {
        const requiredPolicy = requiredPolicies[i];
        if (!userPermissions.policies.includes(requiredPolicy)) {
            return false;
        }
    }
    return true;
};

// Check if user has any permission
export const userHasAnyPermission = (user, userPermissions, permissions) => {
    for (const permission of permissions) {
        switch (permission.type) {
            case 'userHasPolicies':
                for (const policy of permission.value) {
                    if (userHasPolicies(user, userPermissions, [policy])) {
                        return true;
                    }
                }
                break;
            default:
                break;
        }
    }
    return false;
};

// Get missing permissions
export const getMissingPermissions = (user, userPermissions, requiredPermissions) => {
    const missingPermissions = [];
    for (let i = 0; i < requiredPermissions.length; i++) {
        const permission = requiredPermissions[i];
        switch (permission.type) {
            case 'userHasPolicies':
                if (!userHasPolicies(user, userPermissions, permission.value)) {
                    missingPermissions.push(permission);
                }
                break;
            default:
                break;
        }
    }
    return missingPermissions;
};
