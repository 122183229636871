import React from 'react';
import AuthRedirect from '@/router/AuthRedirect';
import CreateNewAccountForm from '@/components/metrics-account/forms/CreateNewAccountForm';
import styles from './CreateAccount.module.scss';

const CreateAccount = () => {
    return (
        <>
            <AuthRedirect allowableRolesTypes={['admin']} />

            <div className={styles.module}>
                <h2 className='viewHeading'>Create Metrics Account</h2>
                <CreateNewAccountForm />
            </div>
        </>
    );
};

export default CreateAccount;
