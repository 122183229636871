import { configureStore } from '@reduxjs/toolkit';
import { actionResetStore } from './actions';

// Reduces
import appReducer from './slices/app';
import authReducer from './slices/auth';
import usersReducer from './slices/users';
import accountsReduces from './slices/accounts';
import filtersReducer from './slices/filters';
import datasetsReducer from './slices/datasets';
//import salesReducers from './slices/sales';
import reportsReducer from './slices/reports';
import reportItemsReduce from './slices/reportItems';
import logEntriesReducer from './slices/logEntries';
import productsReducer from './slices/products';
import toolsReducer from './slices/tools';

const store = configureStore({
    reducer: {
        app: appReducer,
        auth: authReducer,
        users: usersReducer,
        accounts: accountsReduces,
        tools: toolsReducer,
        filters: filtersReducer,
        datasets: datasetsReducer,
        //sales: salesReducers,
        reports: reportsReducer,
        reportItems: reportItemsReduce,
        logEntries: logEntriesReducer,
        products: productsReducer,
    },
});

export const resetStore = () => {
    store.dispatch(actionResetStore());
};

export default store;
