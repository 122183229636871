import { createSlice } from '@reduxjs/toolkit';
import { actionResetStore } from '../actions';

const initialState = {
    jwt: '',
    user: null,
    permissions: [],
};

const slice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        userLoggedIn: (state, action) => {
            state.jwt = action.payload.jwt;
            state.user = action.payload.user;
        },
        userLoggedOut: (state, action) => {
            state.jwt = '';
            state.user = null;
            state.permissions = [];
        },
        fetchedUserPermissions: (state, action) => {
            // Check if permissions for this key already exists
            const index = state.permissions.findIndex((object) => {
                return object.key === action.payload.key;
            });
            if (index === -1) {
                state.permissions.push({ key: action.payload.key, data: action.payload.data });
            } else {
                state.permissions[index] = { key: action.payload.key, data: action.payload.data };
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(actionResetStore, (state, action) => {
            return initialState;
        });
    },
});

// Actions
export const { userLoggedIn, userLoggedOut, fetchedUserPermissions } = slice.actions;

// Reducer
export default slice.reducer;

// Selectors
export const selectorCurrentUserPermissions = (state, key) => {
    const entry = state.auth.permissions.find((object) => {
        return object.key === key;
    });
    return entry ? entry.data : null;
};
