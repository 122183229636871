import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './ExportData.module.scss';
import { Button, Select } from '@/components/ui/FormFields';
import * as Filters from '@/components/metrics-account/filters';
import { createCSVFileDownload } from '@/util/files';
import LoadingIcon from '@/components/ui/LoadingIcon/LoadingIcon';
import { useIsMounted } from '@/util/hooks';
import { clearTimezoneFromDate } from '@/util/format';

const ExportData = ({ account }) => {
    // State
    const [fetching, setFetching] = useState(false);
    const [dataType, setDataType] = useState('');
    const [dateRange, setDateRange] = useState({
        startTime: clearTimezoneFromDate(new Date(new Date().setHours(0, 0, 0, 0))).getTime(),
        endTime: clearTimezoneFromDate(new Date(new Date().setHours(0, 0, 0, 0))).getTime(),
        presetID: 'this-month',
        presetLabel: '',
    });

    // Hooks
    const isMounted = useIsMounted();

    // Event Handlers
    const onGenerateExport = () => {
        const data = {
            dataType: dataType,
            filters: {
                startDate: new Date(dateRange.startTime).toISOString(),
                endDate: new Date(dateRange.endTime + 1000 * 60 * 60 * 24).toISOString(),
            },
        };
        setFetching(true);
        account.getCollectionTypeExportData(data).then((response) => {
            if (!response.error) {
                const date = new Date();
                const csvContent = response
                    .map((row) => {
                        return row.map((entry) => `"${entry}"`).join(',');
                    })
                    .join('\n');
                createCSVFileDownload(csvContent, `export_${dataType}s_${date.toISOString()}`);
            }
            isMounted() && setFetching(false);
        });
    };

    // Renders
    const renderExportForm = () => {
        const dataTypeOptions = account.getRelatedCollectionTypes().map((item) => {
            return {
                name: item.name,
                value: item.id,
            };
        });

        return (
            <div className='exportForm'>
                <h2>Export Metrics Data</h2>
                <p>Export data stored on this metrics account.</p>
                <p>1. Choose data type to export</p>
                <Select
                    field={{
                        options: dataTypeOptions,
                        value: dataType,
                        required: true,
                        onChange: (name, value) => setDataType(value),
                    }}
                />
                <p>2. Select date range</p>
                <Filters.DateRange dateRange={dateRange} onChange={(value) => setDateRange(value)} />
                <p>3. Generate export file</p>
                <Button
                    field={{
                        value: 'Generate Export File',
                        disabled: !dataType || fetching,
                        onClick: onGenerateExport,
                        afterInput: fetching && <LoadingIcon />,
                    }}
                />
            </div>
        );
    };

    return <div className={styles.module}>{renderExportForm()}</div>;
};

ExportData.propTypes = {
    account: PropTypes.object.isRequired,
};

export default ExportData;
