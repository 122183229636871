import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import FormHandler from '@/components/ui/FormHandler/FormHandler';
import { userLoginFields } from './_formFields';
import { authenticateUser } from '@/services/strapi/auth';
import * as endpoints from '@/router/_endpoints';

const LoginForm = ({ onLogin }) => {
    // Handler Form Submit
    const onSubmit = async (data) => {
        const identifier = data.find((input) => input.name === 'identifier').value;
        const password = data.find((input) => input.name === 'password').value;

        const res = await authenticateUser(identifier, password);

        if (res.error) {
            return {
                status: 'error',
                message: 'Invalid Login. Try Again.',
                clearFields: true,
            };
        } else {
            onLogin(res);
            return {};
        }
    };

    return (
        <>
            <FormHandler
                fields={userLoginFields}
                formOptions={{
                    formClass: 'formStyleA',
                }}
                callbacks={{ onSubmit: onSubmit }}
            />
            <p>
                <Link to={endpoints.FORGOT_PASSWORD}>Forgot your password?</Link>
            </p>
        </>
    );
};

LoginForm.propTypes = {
    onLogin: PropTypes.func,
};

export default LoginForm;
