import moment from 'moment';

export const removeUnderscoresAndCapitalize = (str) => {
    str.replace(/_/g, ' ');
    const words = str.split(' ');
    for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    return words.join(' ');
};

export const formatDateString = (date) => {
    const options = {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    };

    return date.toLocaleDateString('en-US', options);
};

export const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const percentageIncreaseDecrease = (a, b) => {
    if (a === 0) {
        return 100;
    }
    const result = ((b - a) / Math.abs(a)) * 100;
    return parseFloat(result.toFixed(2));
};

export const valueRatioToTotal = (a, b) => {
    if (a + b === 0) {
        return 0.5;
    }
    return parseFloat((a / (b + a)).toFixed(2));
};

export const offsetDateByDays = (dateObj, numDays) => {
    const newDateObj = new Date(dateObj);
    newDateObj.setDate(dateObj.getDate() + numDays);
    return newDateObj;
};

export const clearTimezoneFromDate = (dateObj) => {
    const isoDate = dateObj.toISOString();
    return new Date(moment(isoDate).utcOffset(0, true).format());
};

export const clearTimeAndTimezoneFromDate = (dateObj) => {
    const date = new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate());
    return clearTimezoneFromDate(date);
};

export const secondsToMs = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.round(seconds % 60);
    return `${minutes}m ${remainingSeconds}s`;
};

export const minutesToHm = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMutes = minutes % 60;
    return `${hours}h ${remainingMutes}m`;
};

export const approximateIntegerWithPrefix = (number) => {
    if (number >= 1000000) {
        return (number / 1000000).toFixed(2) + 'M';
    } else if (number >= 1000) {
        return (number / 1000).toFixed(2) + 'k';
    } else {
        return number.toString();
    }
};

export const webarchiveSnapshotTimestampToDate = (timestamp) => {
    timestamp = timestamp.toString();
    let year = timestamp.slice(0, 4);
    let month = timestamp.slice(4, 6);
    let day = timestamp.slice(6, 8);

    return new Date(`${year}-${month}-${day}`);
};
