import React from "react";
import PropTypes from "prop-types";
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";

const ReportChart = ({chartEntries, selectedChartSalesTypeOptions}) => {

    // Chart Tooltip
    const CustomTooltip = ({ active, payload, label }) => {

        if( !payload || !payload.length ) return "";

        const getLegends = selectedChartSalesTypeOptions.map( (status, key) => {
            const statusPayload = payload.find( (payloadStatus) => { return payloadStatus.dataKey === status.key } );
            if( statusPayload ) {
                return (
                    <p key={key}>{`${status.label}: ${statusPayload.value}`}</p>
                )
            }else{
                return (
                    <p key={key}>{`${status.label}: 0`}</p>
                )
            }
        } );

        if (active && payload && payload.length) {
            return (
                <div className="tooltip">
                    <p className="label">{`${label}`}</p>
                    {getLegends}
                </div>
            );
        }
        return null;
    };

    // Chart Bars
    const generateBars = selectedChartSalesTypeOptions.map( (statusMeta, key) => {
        return(
            <Bar
                key={key}
                name={statusMeta.label}
                stackId="a"
                dataKey={statusMeta.key}
                barSize={20}
                opacity="0.8"
                fill={statusMeta.fill}
            />
        )
    })

    return (
        <div className="chart">
            <ResponsiveContainer width="100%" height={100 + chartEntries.length * 25}>
                <BarChart
                    layout="vertical"
                    width={500}
                    height={400}
                    data={chartEntries}
                    margin={{
                        top: 20,
                        right: 20,
                        bottom: 20,
                        left: 10,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <YAxis dataKey="datetime" type="category" />
                    <XAxis type="number" tickCount={30} allowDecimals={false} />
                    <Tooltip cursor={{ fill: '#eee', opacity: '0.5'}}  content={<CustomTooltip />} />
                    <Legend />
                    {generateBars}
                </BarChart>
            </ResponsiveContainer>
        </div>
    )


}

ReportChart.propTypes = {
    chartEntries: PropTypes.array.isRequired,
    selectedChartSalesTypeOptions: PropTypes.array.isRequired,
}

export default ReportChart;