import React from 'react';
import PropTypes from 'prop-types';
import ReportStatWrapper from '@/components/metrics-account/reports/ui/ReportStatWrapper/ReportStatWrapper';
import { formatNumberWithCommas } from '@/util/format';
import dateFormat from 'dateformat';

const StatsComparison = ({ salesSummaries, periods, selectedChartSalesTypeOptions }) => {
    const generateTableEntries = () => {
        const percentageChange = (index) => {
            if (index === 0) {
                return <td>-</td>;
            } else {
                const percentageChange =
                    salesSummaries[index].totalRevenue !== 0
                        ? Math.round(
                              -(((salesSummaries[index].totalRevenue - salesSummaries[0].totalRevenue) / salesSummaries[index].totalRevenue) * 100) *
                                  100
                          ) / 100
                        : 0;
                return <td className={percentageChange >= 0 ? 'green' : 'red'}>{percentageChange} %</td>;
            }
        };

        return salesSummaries.map((summary, key) => {
            const period = periods[key];
            const startDate = new Date(period.startTime);
            const endDate = new Date(period.endTime);

            return (
                <tbody key={key}>
                    <tr>
                        <td className='stat-neutral'>
                            {dateFormat(startDate, "d mmm 'yy")} - {dateFormat(endDate, "d mmm 'yy")}
                        </td>
                        <td className='stat-positive'>
                            <strong>${formatNumberWithCommas(Math.round(summary.totalRevenue * 100) / 100)}</strong>
                        </td>
                        <td>
                            {selectedChartSalesTypeOptions.map((saleType, key) => {
                                return (
                                    <span key={key}>
                                        <span style={{ color: saleType.fill }}>
                                            {`${summary.sales[saleType.key] ? summary.sales[saleType.key].transactions : '0'} ${saleType.label}`}
                                        </span>
                                        {key < selectedChartSalesTypeOptions.length - 1 && <span className='delimiter'>|</span>}
                                    </span>
                                );
                            })}
                        </td>
                        {percentageChange(key)}
                    </tr>
                </tbody>
            );
        });
    };

    return (
        <div className='reportStats'>
            <ReportStatWrapper className={'statPeriodCompare'}>
                <table>
                    <thead>
                        <tr>
                            <th>Period</th>
                            <th>Revenue</th>
                            <th>Transactions</th>
                            <th>% Change</th>
                        </tr>
                    </thead>
                    {generateTableEntries()}
                </table>
            </ReportStatWrapper>
        </div>
    );
};

StatsComparison.propTypes = {
    salesSummaries: PropTypes.array.isRequired,
    periods: PropTypes.array.isRequired,
    selectedChartSalesTypeOptions: PropTypes.array.isRequired,
};

export default StatsComparison;
