import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from '../Fields.module.scss';
import classnames from 'classnames';
import Label from './partials/Label';

const SelectMultiple = ({ field }) => {
    // State
    const [showDropdown, setShowDropdown] = useState(false);

    // Refs
    const refDropdown = React.createRef();
    const refSelectButton = React.createRef();

    // Register click event
    useEffect(() => {
        document.addEventListener('mousedown', eventClicked);
        return () => {
            document.removeEventListener('mousedown', eventClicked);
        };
    });

    const eventClicked = (e) => {
        if (
            refSelectButton.current &&
            !refSelectButton.current.contains(e.target) &&
            refDropdown.current &&
            !refDropdown.current.contains(e.target)
        ) {
            setShowDropdown(false);
            field.onBlur && field.onBlur(field.name);
        }
    };

    // Memo
    const selectedValues = useMemo(() => {
        return Array.isArray(field.value) ? [...field.value] : [];
    }, [field.value]);

    // On Item Click
    const onItemClick = (value) => {
        let nextSelectedValues = [...selectedValues];
        if (nextSelectedValues.includes(value)) {
            nextSelectedValues = nextSelectedValues.filter((itemValue) => itemValue !== value);
        } else {
            nextSelectedValues.push(value);
        }
        field.onChange && field.onChange(field.name, nextSelectedValues);
    };

    // On Multi Select
    const onMultiSelect = () => {
        let nextSelectedValues = [...selectedValues];
        if (selectedValues.length < field.options.length) {
            nextSelectedValues = field.options.map((option) => {
                return option.value;
            });
        } else if (selectedValues.length === field.options.length) {
            nextSelectedValues = [];
        }
        field.onChange && field.onChange(field.name, nextSelectedValues);
    };

    // On Template Select
    const onGroupSelect = (key) => {
        let nextSelectedValues = [...selectedValues];
        const group = field.optionGroups.find((group) => group.key === key);
        if (group && group.values) {
            // Check if all values are selected
            const allValuesSelected = group.values.every((value) => nextSelectedValues.includes(value));
            if (allValuesSelected) {
                // Remove all values
                nextSelectedValues = nextSelectedValues.filter((value) => !group.values.includes(value));
            } else {
                // Add all values
                nextSelectedValues = [...nextSelectedValues, ...group.values];
            }
        }
        field.onChange && field.onChange(field.name, nextSelectedValues);
    };

    // Label Text
    const renderLabelText = () => {
        const count = selectedValues.length;
        const summaryLabels = field.summaryLabels ? field.summaryLabels : {};

        if (count === field.options.length && field.options.length > 0) {
            return summaryLabels.hasOwnProperty('all') ? summaryLabels.all.replace('{count}', count) : 'All Items Selected';
        } else if (count >= 2) {
            return summaryLabels.hasOwnProperty('multiple') ? summaryLabels.multiple.replace('{count}', count) : `${count} Items Selected`;
        } else if (count === 1) {
            if (summaryLabels.hasOwnProperty('single')) {
                return summaryLabels.single;
            } else {
                const option = field.options.find((option) => option.value === selectedValues[0]);
                return option ? option.name : '1 Item Selected';
            }
        } else {
            return summaryLabels.hasOwnProperty('blank') ? summaryLabels.blank : 'Select Items';
        }
    };

    // Dropdown Items
    const dropdownItems = field.options.map((option) => {
        const itemSelected = selectedValues.includes(option.value);
        return (
            <li className='item' key={option.value} onClick={() => onItemClick(option.value)}>
                {itemSelected ? (
                    <span className='icon checked'>
                        <i className='fas fa-check-square' />
                    </span>
                ) : (
                    <span className='icon'>
                        <i className='far fa-square' />
                    </span>
                )}
                {option.name}
            </li>
        );
    });

    // Select Options
    const selectOptions = field.options.map((option, key) => {
        return (
            <option key={key} value={option.value}>
                {option.name}
            </option>
        );
    });

    // Option Groups
    const optionGroups = field.optionGroups
        ? field.optionGroups.map((group, key) => {
              const allValuesSelected = group.values.every((value) => selectedValues.includes(value));
              return (
                  <li className='item' key={key} onClick={() => onGroupSelect(group.key)}>
                      {allValuesSelected ? (
                          <span className='icon checked'>
                              <i className='fas fa-check-square' />
                          </span>
                      ) : (
                          <span className='icon'>
                              <i className='far fa-square' />
                          </span>
                      )}
                      {group.name}
                  </li>
              );
          })
        : null;

    // Render
    return (
        <div
            ref={field.wrapperRef}
            className={classnames(
                'fieldWrapper typeSelectMultiple',
                styles.typeSelectMultiple,
                field.wrapperClass,
                field.value.length === 0 && 'empty',
                field.focused && 'focused',
                field.disabled && 'disabled'
            )}
            onFocus={() => field.onFocus && field.onFocus(field.name)}
            tabIndex={-1}>
            <Label field={field} />
            <div className={classnames('field', field.fieldClass)}>
                <div className={classnames('selectElement', field.inputClass)}>
                    <button
                        ref={refSelectButton}
                        className={showDropdown ? 'focus' : ''}
                        onClick={(e) => {
                            e.preventDefault();
                            setShowDropdown(!showDropdown);
                        }}>
                        <span className={classnames('label', selectedValues.length && 'items-selected')}>{renderLabelText()}</span>
                        <span className='icon'>
                            <i className='fas fa-caret-down' />
                        </span>
                    </button>
                    {showDropdown && (
                        <div ref={refDropdown} className={'dropdown'}>
                            <li className='item' onClick={onMultiSelect}>
                                {selectedValues.length < field.options.length && <span className='action'>Select All</span>}
                                {selectedValues.length === field.options.length && <span className='action'>Deselect All</span>}
                            </li>
                            {optionGroups}
                            {dropdownItems}
                        </div>
                    )}
                </div>

                <select
                    ref={field.inputRef}
                    id={field.name}
                    name={field.name}
                    value={field.value ? field.value : []}
                    required={field.required && 'required'}
                    multiple='multiple'
                    onChange={(e) => onItemClick(e.target.value)}>
                    {selectOptions}
                </select>

                {field.afterInput}
            </div>
        </div>
    );
};

SelectMultiple.propTypes = {
    field: PropTypes.shape({
        // Base props
        name: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
        options: PropTypes.array,
        placeholder: PropTypes.string,
        required: PropTypes.bool,
        // Ref props
        inputRef: PropTypes.object,
        wrapperRef: PropTypes.object,
        // Callback props
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        onClick: PropTypes.func,
        // Class props
        wrapperClass: PropTypes.string,
        fieldClass: PropTypes.string,
        inputClass: PropTypes.string,
        // Label props
        label: PropTypes.string,
        labelClass: PropTypes.string,
        hideLabel: PropTypes.bool,
        hideRequiredSign: PropTypes.bool,
        summaryLabels: PropTypes.shape({
            all: PropTypes.string,
            single: PropTypes.string,
            multiple: PropTypes.string,
            blank: PropTypes.string,
        }),
        // Other props
        afterInput: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    }),
};

export default SelectMultiple;
