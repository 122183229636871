import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { secondsToMs, webarchiveSnapshotTimestampToDate } from '@/util/format';
import { formatDateString, formatNumberWithCommas, percentageIncreaseDecrease } from '@/util/format';

const ReportItem = ({ item, index = null, showDescription = false }) => {
    const { status, status_message } = item;

    // Placehold for invalid item
    const InvalidItem = () => {
        return (
            <div className={classNames('reportItem invalid', status)}>
                <p className={classNames('statusMessage', status)}>{status_message}</p>
            </div>
        );
    };

    if (status !== 'done' || !item.data) {
        return <InvalidItem />;
    }

    // Deconstruct item
    const { video, channel, analytics } = item.data;

    if (!video || !channel || !analytics) {
        return <InvalidItem />;
    }

    let formattedDate = null;

    if (analytics.snapshotTimestamp) {
        const snapshotDate = webarchiveSnapshotTimestampToDate(analytics.snapshotTimestamp);
        formattedDate = formatDateString(snapshotDate);
    } else {
        formattedDate = 'n/a';
    }

    const currentViewCount = analytics.currentViewCount;
    const snapshotViewCount = analytics.snapshotViewCount;
    const viewCountChange = currentViewCount - snapshotViewCount;
    const monthlyAverageViews = analytics.monthlyAverageViews;
    const percentageIncrease = snapshotViewCount
        ? formatNumberWithCommas(percentageIncreaseDecrease(snapshotViewCount, currentViewCount)) + '%'
        : 'n/a';
    const publishDate = new Date(video.publishedAt);

    const formattedViewCount = currentViewCount ? formatNumberWithCommas(currentViewCount) : 'n/a';
    const formattedSelectedSnapViewCount = snapshotViewCount ? formatNumberWithCommas(snapshotViewCount) : 'n/a';
    const formattedViewCountChange = viewCountChange ? formatNumberWithCommas(viewCountChange) : 'n/a';
    const formattedMonthlyAverageViews = monthlyAverageViews ? formatNumberWithCommas(monthlyAverageViews) : 'n/a';

    return (
        <div className='reportItem'>
            <div className='videoHeader'>
                <h4 className='heading'>
                    {index ? <span className='index'>{index}.</span> : ''}
                    <a href={video.url} target='_blank'>
                        {video.title}
                    </a>
                </h4>
            </div>

            <div className='videoMetaWrapper'>
                <div className='flexCol thumbnailWrapper'>
                    <img className='videoThumb' src={video.thumbnailUrl} />
                </div>

                <div className='flexCol'>
                    <div className='tableMeta'>
                        <div className='gridItem itemStyleHeader'>Channel</div>
                        <div className='gridItem itemStyleCell'>
                            <a href={channel.url} target='_blank'>
                                {channel.name}
                            </a>
                        </div>

                        <div className='gridItem itemStyleHeader'>Channel ID</div>
                        <div className='gridItem itemStyleCell'>{channel.id}</div>

                        <div className='gridItem itemStyleHeader'>Video ID</div>
                        <div className='gridItem itemStyleCell'>{video.id}</div>

                        <div className='gridItem itemStyleHeader'>Duration</div>
                        <div className='gridItem itemStyleCell'>{secondsToMs(video.duration)}</div>

                        <div className='gridItem itemStyleHeader'>Publish Date</div>
                        <div className='gridItem itemStyleCell'>{formatDateString(publishDate)}</div>

                        {item.group && (
                            <>
                                <div className='gridItem itemStyleHeader'>Group</div>
                                <div className='gridItem itemStyleCell'>{item.group}</div>
                            </>
                        )}
                    </div>
                </div>
            </div>

            <div className='videoStatsWrapper'>
                <div className='tableStats'>
                    <div className='gridItem itemStyleHeader centerAlign'>Closest Snapshot</div>
                    <div className='gridItem itemStyleHeader centerAlign'>Current Views</div>
                    <div className='gridItem itemStyleHeader centerAlign'>Historic Views</div>
                    <div className='gridItem itemStyleHeader centerAlign'>Change in Views</div>
                    <div className='gridItem itemStyleHeader centerAlign'>Average Views/Month</div>
                    <div className='gridItem itemStyleHeader centerAlign'>% Increase (since Snapshot)</div>
                    <div className='gridItem itemStyleCell centerAlign'>{formattedDate}</div>
                    <div className='gridItem itemStyleCell centerAlign'>{formattedViewCount}</div>
                    <div className='gridItem itemStyleCell centerAlign'>{formattedSelectedSnapViewCount}</div>
                    <div className='gridItem itemStyleCell centerAlign'>{formattedViewCountChange}</div>
                    <div className='gridItem itemStyleCell centerAlign'>{formattedMonthlyAverageViews}</div>
                    <div className='gridItem itemStyleCell centerAlign'>{percentageIncrease}</div>
                </div>
            </div>

            {showDescription && (
                <div className='videoDescription'>
                    <p>
                        <span>Description: </span>
                        {video.description}
                    </p>
                </div>
            )}
        </div>
    );
};

ReportItem.propTypes = {
    item: PropTypes.object.isRequired,
    index: PropTypes.number,
    showDescription: PropTypes.bool,
};

export default ReportItem;
