import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

const MenuItem = ({item, hooks = {}}) => {

    if( item.link ){
        return(
            <Link to={item.link} onClick={() => hooks.onClick && hooks.onClick()} >
                {hooks.beforeItemLabel && hooks.beforeItemLabel(item)}
                {item.label}
                {hooks.afterItemLabel && hooks.afterItemLabel(item)}
            </Link>
        )

    }else{
        return (
            <span onClick={() => hooks.onClick && hooks.onClick()} >
                {hooks.beforeItemLabel && hooks.beforeItemLabel(item)}
                {item.label}
                {hooks.afterItemLabel && hooks.afterItemLabel(item)}
            </span>
        );
    }
}

MenuItem.propTypes = {
    item: PropTypes.object.isRequired,
    hooks: PropTypes.shape({
        beforeItemLabel: PropTypes.func,
        afterItemLabel: PropTypes.func,
        onClick: PropTypes.func
    }),
}

export default MenuItem;