import React, { useLayoutEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import styles from './DateRange.module.scss';
import { DropdownButton } from '@/components/ui';
import { getAllPresets, getAllRelativePresets, getPreset, getRelativePreset } from './_dateRangeHandler';
import 'react-datepicker/dist/react-datepicker.css';
import { clearTimezoneFromDate } from '@/util/format';

const DateRange = ({ dateRange, relativeDateRange = {}, onChange }) => {
    // Get dateRange Object
    const getDateRangeObject = useCallback(
        (presetID) => {
            // Try to get relative date objects first
            let preset = getRelativePreset(presetID);
            if (preset) {
                return {
                    startTime: preset.getStartDate ? preset.getStartDate(relativeDateRange).getTime() : dateRange.startTime,
                    endTime: preset.getEndDate ? preset.getEndDate(relativeDateRange).getTime() : dateRange.endTime,
                    presetID: presetID,
                    presetLabel: preset.label,
                };
            }

            // Default to regular date object
            preset = getPreset(presetID);
            if (preset) {
                return {
                    startTime: preset.getStartDate ? clearTimezoneFromDate(preset.getStartDate()).getTime() : dateRange.startTime,
                    endTime: preset.getEndDate ? clearTimezoneFromDate(preset.getEndDate()).getTime() : dateRange.endTime,
                    presetID: presetID,
                    presetLabel: preset.label,
                };
            }
        },
        [dateRange, relativeDateRange]
    );

    // Check if preset matches dates
    useLayoutEffect(() => {
        const nextDateRange = getDateRangeObject(dateRange.presetID);
        if (dateRange.startTime !== nextDateRange.startTime || dateRange.endTime !== nextDateRange.endTime) {
            onChange(nextDateRange);
        }
    }, [dateRange, getDateRangeObject, onChange]);

    // On Start Date Change
    const onChangeStartDate = (date) => {
        const nextDateRange = getDateRangeObject('custom');
        onChange({ ...nextDateRange, startTime: clearTimezoneFromDate(date).getTime() });
    };

    // On End Date Change
    const onChangeEndDate = (date) => {
        const nextDateRange = getDateRangeObject('custom');
        onChange({ ...nextDateRange, endTime: clearTimezoneFromDate(date).getTime() });
    };

    // On Preset Change
    const onPresetChange = (presetID) => {
        const nextDateRange = getDateRangeObject(presetID);
        onChange(nextDateRange);
    };

    // Get Dropdown Items
    const getDropdownItems = () => {
        let datePresets = relativeDateRange.presetID ? getAllRelativePresets() : getAllPresets();

        return datePresets.map((item, key) => {
            return (
                <div key={key} className='presetItem' onClick={() => onPresetChange(item.id)}>
                    {item.label}
                </div>
            );
        });
    };

    return (
        <div className={classnames('DateRange', styles.module)}>
            <div className='date-pickers'>
                <DatePicker
                    className='input-start-date'
                    selected={new Date(dateRange.startTime)}
                    onChange={(date) => onChangeStartDate(new Date(date.setHours(0, 0, 0, 0)))}
                    dateFormat='d MMM, yyyy'
                />
                <DatePicker
                    className='input-end-date'
                    selected={new Date(dateRange.endTime)}
                    onChange={(date) => onChangeEndDate(new Date(date.setHours(0, 0, 0, 0)))}
                    dateFormat='d MMM, yyyy'
                    minDate={new Date(dateRange.startTime)}
                />
            </div>
            <DropdownButton label='' className={'selectDatePresets'}>
                {getDropdownItems()}
            </DropdownButton>
        </div>
    );
};

DateRange.propTypes = {
    dateRange: PropTypes.shape({
        startTime: PropTypes.number.isRequired,
        endTime: PropTypes.number.isRequired,
        presetID: PropTypes.string.isRequired,
        presetLabel: PropTypes.string,
    }).isRequired,
    relativeDateRange: PropTypes.shape({
        startTime: PropTypes.number.isRequired,
        endTime: PropTypes.number.isRequired,
        presetID: PropTypes.string.isRequired,
        presetLabel: PropTypes.string,
    }),
    onChange: PropTypes.func.isRequired,
};

export default DateRange;
