import Tool from '@/components/tools/Tool.class';
import ViewRouter from '@/components/screens/router/ViewRouter/ViewRouter';
import AccountSettings from './screens/settings/AccountSettings';
import ToolStatus from './partials/ToolStatus';
import ScreenManageAccountUsers from '@/components/screens/settings/ScreenManageAccountUsers/ScreenManageAccountUsers';
import { policies } from './constants';
import ToolWrapper from './ToolWrapper/ToolWrapper';
import PreviewReportPDF from './screens/preview/PreviewReportPDF';
import { YOUTUBE_PERFORMANCE_TOOL } from '@/router/_endpoints';

class YouTube_Perfomance extends Tool {
    constructor(uid) {
        super(uid);
    }

    // URL basename
    url = YOUTUBE_PERFORMANCE_TOOL;

    // Views
    getStatusArea = () => {
        return <ToolStatus tool={this} />;
    };

    getMainViews = () => [
        {
            endpoint: '',
            component: <ToolWrapper tool={this} />,
            permissions: [
                {
                    type: 'userHasPolicies',
                    value: ['can-view-reports'],
                },
            ],
        },
        {
            endpoint: '/:reportId',
            component: <ToolWrapper tool={this} />,
            permissions: [
                {
                    type: 'userHasPolicies',
                    value: ['can-view-reports'],
                },
            ],
        },
        {
            endpoint: 'options/*',
            component: <ViewRouter views={this.getSettingsViews()} permissionsKey={this.uid} />,
        },
        {
            endpoint: '/:reportId/preview-pdf/',
            component: <PreviewReportPDF tool={this} />,
            permissions: [
                {
                    type: 'userHasPolicies',
                    value: ['can-view-reports'],
                },
            ],
        },
    ];

    getSettingsViews = () => [
        {
            endpoint: 'manage-account',
            link: 'options/manage-account/',
            label: 'Manage Account',
            icon: 'fas fa-gears',
            permissions: [
                {
                    type: 'userHasPolicies',
                    value: ['can-edit-settings'],
                },
            ],
            component: <AccountSettings tool={this} />,
        },
        {
            endpoint: 'user-permissions',
            link: 'options/user-permissions/',
            label: 'User Permissions',
            icon: 'fas fa-user-cog',
            permissions: [
                {
                    type: 'userHasPolicies',
                    value: ['can-edit-users'],
                },
            ],
            component: (
                <ScreenManageAccountUsers
                    handlers={{
                        getUsers: this.getUsers,
                        addUser: this.addUser,
                        removeUser: this.removeUser,
                        getAllUsersPermissions: this.getAllUsersPermissions,
                        setUserPermissions: this.setUserPermissions,
                    }}
                    policies={policies}
                />
            ),
        },
    ];
}

export default YouTube_Perfomance;
