import { getBestTimeTickFormat } from './_timeFunctions';

/** --------------------------------------------------------------
 Report Functions
 -------------------------------------------------------------- */

export const generateSalesSummary = (sales, salesTypeOptions) => {
    // Default summary
    const salesSummary = {
        totalRevenue: 0,
        totalTransactions: 0,
        sales: {},
    };

    // Add sales type objects
    for (const salesType of salesTypeOptions) {
        salesSummary.sales[salesType.key] = {
            transactions: 0,
            revenue: 0,
        };
    }

    // Loop through sales
    for (const sale of sales) {
        // Get matching sales type option
        const saleTypeOption = salesTypeOptions.find((type) => type.key === sale.type);

        // Skip sale if sale type option is not defined
        if (!saleTypeOption) continue;

        // Update summery revenue
        switch (saleTypeOption.revenue) {
            case 'add':
                salesSummary.totalRevenue = Math.round((salesSummary.totalRevenue + sale.value) * 100) / 100;
                salesSummary.sales[sale.type].revenue = Math.round((salesSummary.sales[sale.type].revenue + sale.value) * 100) / 100;
                break;
            case 'subtract':
                salesSummary.totalRevenue = Math.round((salesSummary.totalRevenue - sale.value) * 100) / 100;
                salesSummary.sales[sale.type].revenue = Math.round((salesSummary.sales[sale.type].revenue - sale.value) * 100) / 100;
                break;
            case 'ignore':
                break;
            default:
                break;
        }

        // Update summary transaction numbers
        salesSummary.sales[sale.type].transactions++;
        salesSummary.totalTransactions++;
    }

    return salesSummary;
};

export const generateSalesChartEntries = (sales, startTime, endTime, minTicks, offset = 0, salesTypeOptions) => {
    const chartEntries = [];

    // Generate time ticks
    const timeTickFormat = getBestTimeTickFormat(startTime, endTime, minTicks);
    const timeTicks = timeTickFormat.getTicks(startTime, endTime);

    // Early return if no valid time ticks were generated
    if (!timeTicks) {
        return chartEntries;
    }

    // Get Sales Types
    const salesTypes = [];
    sales.forEach((sale) => {
        if (!salesTypes.includes(sale.type)) {
            salesTypes.push(sale.type);
        }
    });

    // Loop through time ticks (ignore last one)
    for (let i = 0; i < timeTicks.length - 1 + offset; i++) {
        // Get sales that are within the time range
        let periodSales = [];
        if (i >= offset) {
            periodSales = sales.filter((sale) => {
                if (sale.datetime) {
                    const saleTime = new Date(sale.datetime).getTime();
                    return saleTime >= timeTicks[i - offset] && saleTime < timeTicks[i + 1 - offset];
                } else {
                    return false;
                }
            });
        }

        // Create new chart data point
        const chartEntry = {
            datetime: timeTickFormat.formatDate(startTime, endTime, new Date(timeTicks[i])),
            revenue: 0,
        };

        // Create empty object for sales types
        salesTypes.forEach((salesType) => {
            chartEntry[salesType] = 0;
        });

        // Process period sales
        for (const sale of periodSales) {

            // Get matching sales type option
            const salesTypeOption = salesTypeOptions.find((type) => type.key === sale.type);

            // Skip sale if type is not defined
            if (!salesTypeOptions) return true;

            // Calculate period revenue
            switch (salesTypeOption.revenue) {
                case 'add':
                    chartEntry.revenue = Math.round((chartEntry.revenue + sale.value) * 100) / 100;
                    break;
                case 'subtract':
                    chartEntry.revenue = Math.round((chartEntry.revenue - sale.value) * 100) / 100;
                    break;
                case 'ignore':
                    break;
                default:
                    break;
            }

            // Increment number for that sales type
            chartEntry[sale.type]++;
        }

        chartEntries.push(chartEntry);
    }

    return chartEntries;
};

/** --------------------------------------------------------------
 Filter Functions
 -------------------------------------------------------------- */

export const validateSalesQuery = (queryObj) => {
    if (!queryObj.productIDs || queryObj.productIDs.length < 1) {
        return {
            valid: false,
            message: 'Please select at least one product or category.',
        };
    }
    if (!queryObj.types || queryObj.types.length < 1) {
        return {
            valid: false,
            message: 'Please select at least one sales type.',
        };
    }
    return {
        valid: true,
        message: 'Generating report, please wait.',
    };
};

export const createSalesQueryFromFilters = (filters, accountProducts) => {
    let productIDs = [];
    let types = [];
    let countries = [];
    let currencies = [];
    let startDate = new Date().toISOString();
    let endDate = new Date().toISOString();

    // Filter: Individual Products
    if (filters.hasOwnProperty('productIDs')) {
        productIDs = [...filters['productIDs']];
    }

    // Filter: Products From Categories
    if (filters.hasOwnProperty('categories')) {
        const metaProductIDs = getProductIDsWithMeta(filters['categories'], accountProducts);
        metaProductIDs.forEach((item) => {
            if (!productIDs.includes(item)) {
                productIDs.push(item);
            }
        });
    }

    // Sales Types
    if (filters.hasOwnProperty('types')) {
        types = filters['types'];
    }

    // Period
    if (filters.hasOwnProperty('period')) {
        startDate = filterStartDate(filters.period.startTime).toISOString();
    }

    if (filters.hasOwnProperty('period')) {
        endDate = filterEndDate(filters.period.endTime).toISOString();
    }

    // Currencies
    if (filters.hasOwnProperty('currencies')) {
        currencies = filters['currencies'];
    }

    // Countries
    if (filters.hasOwnProperty('countries')) {
        countries = filters['countries'];
    }

    const returnObject = {
        productIDs: productIDs,
        types: types,
        startDate: startDate,
        endDate: endDate,
        currencies: currencies,
        countries: countries,
    };

    return returnObject;
};

const getProductIDsWithMeta = (metaField, products) => {
    const metaKey = metaField.metaKey;
    const entryIdKey = metaField.entryIdKey;
    const values = metaField.values;

    const productIDs = [];

    for (const product of products) {
        if (!product.hasOwnProperty('meta')) continue;
        if (!product.meta.hasOwnProperty(metaKey)) continue;
        const entries = product.meta[metaKey];

        for (const entry of entries) {
            if (entry.hasOwnProperty(entryIdKey) && values.includes(entry[entryIdKey])) {
                productIDs.push(product.productID);
                break;
            }
        }
    }

    return productIDs;
};

export const filterStartDate = (startTime) => {
    return new Date(startTime);
};

export const filterEndDate = (endTime) => {
    return new Date(endTime + 1000 * 60 * 60 * 24);
};
