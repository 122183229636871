import React from 'react';
import PropTypes from 'prop-types';
import GoogleLogIn from "./partials/GoogleLogIn";


const AccountSettings = ( {account} ) => {

    return (
        <GoogleLogIn account={account} />
    );
};

AccountSettings.propTypes = {
    account: PropTypes.object.isRequired
};

export default AccountSettings;
