import React, { useState, useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import typeToComponent from '@/components/ui/FormFields';
import styles from './FormStyles.module.scss';
import classnames from 'classnames';
import { useIsMounted } from '@/util/hooks';
import { showFadeIn, hideFadeOut } from '@/animations';

const FormHandler = ({ fields: inputFields, defaultFieldProps, callbacks = {}, formOptions = {} }) => {
    // State
    const [fields, setFields] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [response, setResponse] = useState({});

    // Refs
    const refStatusIcon = createRef();
    const refResponseMsg = createRef();

    // isMounted
    const isMounted = useIsMounted();

    // Hook: Create working fields from input fields
    useEffect(() => {
        // Create default working fields from inputFields
        let nextFields = inputFields.map((field) => {
            const prevField = fields.find((thisField) => thisField.name === field.name);
            const value = field.value !== undefined ? field.value : field.defaultValue;
            return {
                ...field,
                value: value !== undefined ? value : '',
                inputRef: field.inputRef ? field.inputRef : createRef(),
                wrapperRef: field.wrapperRef ? field.wrapperRef : createRef(),
                focused: prevField ? prevField.focused : false,
            };
        });

        // Add default field props if they don't exist
        nextFields = nextFields.map((field) => {
            if (defaultFieldProps) {
                for (const [key, value] of Object.entries(defaultFieldProps)) {
                    field[key] = field[key] ? field[key] : value;
                }
            }
            return field;
        });

        setFields(nextFields);
    }, [inputFields, defaultFieldProps]);

    // Hook: React to submit response changes
    useEffect(() => {
        if (response.clearFields) {
            clearFormFields();
        }
    }, [response]);

    // Animation
    useEffect(() => {
        submitting ? showFadeIn(refStatusIcon.current) : hideFadeOut(refStatusIcon.current, 0);
        response.message ? showFadeIn(refResponseMsg.current) : hideFadeOut(refResponseMsg.current, 0);
    }, [submitting, response]);

    // On Form Submit
    const onFormSubmit = async (e, submitName) => {
        e.preventDefault();
        if (submitting) {
            return;
        }

        setResponse({});
        setSubmitting(true);
        const data = getFormData();

        if (callbacks.onSubmit) {
            const res = await callbacks.onSubmit(data, submitName);
            isMounted() && res && setResponse(res);
        }
        isMounted() && setSubmitting(false);
    };

    // Compile Form Data
    const getFormData = () => {
        const data = [];
        fields.forEach((field) => {
            if (field.type === 'submit') return;
            if (field.type === 'formHeader') return;

            const fieldData = {};
            fieldData.name = field.name;
            fieldData.type = field.type;
            fieldData.value = field.value;
            if (field.hasOwnProperty('files')) {
                fieldData.files = field.files;
            }
            if (field.hasOwnProperty('dataFilter')) {
                fieldData.dataFilter = field.dataFilter;
            }
            data.push(fieldData);
        });
        return data;
    };

    // Clear for fields
    const clearFormFields = () => {
        setFields(
            fields.map((field) => {
                if (field.type !== 'submit') {
                    field.value = '';
                    field.focused = false;
                }
                return field;
            })
        );
    };

    // Field callbacks
    const fieldCallbacks = {
        onChange: (name, value, params = {}) => {
            if (!submitting) {
                setFields(
                    fields.map((field) => {
                        if (field.name === name) {
                            field.value = value;
                            field.changed = field.defaultValue !== undefined && value !== field.defaultValue;
                            // Any other parameters
                            for (let key in params) {
                                field[key] = params[key];
                            }
                        }
                        return field;
                    })
                );
                callbacks.onChange && callbacks.onChange(name, value);
            }
        },
        onClick: (name, e) => {
            const field = fields.find((field) => field.name === name);
            if (field && field.type === 'submit' && field.name !== 'submit') {
                e.preventDefault();
                onFormSubmit(e, field.name).then();
            }
        },
        onFocus: (name) => {
            setFields(
                fields.map((field) => {
                    if (field.name === name) field.focused = true;
                    return field;
                })
            );
        },
        onBlur: (name) => {
            setFields(
                fields.map((field) => {
                    if (field.name === name) field.focused = false;
                    return field;
                })
            );
        },
    };

    // Render Fields Loops
    const formFields = fields
        .filter((field) => {
            // Filter out conditional fields
            if (field.conditions) {
                for (let i = 0; i < field.conditions.length; i++) {
                    const condition = field.conditions[i];
                    const conditionalField = fields.find((thisField) => thisField.name === condition.name);
                    if (!conditionalField || conditionalField.value !== condition.value) {
                        return false;
                    }
                }
            }
            return true;
        })
        .map((field, key) => {
            const renderedField = { ...field };

            // Add callbacks
            for (const [key] of Object.entries(fieldCallbacks)) {
                const superCallback = field[key];
                renderedField[key] = (name, value, params = {}) => {
                    fieldCallbacks[key](name, value, params);
                    superCallback && superCallback(name, value);
                };
            }

            const Component = typeToComponent[renderedField.type];
            if (Component) {
                return <Component key={key} field={renderedField} />;
            } else {
                return <p className='error'>Invalid field type</p>;
            }
        });

    return (
        <form className={classnames(styles.formStyles, formOptions.formClass)} onSubmit={(e) => onFormSubmit(e)}>
            {formFields}
            <span ref={refStatusIcon} style={{ display: 'none' }} className='statusIcon'>
                <i className='fas fa-spinner fa-pulse' />
            </span>
            <span ref={refResponseMsg} style={{ display: 'none' }} className={classnames('submitResponse', response.status)}>
                {response.message}
            </span>
        </form>
    );
};

FormHandler.propTypes = {
    fields: PropTypes.array.isRequired,
    callbacks: PropTypes.shape({
        onSubmit: PropTypes.func,
        onChange: PropTypes.func,
    }),
    formOptions: PropTypes.shape({
        formClass: PropTypes.string,
        hideResponse: PropTypes.bool,
    }),
    defaultFieldProps: PropTypes.object,
};

export default FormHandler;
