import React, { useLayoutEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from '@/router/PrivateRoute';
import * as endpoints from '@/router/_endpoints';
import PageWrapper from '@/pages/PageWrapper';
import NavTop from '@/components/nav/NavTop/NavTop';
import NavSidebar from '@/components/nav/NavSidebar/NavSidebar';
import { ScreenCreateMetricsAccount, ScreenViewMetricsAccount } from '@/components/metrics-account';
import { ScreenYouTubePerformanceTool } from '@/components/tools/YouTubePerformance';
import { ScreenEventLoggerTool } from '@/components/tools/EventLogger';
import ScreenManageAppUsers from '@/components/screens/settings/ScreenManageAppUsers/ScreenManageAppUsers';
import { getAllEntries } from '@/services/strapi/metrics-account/account';
import classnames from 'classnames';
import styles from './Dashboard.module.scss';

const Dashboard = () => {
    // Hook: Get All Metrics Accounts
    useLayoutEffect(() => {
        getAllEntries().then();
    }, []);

    return (
        <PageWrapper pageClass={classnames('pageDashboard', styles.module)}>
            <div id='panelTop'>
                <NavTop />
            </div>

            <div id='panelLeft'>
                <NavSidebar />
            </div>

            <div id='panelMain'>
                <Routes>
                    <Route path={endpoints.CREATE_METRICS_ACCOUNT} element={<PrivateRoute element={<ScreenCreateMetricsAccount />} />} />
                    <Route path={endpoints.METRICS_ACCOUNT_SINGLE + '*'} element={<PrivateRoute element={<ScreenViewMetricsAccount />} />} />
                    <Route path={endpoints.MANAGE_USERS} element={<PrivateRoute element={<ScreenManageAppUsers />} />} />
                    <Route path={endpoints.YOUTUBE_PERFORMANCE_TOOL + '*'} element={<PrivateRoute element={<ScreenYouTubePerformanceTool />} />} />
                    <Route path={endpoints.EVENT_LOGGER + '*'} element={<PrivateRoute element={<ScreenEventLoggerTool />} />} />
                </Routes>
            </div>
        </PageWrapper>
    );
};

export default Dashboard;
