import * as api from '@/services/strapi/rest';
import { logError } from '@/util/debugger';

/** Define collection type basename */
const basename = 'metrics-accounts';

/** Get Account Users */
export const getAccountUsers = async (id) => {
    const endpoint = `${basename}/${id}/users/`;
    const response = await api.restStrapiGetRequest(endpoint, true);
    if (response.error) {
        logError(response);
        return [];
    } else {
        return response;
    }
};

/** Add Account User */
export const addAccountUser = async (id, body = new FormData()) => {
    const endpoint = `${basename}/${id}/add-user/`;
    const response = await api.restStrapiPutRequest(endpoint, true, body);
    if (response.error) {
        logError(response);
        return false;
    } else {
        return response;
    }
};

/** Remove Account User */
export const removeAccountUser = async (id, body = new FormData()) => {
    const endpoint = `${basename}/${id}/remove-user/`;
    const response = await api.restStrapiPutRequest(endpoint, true, body);
    if (response.error) {
        logError(response);
        return false;
    } else {
        return response;
    }
};
