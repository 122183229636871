import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const PageWrapper = ({pageClass, pageId, children}) => {

    return (
        <div id={pageId} className={classnames("pageWrapper", pageClass)}>
            {children}
        </div>
    )

}

PageWrapper.propTypes = {
    pageClass: PropTypes.string,
    pageId: PropTypes.string,
    children: PropTypes.node
}

export default PageWrapper;