import React, { useMemo, useLayoutEffect } from 'react';
import Event_Logger from '../Event_Logger.class';
import DropdownMenu from '@/components/screens/ui/DropdownMenu/DropdownMenu';
import ViewRouter from '@/components/screens/router/ViewRouter/ViewRouter';
import { toolUid } from '../constants';
import styles from './EventLoggerTool.module.scss';

const EventLoggerTool = () => {
    const tool = useMemo(() => new Event_Logger(toolUid), []);

    // Hooks
    useLayoutEffect(() => {
        tool.getCurrentUserPermissions().then();
    }, []);

    return (
        <div className={styles.module}>
            <div className='wrapper'>
                <div className='header'>
                    <div className='title'>
                        <h2>Event Logger</h2>
                        {tool.getStatusArea()}
                    </div>
                    <div className='menu'>
                        <DropdownMenu items={tool.getSettingsViews()} label='Options' permissionsKey={tool.uid} />
                    </div>
                </div>

                <div className='body'>
                    <ViewRouter views={tool.getMainViews()} permissionsKey={tool.uid} />
                </div>
            </div>
        </div>
    );
};

export default EventLoggerTool;
