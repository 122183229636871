import {useEffect, useLayoutEffect, useRef} from "react";

export const useNonInitialEffect = (effect, deps) => {
    const initialRender = useRef(true);

    useEffect(() => {
        let effectReturns = () => {};

        if (initialRender.current) {
            initialRender.current = false;
        } else {
            effectReturns = effect();
        }

        if (effectReturns && typeof effectReturns === "function") {
            return effectReturns;
        }
    }, deps);
};

export const useNonInitialLayoutEffect = (effect, deps) => {
    const initialRender = useRef(true);

    useLayoutEffect(() => {
        let effectReturns = () => {};

        if (initialRender.current) {
            initialRender.current = false;
        } else {
            effectReturns = effect();
        }

        if (effectReturns && typeof effectReturns === "function") {
            return effectReturns;
        }
    }, deps);
};

export const useIsMounted = () => {
    const isMounted = useRef(false);
    useEffect( () => {
        isMounted.current = true;
        return () => {isMounted.current = false}
    }, [] );
    return () => isMounted.current;
}