import React, { useEffect, useState } from 'react';
import { useIsMounted } from '@/util/hooks';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectorAccountBySlug } from '@/store/slices/accounts';
import Account from '@/components/metrics-account/screens/Account';
import { getOneEntryBySlug } from '@/services/strapi/metrics-account/account';
import LoadingIcon from '@/components/ui/LoadingIcon/LoadingIcon';
import styles from './ViewAccount.module.scss';

const ViewAccount = () => {
    // Get slug
    let params = useParams();
    const slug = params.slug;

    // State
    const [fetching, setFetching] = useState(true);

    // Store
    const metricsAccount = useSelector((state) => selectorAccountBySlug(state, slug));

    // Hooks
    const isMounted = useIsMounted();
    useEffect(() => {
        setFetching(true);
        getOneEntryBySlug(slug).then(() => {
            isMounted() && setFetching(false);
        });
    }, []);

    if (fetching) {
        return (
            <div className={styles.module}>
                <div className='viewLoader'>
                    <LoadingIcon />
                </div>
            </div>
        );
    }

    if (metricsAccount) {
        return <Account metricsAccount={metricsAccount} />;
    } else {
        return (
            <div className={styles.module}>
                <div className='view404'>
                    <p className='highlight'>404</p>
                    <p>The requested account couldn't be found.</p>
                </div>
            </div>
        );
    }
};

export default ViewAccount;
