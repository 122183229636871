import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectorReport } from '@/store/slices/reports';
import { modes } from './modeManifest';
import styles from './ModeWrapper.module.scss';

const ModeWrapper = ({ modeId, tool }) => {
    // Router
    const { reportId } = useParams();

    // States
    const [mode, setMode] = useState(null);
    const [parameters, setParameters] = useState({});
    const [loading, setLoading] = useState(false);

    // Selector
    const selectedReport = useSelector((state) => selectorReport(state, tool.uid, reportId));

    // Effect: Select mode from manifest
    useEffect(() => {
        if (modeId) {
            const mode = modes.find((m) => m.id === modeId);
            setMode(mode);
        }
    }, [modeId, selectedReport]);

    // Effect: Set parameters from selected report
    useEffect(() => {
        setLoading(true);
        if (selectedReport) {
            const nextParameters = { ...selectedReport.parameters };
            nextParameters.submit = 'Run Report Again';
            setParameters(nextParameters);
        } else {
            const nextParameters = { ...parameters };
            nextParameters.submit = 'Schedule New Report';
            setParameters(nextParameters);
        }
        setLoading(false);
    }, [reportId, mode]);

    // Handler: on parameters change
    const onParametersChange = (nextParameters) => {
        setParameters(nextParameters);
    };

    // Handler: on submit
    const onQuerySubmit = (fields) => {
        const queryParams = { mode: modeId };
        fields.forEach((field) => {
            if (field.name === 'submit') return;
            queryParams[field.name] = field.value;
        });
        const selectedId = selectedReport ? selectedReport.id : null;

        tool.scheduleReport(selectedId, queryParams).then((res) => {
            if (!res.error) {
                //onReportSelect(res.id);
            }
        });
    };

    // Bail if mode is not found
    if (!mode || loading) {
        return null;
    }

    // Render mode
    return (
        <div className={styles.module}>
            <h3>{mode.title}</h3>
            <mode.QuotaCalculator tool={tool} parameters={parameters} />
            <mode.QueryForm parameters={parameters} onParametersChange={onParametersChange} onQuerySubmit={onQuerySubmit} />
            <mode.Results tool={tool} />
        </div>
    );
};

ModeWrapper.propTypes = {
    modeId: PropTypes.string.isRequired,
    tool: PropTypes.object.isRequired,
};

export default ModeWrapper;
