import React, { useMemo, useLayoutEffect } from 'react';
import { getTemplateClass } from '@/components/metrics-account/accountTypes';
import styles from './Account.module.scss';

const Account = ({ metricsAccount }) => {
    // Create new account object
    const AccountTemplateClass = getTemplateClass(metricsAccount.settings.accountType);
    const account = useMemo(() => new AccountTemplateClass(metricsAccount), [metricsAccount]);

    // Hooks
    useLayoutEffect(() => {
        account.getCurrentUserPermissions().then();
    }, []);

    return (
        <div className={styles.module}>
            <div className='wrapper'>
                <div className='header'>
                    <div className='flexChild'>
                        <div className='title'>
                            <h2>{account.accountName}</h2>
                        </div>
                        <div className='status'>{account.getStatusArea()}</div>
                    </div>

                    <div className='flexChild'>
                        <div className='menu'>{account.getMenuArea()}</div>
                    </div>
                </div>

                <div className='body'>{account.getBodyArea()}</div>
            </div>
        </div>
    );
};

export default Account;
