/** Filters and converts an array of field data into a single JSON object and an array of files (if applicable) */
export const filterFormData = (data) => {

    const outputDataObj = {};
    const outputFiles = [];
    const processedEntries = [];

    for( const entry of data ){

        // Check if field has already been processed
        if( processedEntries.includes( entry.name ) ){ continue; }

        // Check if field has a data filter
        if( entry.dataFilter ){
            switch ( entry.dataFilter.type ) {
                case "dynamicZone":
                    const name = entry.dataFilter.name;
                    const componentID = entry.dataFilter.componentID;

                    // Get all fields related to dynamicZone
                    const dynamicZoneFields = data.filter( (field) => field.hasOwnProperty('dataFilter') && field.dataFilter.componentID === componentID );

                    // Find the __component field
                    const componentField = dynamicZoneFields.find( (field) => field.name === `${componentID}.__component` );
                    if( !componentField ){
                        console.log( "Invalid dynamicZone, will not be processed" );
                        continue;
                    }

                    // Get dynamicZone field
                    const dataObj = outputDataObj[name] ? [...outputDataObj[name]] : [];
                    // Create new component
                    const component = {}
                    dataObj.push(component);
                    outputDataObj[name] = dataObj;
                    const componentIndex = dataObj.length - 1;

                    // Iterate over fields and mark them as processed
                    for( const field of dynamicZoneFields ){
                        const componentFieldName = field.name.substring( componentID.length + 1);
                        if( field.type === 'file' && field.files ){
                            outputFiles.push( {
                                name: `files.${name}[${componentIndex}].${componentFieldName}`,
                                file: field.files[0],
                                fileName: field.files[0].name
                            });
                        }else{
                            component[componentFieldName] = field.value;
                        }
                        processedEntries.push( field.name );
                    }
                    break;
            }

        }else{
            // Default behavior
            outputDataObj[entry.name] = entry.value;
            processedEntries.push( entry.name );
        }
    }

    return {
        data: outputDataObj,
        files: outputFiles
    };
}