import * as api from '@/services/strapi/rest';
import store from '@/store';
import { logError } from '@/util/debugger';
import { fetchedUserPermissions } from '@/store/slices/auth';

// Get the permissions for the current user
export const getCurrentUserPermissions = async (uid) => {
    const endpoint = `${uid}/my-user-permissions`;
    const response = await api.restStrapiGetRequest(endpoint, true);
    if (response.error) {
        logError(response);
        return {};
    } else {
        store.dispatch(fetchedUserPermissions({ key: uid, data: response }));
        return response;
    }
};

// Get all user permissions for a specific tool
export const getAllAccountUserPermissions = async (uid) => {
    const endpoint = `${uid}/user-permissions`;
    const response = await api.restStrapiGetRequest(endpoint, true);
    if (response.error) {
        logError(response);
        return {};
    } else {
        return response;
    }
};

// Set the permissions for a specific user
export const updateAccountUserPermissions = async (uid, body = new FormData()) => {
    const endpoint = `${uid}/user-permissions`;
    const response = await api.restStrapiPutRequest(endpoint, true, body);
    if (response.error) {
        logError(response);
        return {};
    } else {
        return response;
    }
};
