import React from "react";
import PropTypes from "prop-types";
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";


const ReportChart = ({chartEntries, keywords}) => {

    // Chart Tooltip
    const CustomTooltip = ({ active, payload, label }) => {

        if( !payload || !payload.length ) return "";

        const getLegends = keywords.map( (keyword, key) => {
            return (
                <p key={key}>{`Mentions for "${keyword}": ${payload[0].payload[keyword]}`}</p>
            )
        } );

        if (active && payload && payload.length) {
            return (
                <div className="tooltip">
                    <p className="label">{`${label}`}</p>
                    <p>{`Posts: ${payload[0].payload.posts}`}</p>
                    {getLegends}
                </div>
            );
        }
        return null;
    };

    return(
        <div className="reportChart">
            <ResponsiveContainer width="100%" height={500}>
                <LineChart
                    width={500}
                    height={600}
                    data={chartEntries}
                    margin={{
                        top: 10,
                        right: 0,
                        left: 0,
                        bottom: 10,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="datetime" height={35} />
                    <YAxis yAxisId="left" orientation="left" mirror={true} allowDecimals={false} />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend />
                    <Line
                        name="Posts"
                        yAxisId="left"
                        type="monotone"
                        dataKey="posts"
                        stroke="#35a1d6"
                        strokeWidth="3"
                        activeDot={{ r: 8 }}
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    )

}

ReportChart.propTypes = {
    chartEntries: PropTypes.array.isRequired,
    keywords: PropTypes.array.isRequired
}

export default ReportChart;