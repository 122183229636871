import React, { useState, useMemo } from 'react';
import { createFormFields } from './formFields';
import { filterFormData } from '@/services/strapi/dataProcessing';
import FormHandler from '@/components/ui/FormHandler/FormHandler';
import { toFormData } from '@/util/forms';
import { createEntry } from '@/services/strapi/metrics-account/account';

const CreateNewAccountForm = () => {
    // State
    const [values, setValues] = useState({ accountType: null });

    // On form input change
    const onChange = (name, value) => {
        values[name] = value;
        setValues({ ...values });
    };

    // Populate dynamic fields
    const fields = useMemo(() => {
        const renderedFields = [];
        createFormFields.forEach((field) => {
            if (field.type === 'dynamicFieldset') {
                renderedFields.push(...field.populate(values.accountType));
            } else {
                renderedFields.push(field);
            }
        });
        return renderedFields.map((field) => {
            field.value = values.hasOwnProperty(field.name) ? values[field.name] : field.value;
            return field;
        });
    }, [values.accountType]);

    // On form submit
    const onSubmit = async (fields) => {
        const { data, files } = filterFormData(fields);

        const formData = toFormData([
            {
                name: 'data',
                value: JSON.stringify(data),
            },
        ]);

        const res = await createEntry(formData);

        if (res.error) {
            return {
                status: 'error',
                message: `Error: ${res.error}`,
                clearFields: false,
            };
        } else {
            return {
                status: 'success',
                message: 'Account Created.',
                clearFields: true,
            };
        }
    };

    return (
        <FormHandler
            fields={fields}
            formOptions={{
                formClass: 'formStyleA',
            }}
            callbacks={{
                onChange: onChange,
                onSubmit: onSubmit,
            }}
        />
    );
};

export default CreateNewAccountForm;
