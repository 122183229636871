import React from 'react';
import { Account } from '@/components/metrics-account/classes';
import ViewRouter from '@/components/screens/router/ViewRouter/ViewRouter';
import DropdownMenu from '@/components/screens/ui/DropdownMenu/DropdownMenu';
import { AccountSettings, ViewSyncSettings } from '@/components/metrics-account/settings';
import { ViewReportYouTubeSalesAnalytics } from '@/components/metrics-account/reports';
import ScreenManageAccountUsers from '@/components/screens/settings/ScreenManageAccountUsers/ScreenManageAccountUsers';

class Account_YouTube_EDD_Analytics extends Account {
    constructor(account) {
        super(account);
    }

    getRelatedCollectionTypes = () => [
        {
            name: 'Sales',
            id: 'sale',
        },
        {
            name: 'Products',
            id: 'product',
        },
        {
            name: 'Social Media Posts',
            id: 'social-media-post',
        },
    ];

    getMainViews = () => [
        {
            endpoint: '',
            component: <div>Dashboard Placeholder</div>,
        },
        {
            endpoint: 'options/*',
            component: <ViewRouter views={this.getSettingsViews()} permissionsKey={this.slug} />,
        },
        {
            endpoint: 'reports/*',
            permissions: [
                {
                    type: 'userHasPolicies',
                    value: ['can-view-data'],
                },
            ],
            component: <ViewRouter views={this.getReportViews()} permissionsKey={this.slug} />,
        },
    ];

    getReportViews = () => [
        {
            id: 'youtube-to-sales-analytics',
            endpoint: 'youtube-to-sales-analytics',
            link: 'reports/youtube-to-sales-analytics/',
            label: 'YouTube to Sales Analytics',
            permissions: [
                {
                    type: 'userHasPolicies',
                    value: ['can-view-data'],
                },
            ],
            component: <ViewReportYouTubeSalesAnalytics account={this} reportID='youtubeSalesAnalytics' />,
        },
    ];

    getSettingsViews = () => [
        {
            endpoint: 'manage-account',
            link: 'options/manage-account/',
            label: 'Manage Account',
            icon: 'fas fa-gears',
            permissions: [
                {
                    type: 'userHasPolicies',
                    value: ['can-manage-account'],
                },
            ],
            component: <AccountSettings account={this} />,
        },
        {
            endpoint: 'user-permissions',
            link: 'options/user-permissions/',
            label: 'User Permissions',
            icon: 'fas fa-user-cog',
            permissions: [
                {
                    type: 'userHasPolicies',
                    value: ['can-edit-users'],
                },
            ],
            component: (
                <ScreenManageAccountUsers
                    handlers={{
                        getUsers: this.getUsers,
                        addUser: this.addUser,
                        removeUser: this.removeUser,
                        getAllUsersPermissions: this.getAllUsersPermissions,
                        setUserPermissions: this.setUserPermissions,
                    }}
                    policies={this.getPolicies()}
                />
            ),
        },
        {
            endpoint: 'sync-configuration',
            link: 'options/sync-configuration/',
            label: 'Sync Configuration',
            icon: 'fas fa-clock-rotate-left',
            permissions: [
                {
                    type: 'userHasPolicies',
                    value: ['can-edit-sync-config'],
                },
            ],
            component: <ViewSyncSettings account={this} />,
        },
        {
            label: 'Sync Recent Data',
            icon: 'fas fa-sync-alt',
            permissions: [
                {
                    type: 'userHasPolicies',
                    value: ['can-quick-sync-data'],
                },
            ],
            action: async () => this.startDataSync(),
        },
    ];

    getMenuArea = () => {
        return (
            <>
                <DropdownMenu items={this.getReportViews()} label='Reports' permissionsKey={this.slug} />
                <DropdownMenu items={this.getSettingsViews()} label='Options' permissionsKey={this.slug} />
            </>
        );
    };

    getBodyArea = () => {
        return <ViewRouter views={this.getMainViews()} permissionsKey={this.slug} />;
    };
}

export default Account_YouTube_EDD_Analytics;
