import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectorToolSettings } from '@/store/slices/tools';

const QuotaCalculatorChannel = ({ tool, parameters }) => {
    // State
    const [estimate, setEstimate] = useState(null);

    // Selector
    const settings = useSelector((state) => selectorToolSettings(state, tool.uid));

    // Effect: Calculate quota estimate
    useEffect(() => {
        if (settings && settings.apiCosts && parameters.videosNumber) {
            const { apiCosts } = settings;
            const pages = Math.ceil(parameters.videosNumber / 50);
            let totalCost = apiCosts.listChannels;
            totalCost += apiCosts.listPlaylists * pages;
            totalCost += apiCosts.listVideos * pages;
            setEstimate(totalCost);
        }
    }, [settings, parameters]);

    return (
        <div className='quotaEstimate'>
            <p>Estimated Request Cost: {estimate}</p>
        </div>
    );
};

QuotaCalculatorChannel.propTypes = {
    tool: PropTypes.object.isRequired,
    parameters: PropTypes.object.isRequired,
};

export default QuotaCalculatorChannel;
