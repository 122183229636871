import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Actions = ({ tool, report }) => {
    const { status } = report;

    // Stop Report
    const onStopReport = (e) => {
        e.stopPropagation();
        e.preventDefault();
        tool.stopReport(report.id);
    };

    // Resume Report
    const onResumeReport = (e) => {
        e.stopPropagation();
        e.preventDefault();
        tool.resumeReport(report.id);
    };

    // Retry Report
    const onRetryReport = (e) => {
        e.stopPropagation();
        e.preventDefault();
        tool.retryReport(report.id);
    };

    // Action Buttons
    const ActionButtons = () => {
        if (status === 'processing' || status === 'pending') {
            return (
                <span className='action stop' onClick={(e) => onStopReport(e)}>
                    Stop Report
                </span>
            );
        } else if (status === 'stopped') {
            return (
                <span className='action resume' onClick={(e) => onResumeReport(e)}>
                    Resume Report
                </span>
            );
        } else if (status === 'error') {
            return (
                <span className='action retry' onClick={(e) => onRetryReport(e)}>
                    Retry Report
                </span>
            );
        } else {
            return null;
        }
    };

    return (
        <p className={classNames('actionButtons', status)}>
            <ActionButtons />
        </p>
    );
};

Actions.propTypes = {
    report: PropTypes.object.isRequired,
};

export default Actions;
