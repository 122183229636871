import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import FormHandler from '@/components/ui/FormHandler/FormHandler';
import { useSelector } from 'react-redux';
import { getAllUsers, updateUser, deleteUser } from '@/services/strapi/users';
import { sendConfirmationEmail } from '@/services/strapi/auth';
import { userEditFields } from './_formFields';
import { toFormData } from '@/util/forms';

const UserEditForm = ({ user, roles = [] }) => {
    // Store
    const currentUser = useSelector((state) => state.auth.user);

    // Add roles to field
    const fields = useMemo(() => {
        const roleOptions = roles
            .filter((role) => role.type !== 'public')
            .map((role) => {
                return {
                    name: `${role.name}: ${role.description}`,
                    value: role.id,
                };
            });

        return userEditFields.map((field) => {
            if (field.name === 'role') {
                return { ...field, options: roleOptions };
            } else {
                return field;
            }
        });
    }, [roles]);

    // Filter out certain fields
    const filteredFields = fields.filter((field) => {
        switch (field.name) {
            case 'submitSendConfirmation':
                return !user.confirmed;
            case 'submitDeleteUser':
                return currentUser.id !== user.id;
            default:
                return true;
        }
    });

    // Add default values to fields
    const editFields = filteredFields.map((field) => {
        switch (field.name) {
            case 'email':
                return { ...field, defaultValue: user.email };
            case 'displayName':
                return { ...field, defaultValue: user.displayName };
            case 'role':
                return { ...field, defaultValue: user.role.id };
            case 'confirmed':
                return { ...field, defaultValue: user.confirmed };
            default:
                return field;
        }
    });

    // On form submit
    const onSubmit = async (data, submitName) => {
        switch (submitName) {
            // Resend Email Confirmation
            case 'submitSendConfirmation':
                const emailRes = await sendConfirmationEmail(user.email);
                if (emailRes.error) {
                    return {
                        status: 'error',
                        message: 'Server failed to send email confirmation.',
                    };
                } else {
                    return {
                        status: 'success',
                        message: 'Confirmation email sent.',
                    };
                }
                break;

            // Delete User
            case 'submitDeleteUser':
                const deleteRes = await deleteUser(user.id);
                if (deleteRes.error) {
                    return {
                        status: 'error',
                        message: `Error: ${deleteRes.error}`,
                        clearFields: false,
                    };
                } else {
                    getAllUsers().then();
                    return {
                        status: 'success',
                        message: 'User was deleted.',
                        clearFields: true,
                    };
                }

            // Just update the user
            default:
                // Filter out empty fields
                const sanitizedData = data.filter((field) => {
                    return field.value !== '';
                });
                const formData = toFormData(sanitizedData);
                const updateRes = await updateUser(user.id, formData);

                if (updateRes.error) {
                    return {
                        status: 'error',
                        message: `Error: ${updateRes.error}`,
                    };
                } else {
                    getAllUsers().then();
                    return {
                        status: 'success',
                        message: 'User was updated.',
                    };
                }
        }
    };

    return (
        <FormHandler
            fields={editFields}
            formOptions={{
                formClass: 'formStyleA',
            }}
            callbacks={{
                onSubmit: onSubmit,
            }}
        />
    );
};

UserEditForm.propTypes = {
    user: PropTypes.object.isRequired,
    roles: PropTypes.array,
};

export default UserEditForm;
