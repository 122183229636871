import React from 'react';
import { Account } from '@/components/metrics-account/classes';
import ViewRouter from '@/components/screens/router/ViewRouter/ViewRouter';
import DropdownMenu from '@/components/screens/ui/DropdownMenu/DropdownMenu';
import { ViewReportRevenue, ViewReportDailySales } from '@/components/metrics-account/reports';
import { ViewExportData, ViewExportEddData, ViewImportData, ViewSyncSettings } from '@/components/metrics-account/settings';
import ScreenManageAccountUsers from '@/components/screens/settings/ScreenManageAccountUsers/ScreenManageAccountUsers';
import { policies } from '@/components/tools/YouTubePerformance/constants';

class Account_EDD_WP_Sales extends Account {
    constructor(account) {
        super(account);
    }

    getRelatedCollectionTypes = () => [
        {
            name: 'Sales',
            id: 'sale',
        },
        {
            name: 'Products',
            id: 'product',
        },
    ];

    getMainViews = () => [
        {
            endpoint: '',
            component: <div>Dashboard Placeholder</div>,
        },
        {
            endpoint: 'options/*',
            component: <ViewRouter views={this.getSettingsViews()} permissionsKey={this.slug} />,
        },
        {
            endpoint: 'reports/*',
            permissions: [
                {
                    type: 'userHasPolicies',
                    value: ['can-view-data'],
                },
            ],
            component: <ViewRouter views={this.getReportViews()} permissionsKey={this.slug} />,
        },
    ];

    getReportViews = () => [
        {
            id: 'edd-revenue',
            endpoint: 'edd-revenue',
            link: 'reports/edd-revenue/',
            label: 'Revenue Over Time',
            permissions: [
                {
                    type: 'userHasPolicies',
                    value: ['can-view-data'],
                },
            ],
            component: <ViewReportRevenue account={this} reportID='eddRevenue' />,
        },
        {
            id: 'edd-daily-sales',
            endpoint: 'edd-daily-sales',
            link: 'reports/edd-daily-sales/',
            label: 'Daily Sales',
            permissions: [
                {
                    type: 'userHasPolicies',
                    value: ['can-view-data'],
                },
            ],
            component: <ViewReportDailySales account={this} reportID='eddDailySales' />,
        },
    ];

    getSettingsViews = () => [
        {
            endpoint: 'user-permissions',
            link: 'options/user-permissions/',
            label: 'User Permissions',
            icon: 'fas fa-user-cog',
            permissions: [
                {
                    type: 'userHasPolicies',
                    value: ['can-edit-users'],
                },
            ],
            component: (
                <ScreenManageAccountUsers
                    handlers={{
                        getUsers: this.getUsers,
                        addUser: this.addUser,
                        removeUser: this.removeUser,
                        getAllUsersPermissions: this.getAllUsersPermissions,
                        setUserPermissions: this.setUserPermissions,
                    }}
                    policies={this.getPolicies()}
                />
            ),
        },
        // {
        //     endpoint: "import-data",
        //     link: "options/import-data/",
        //     label: "Import Data",
        //     icon: "fas fa-file-import",
        //     permissions: [{
        //         type: "userHasPolicies",
        //         value: ["can-import-data"]
        //     }],
        //     component: <ViewImportData account={this} />
        // },
        {
            endpoint: 'export-data',
            link: 'options/export-data/',
            label: 'Export Data',
            icon: 'fas fa-file-export',
            permissions: [
                {
                    type: 'userHasPolicies',
                    value: ['can-export-data', 'can-export-api-data'],
                },
            ],
            component: (
                <>
                    <ViewExportData account={this} />
                    <ViewExportEddData account={this} />
                </>
            ),
        },
        {
            endpoint: 'sync-configuration',
            link: 'options/sync-configuration/',
            label: 'Sync Configuration',
            icon: 'fas fa-clock-rotate-left',
            permissions: [
                {
                    type: 'userHasPolicies',
                    value: ['can-edit-sync-config'],
                },
            ],
            component: <ViewSyncSettings account={this} />,
        },
        {
            label: 'Sync Recent Data',
            icon: 'fas fa-sync-alt',
            permissions: [
                {
                    type: 'userHasPolicies',
                    value: ['can-quick-sync-data'],
                },
            ],
            action: async () => this.startDataSync(),
        },
    ];

    getMenuArea = () => {
        return (
            <>
                <DropdownMenu items={this.getReportViews()} label='Reports' permissionsKey={this.slug} />
                <DropdownMenu items={this.getSettingsViews()} label='Options' permissionsKey={this.slug} />
            </>
        );
    };

    getBodyArea = () => {
        return <ViewRouter views={this.getMainViews()} permissionsKey={this.slug} />;
    };
}

export default Account_EDD_WP_Sales;
