import React, { useState, useLayoutEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@/components/ui/FormFields';
import { LoadingIcon } from '@/components/ui';
import { useIsMounted } from '@/util/hooks';
import { useLocation } from 'react-router-dom';

const GoogleLogIn = ({ account }) => {
    const [fetching, setFetching] = useState(true);
    const [fetchingMsg, setFetchingMsg] = useState('');
    const [statusMsg, setStatusMsg] = useState('');
    const [components, setComponents] = useState([]);

    // Hooks
    const isMounted = useIsMounted();
    const location = useLocation();

    // Get the response code if it exists
    const queryParams = Object.fromEntries(new URLSearchParams(location.search).entries());
    const code = useMemo(() => (queryParams.code ? queryParams.code : ''), [location]);

    const googleComponentID = useMemo(() => {
        const googleComponent = components.find((component) => component.__component === 'integrations.youtube-analytics-and-reporting');
        return googleComponent ? googleComponent.id : null;
    }, [components]);

    // Additional request parameters
    const params = {
        redirect_uri: window.location.protocol + '//' + window.location.host + location.pathname,
    };

    // Get integration components;
    useLayoutEffect(() => {
        setFetching(true);
        setFetchingMsg('Fetching Google connections status.');
        account.getIntegrationComponents().then((data) => {
            if (isMounted) {
                setComponents(data);
                setFetching(false);
                setFetchingMsg('');
            }
        });
    }, [account]);

    // Generate and store access tokens if code param exists
    useLayoutEffect(() => {
        if (googleComponentID && code !== '') {
            setFetching(true);
            setFetchingMsg('Setting access tokens.');
            account.setIntegrationAccessTokens(googleComponentID, code, params).then((resp) => {
                isMounted() && setFetching(false);
                isMounted() && setFetchingMsg('');
                if (resp.error) {
                    isMounted() && setStatusMsg(resp.error.message);
                } else {
                    isMounted() && setStatusMsg(resp.success.message);
                }
            });
        }
    }, [googleComponentID, code]);

    const getAuthUrl = (componentID) => {
        account.getIntegrationAuthUrl(componentID, params).then((resp) => {
            if (resp.authUrl) {
                window.open(resp.authUrl, '_self');
            }
        });
    };

    if (fetching) {
        return (
            <LoadingIcon>
                <span>{fetchingMsg}</span>
            </LoadingIcon>
        );
    }

    return (
        <div>
            {googleComponentID ? (
                <Button
                    field={{
                        value: (
                            <>
                                <i className='fa-brands fa-google' /> Authenticate with Google{' '}
                            </>
                        ),
                        onClick: () => getAuthUrl(googleComponentID),
                    }}
                />
            ) : (
                <p>There's no component that needs authentication.</p>
            )}
            {statusMsg && <p>{statusMsg}</p>}
        </div>
    );
};

GoogleLogIn.propTypes = {
    account: PropTypes.object.isRequired,
};

export default GoogleLogIn;
