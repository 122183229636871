import React, { useState, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { SelectMultiple } from '@/components/ui/FormFields';
import { useSelector } from 'react-redux';
import { selectorCurrentUserPermissions } from '@/store/slices/auth';
import { userHasPolicies } from '@/services/strapi/permissions';
import { useIsMounted } from '@/util/hooks';
import { Text } from '@/components/ui/FormFields';
import styles from './SocialMediaQueries.module.scss';

const SocialMediaQueries = ({ account }) => {
    // State
    const [fieldKeywords, setFieldKeywords] = useState('');
    const [fieldPlatforms, setFieldPlatforms] = useState([]);

    // Selectors
    const user = useSelector((state) => state.auth.user);
    const currentUserPermissions = useSelector((state) => selectorCurrentUserPermissions(state, account.slug));

    // Hooks
    const isMounted = useIsMounted();

    // Const
    const accountQueries = useMemo(() => account.getSavedQueries(), [account]);

    // On Field Changes
    const onKeywordsChange = (string) => {
        setFieldKeywords(string);
    };

    const onPlatformsChange = (arr) => {
        setFieldPlatforms(arr);
    };

    // On Query Add
    const onQueryAdd = (e) => {
        e.preventDefault();

        if (fieldPlatforms.length < 1 || fieldKeywords.length < 3) {
            return;
        }

        const nextAccountQueries = [...accountQueries];

        nextAccountQueries.push({
            keywords: fieldKeywords.split(',').map((keyword) => keyword.trim().toLowerCase()),
            platforms: fieldPlatforms,
        });

        account.setQueries(nextAccountQueries).then(() => {
            isMounted() && setFieldKeywords('');
        });
    };

    // On Query Delete
    const onQueryDelete = (index) => {
        const nextAccountQueries = [...accountQueries];

        if (index < nextAccountQueries.length) {
            // Remove query by index
            nextAccountQueries.splice(index, 1);

            account.setQueries(nextAccountQueries).then(() => {
                isMounted() && setFieldKeywords('');
            });
        }
    };

    // Element: Query Add Form
    const AddQueryForm = (
        <form className='queryAddForm' onSubmit={(e) => onQueryAdd(e)}>
            <div className='fieldWrapper'>
                <div className='fieldLabel'>
                    <strong>Keywords (comma, separated)</strong>
                </div>
                <Text
                    field={{ inputClass: 'inputKeywords', value: fieldKeywords, required: true, onChange: (name, value) => onKeywordsChange(value) }}
                />
            </div>
            <div className='fieldWrapper'>
                <div className='fieldLabel'>
                    <strong>Platforms</strong>
                </div>
                <SelectMultiple
                    field={{
                        options: account.getPlatformOptions(),
                        value: fieldPlatforms,
                        onChange: (name, value) => onPlatformsChange(value),
                        summaryLabels: {
                            blank: 'Select Platforms',
                            multiple: '{count} Platforms Selected',
                            all: 'All Platforms',
                        },
                    }}
                />
            </div>
            <div className='fieldWrapper'>
                <input type='submit' className='addQueryButton' value='Add Query' />
            </div>
        </form>
    );

    // Element: Query View Form
    const renderQueryViewForm = () => {
        const listQueryItems = () => {
            return accountQueries.map((query, key) => {
                const platforms = query.hasOwnProperty('platforms') ? query.platforms : [];
                const keywords = query.hasOwnProperty('keywords') ? query.keywords : [];

                const platformLabels = platforms.map((platform) => {
                    const platformOption = account.getPlatformOptions().find((platformOption) => platformOption.value === platform);
                    return platformOption ? platformOption.name : 'Error';
                });

                return (
                    <Fragment key={key}>
                        <div className='gridItem itemStyleCell'>
                            {platformLabels.map((item, key) => (
                                <span className='tagPlatform' key={key}>
                                    {item}
                                </span>
                            ))}
                        </div>
                        <div className='gridItem itemStyleCell'>
                            {keywords.map((item, key) => (
                                <span className='tagKeyword' key={key}>
                                    {item}
                                </span>
                            ))}
                        </div>
                        <div className='gridItem itemStyleCell'>
                            {userHasPolicies(user, currentUserPermissions, ['can-edit-integration-options']) && (
                                <span onClick={() => onQueryDelete(key)} className='deleteAction'>
                                    Delete
                                </span>
                            )}
                        </div>
                    </Fragment>
                );
            });
        };

        return (
            <>
                <h4>Active Queries</h4>
                <div className='queryTable'>
                    <div className='gridItem itemStyleHeader'>Platforms</div>
                    <div className='gridItem itemStyleHeader'>Keywords</div>
                    <div className='gridItem itemStyleHeader'>Edit</div>
                    {listQueryItems()}
                </div>
            </>
        );
    };

    // Element: Sync Button
    const renderSyncButton = () => {
        return (
            <div className='syncButtonWrapper'>
                <p>Fetch most recent posts based on the queries listed, or wait for the next automatic synchronization.</p>
                <button className='syncAccountButton' onClick={() => account.startDataSync()}>
                    Sync Recent Data
                </button>
            </div>
        );
    };

    return (
        <div className={styles.module}>
            <h4>Query Social Media</h4>
            <p>Fetch and store posts/comments based on keywords from integrated social media platforms.</p>
            {userHasPolicies(user, currentUserPermissions, ['can-edit-account-settings']) && AddQueryForm}
            {renderQueryViewForm()}
            {userHasPolicies(user, currentUserPermissions, ['can-quick-sync-data']) && renderSyncButton()}
        </div>
    );
};

SocialMediaQueries.propTypes = {
    account: PropTypes.object.isRequired,
};

export default SocialMediaQueries;
