import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Text } from '@/components/ui/FormFields';

const TextInput = ({ value, onChange }) => {
    return (
        <div className={classnames('FilterTextInput')}>
            <Text field={{ value: value, onChange: (name, value) => onChange(value) }} />
        </div>
    );
};

TextInput.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default TextInput;
