import React, { Fragment, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getAllUsers } from '@/services/strapi/users';
import UserEditForm from '@/components/forms/users/UserEditForm';
import styles from './UserTable.module.scss';

const UserTable = ({ roles = [] }) => {
    // Selectors
    const users = useSelector((state) => state.users);

    // State
    const [selectedUser, setSelectedUser] = useState({});

    // Get Products
    useLayoutEffect(() => {
        getAllUsers().then();
    }, []);

    // On Edit User
    const onEditUser = (user) => {
        if (selectedUser.id && selectedUser.id === user.id) {
            setSelectedUser({});
        } else {
            setSelectedUser(user);
        }
    };

    const userTableRows = users.map((user, key) => {
        return (
            <Fragment key={key}>
                <div className='gridItem itemStyleCell'>{user.displayName}</div>
                <div className='gridItem itemStyleCell'>{user.email}</div>
                <div className='gridItem itemStyleCell'>{user.role.name}</div>
                <div className='gridItem itemStyleCell centerAlign'>
                    <span className='confirmedIcon'>
                        {user.confirmed ? <i className='fas fa-user-check' /> : <i className='fas fa-user-slash' />}
                    </span>
                </div>
                <div className='gridItem itemStyleCell centerAlign'>
                    <span className='editIcon' onClick={() => onEditUser(user)}>
                        <i className='fas fa-pen' />
                    </span>
                </div>
                {selectedUser.id && selectedUser.id === user.id && (
                    <div className='gridItem itemStyleCell editForm'>
                        <UserEditForm user={user} roles={roles} />
                    </div>
                )}
            </Fragment>
        );
    });

    return (
        <div className={styles.module}>
            <div className='userTable'>
                <div className='gridItem itemStyleHeader'>Display Name</div>
                <div className='gridItem itemStyleHeader'>Email</div>
                <div className='gridItem itemStyleHeader'>Role</div>
                <div className='gridItem itemStyleHeader centerAlign'>Confirmed</div>
                <div className='gridItem itemStyleHeader centerAlign'>Edit</div>
                {userTableRows}
            </div>
        </div>
    );
};

UserTable.propTypes = {
    roles: PropTypes.array,
};

export default UserTable;
