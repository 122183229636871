import * as api from '@/services/strapi/rest';
import store from '@/store';
import { logError } from '@/util/debugger';
import { fetchedProducts } from '@/store/slices/products';

// Basename
const basename = 'metrics-accounts';

// Default query
export const defaultQuery = {
    populate: '*',
    pagination: {
        page: 1,
        pageSize: 1000,
    },
};

// Get all collection entries and update the store
export const getAllAccountProducts = async (account, query = {}) => {
    let pageCount = 1;
    let page = 1;
    const allItems = [];

    while (pageCount >= page) {
        const queryString = api.createQueryString({ ...defaultQuery, pagination: { ...defaultQuery.pagination, page: page } }, query);
        const response = await api.restStrapiGetRequest(`${basename}/${account.id}/products/?${queryString}`, true);
        if (!response.error) {
            allItems.push(...response.results);
            pageCount = response.pagination.pageCount;
        } else {
            logError(response);
        }
        page++;
    }

    store.dispatch(fetchedProducts({ key: account.slug, data: allItems }));
    return allItems;
};
