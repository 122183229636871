import React, { useState, useLayoutEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useIsMounted } from '@/util/hooks';
import { useSelector } from 'react-redux';
import { selectorProducts } from '@/store/slices/products';
import { selectorDataset } from '@/store/slices/datasets';
import { selectorFilters } from '@/store/slices/filters';
import { salesTypeOptions, reportOptions } from './constants';
import { DropdownPanel, LoadingIcon } from '@/components/ui';
import ReportFilters from './partials/ReportFilters';
import SummaryStats from './partials/SummaryStats';
import { createSalesQueryFromFilters, validateSalesQuery, filterStartDate, filterEndDate } from '../_sales';
import { createFormData } from '../_common';
import { generateSalesSummary, generateSalesChartEntries } from '../_sales';
import ReportChart from './partials/ReportChart';
import { currencies, countries } from '../../filters/constants';
import styles from './ReportRevenue.module.scss';
import commonStyles from '@/components/metrics-account/reports/CommonStyles.module.scss';

const ReportRevenue = ({ account, reportID }) => {
    const datasetSalesKey = `${account.slug}/${reportID}/sales`;
    const filtersKey = `${account.slug}/${reportID}`;

    // State
    const [fetching, setFetching] = useState(false);
    const [showFilters, setShowFilters] = useState(true);
    const [userMessage, setUserMessage] = useState({});

    // Selectors
    const accountProducts = useSelector((state) => selectorProducts(state, account.slug));
    const datasetSales = useSelector((state) => selectorDataset(state, datasetSalesKey));
    const filters = useSelector((state) => selectorFilters(state, filtersKey));

    // Hook: useIsMounted
    const isMounted = useIsMounted();

    // Hook: Get Account Products
    useLayoutEffect(() => {
        if (!accountProducts.length) {
            setFetching(true);
            account.getAllProducts().then(() => {
                isMounted() && setFetching(false);
            });
        }
    }, [account]);

    // Hook: Get Sales Dataset(s)
    useLayoutEffect(() => {
        if (Object.keys(filters).length <= 0) return;

        const sanitizedFilters = {
            productIDs: filters.productIDs,
            categories: filters.categories,
            types: filters.types,
            period: filters.period,
            currencies: filters.currencies,
            countries: filters.countries,
        };

        const salesQuery = createSalesQueryFromFilters(sanitizedFilters, accountProducts);
        const queryValidation = validateSalesQuery(salesQuery);

        if (queryValidation.valid) {
            const formData = createFormData(salesQuery);
            setFetching(true);
            setUserMessage({ className: 'pending', message: queryValidation.message });
            account.getSales(formData).then((response) => {
                account.saveDataset(datasetSalesKey, response);
                isMounted() && setFetching(false);
                isMounted() && setUserMessage({});
            });
        } else {
            setUserMessage({ className: 'error', message: queryValidation.message });
        }
    }, [filters]);

    // Memo: Selected Sales Type Options
    const selectedChartSalesTypeOptions = useMemo(() => {
        if (filters.types) {
            return reportOptions.salesTypes.filter((salesType) => filters.types.includes(salesType.key));
        }
        return [];
    }, [filters]);

    // Memo: Generate Sales Summary
    const salesSummary = useMemo(() => {
        return generateSalesSummary(datasetSales, reportOptions.salesTypes);
    }, [datasetSales]);

    // Selected Currency Options
    const selectedChartCurreciesOptions = useMemo(() => {
        if (filters.currencies) {
            return currencies.filter((currencies) => filters.currencies.includes(currencies.name));
        }
        return [];
    }, [filters]);

    // Selected Countries
    const selectedChartCountries = useMemo(() => {
        if (filters.countries) {
            return countries.filter((country) => filters.countries.includes(country.value));
        }
        return [];
    }, [filters]);

    // Hook: Generate Chart Data
    const chartEntries = useMemo(() => {
        if (filters.period) {
            const startTime = filterStartDate(filters.period.startTime).getTime();
            const endTime = filterEndDate(filters.period.endTime).getTime();
            return generateSalesChartEntries(
                datasetSales,
                startTime,
                endTime,
                12,
                0,
                reportOptions.salesTypes,
                selectedChartCurreciesOptions,
                selectedChartCountries
            );
        } else {
            return [];
        }
    }, [datasetSales]);

    return (
        <div className={classnames(commonStyles.module, styles.module)}>
            {/* Header */}
            <div className='viewHeader'>
                <h3>Revenue</h3>
                <div className='headerControls'>
                    <p className='showFilters' onClick={() => setShowFilters(!showFilters)}>
                        <i className='fas fa-filter' /> Filters
                    </p>
                </div>
                <div className='fetchingStatus'>{fetching && <LoadingIcon />}</div>
                <div className='userMessage'>{userMessage.message && <p className={userMessage.className}>{userMessage.message}</p>}</div>
            </div>

            {/* Filters */}
            <DropdownPanel show={showFilters} hideButton={true}>
                <ReportFilters
                    account={account}
                    filtersKey={filtersKey}
                    options={{
                        products: accountProducts,
                        salesTypes: salesTypeOptions,
                    }}
                />
            </DropdownPanel>

            {/* Summary Stats */}
            {filters.period && <SummaryStats salesSummary={salesSummary} selectedChartSalesTypeOptions={selectedChartSalesTypeOptions} />}

            {/* Reports */}
            <ReportChart chartEntries={chartEntries} selectedChartSalesTypeOptions={selectedChartSalesTypeOptions} />
        </div>
    );
};

ReportRevenue.propTypes = {
    account: PropTypes.object.isRequired,
    reportID: PropTypes.string.isRequired,
};

export default ReportRevenue;
