import React from "react";
import PropTypes from "prop-types";
import styles from "../Fields.module.scss";
import classnames from "classnames";

const FormHeader = ({field}) => {

    return (
        <div ref={field.wrapperRef}
             className={classnames("fieldWrapper typeFormHeader",
                 styles.typeFormHeader,
                 field.wrapperClass)
             }
        >
            <h5>
                {field.label}
            </h5>
        </div>
    );
}

FormHeader.propTypes = {
    label: PropTypes.string,
    wrapperRef: PropTypes.object,
    wrapperClass: PropTypes.string,

}

export default FormHeader;