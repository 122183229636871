/** Get menu item field */
const getField = (field, item, ...args) => {
    if( typeof item[field] === "function" ){
        return item[field](...args)
    }else{
        return item[field];
    }
}

/** Crate menu item */
const createMenuItem = (item, ...args) => {
    const menuItem = {};
    const fieldsIDS = Object.keys(item);
    fieldsIDS.forEach( (fieldID) => {
        menuItem[fieldID] = getField( fieldID, item, ...args );
    });
    return menuItem;
}

/** Menu items filter to compile with dynamic content */
export const filterCompileMenuItems = (menuItems, objects = {}) => {

    const filteredMenuItems = [];

    const compileMenuLevel = (items, parentID) => {

        items.forEach( (item) => {

            switch( item.filter ) {

                // Handle object item
                case "arrayOfObjects":
                    const arrayID = item.arrayID;
                    if (objects.hasOwnProperty(arrayID)) {
                        if( objects[arrayID].length > 0 ) {
                            objects[arrayID].forEach((object) => {
                                const menuItem = createMenuItem(item, object);
                                menuItem["parentID"] = parentID;
                                filteredMenuItems.push(menuItem);
                            });
                        }else if( item.empty ){
                            const menuItem = {
                                parentID: parentID,
                                id: parentID + "NotFound",
                                label: item.empty()
                            };
                            filteredMenuItems.push(menuItem);
                        }
                    }
                    break;

                // Default Handler
                default:
                    const menuItem = createMenuItem( item );
                    menuItem["parentID"] = parentID;
                    filteredMenuItems.push({...menuItem});

                    if (item.submenu) {
                        compileMenuLevel(item.submenu, menuItem.id);
                    }
            }
        });
    }

    compileMenuLevel(menuItems, 0);
    return filteredMenuItems;
}

/** Get all ancestor items of child item */
export const getAncestorItems = (menuItems, childItem) => {

    const getParentItem = (childItem) => {
        return menuItems.find( (item) => item.id === childItem.parentID );
    }

    const ancestorItems = [];
    let parent = getParentItem( childItem );
    while( parent ){
        ancestorItems.push(parent);
        parent = getParentItem( parent.parentID );
    }

    return ancestorItems;
}

