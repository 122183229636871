import store from '@/store';
import { fetchedLogGroups, fetchedLogEntries } from '@/store/slices/logEntries';
import * as api from '@/services/strapi/rest';
import { logError } from '@/util/debugger';

/** Default Query */
export const defaultQuery = {
    pagination: {
        page: 1,
        pageSize: 100,
    },
};

// Get log entries count in log book
export const getLogEntriesCount = async (uid, query = {}) => {
    const queryString = api.createQueryString({}, query);
    const endpoint = `${uid}/logs/count/?${queryString}`;
    const response = await api.restStrapiGetRequest(endpoint, true);
    if (response.error) {
        logError(response);
        return { error: response.error.message };
    } else {
        return response;
    }
};

// Get log groups count in log book
export const getLogGroupsCount = async (uid, query = {}) => {
    const queryString = api.createQueryString({}, query);
    const endpoint = `${uid}/groups/count/?${queryString}`;
    const response = await api.restStrapiGetRequest(endpoint, true);
    if (response.error) {
        logError(response);
        return { error: response.error.message };
    } else {
        return response;
    }
};

// Get log entries
export const getLogEntries = async (uid, query = {}) => {
    const queryString = api.createQueryString(defaultQuery, query);
    const endpoint = `${uid}/logs/?${queryString}`;
    const response = await api.restStrapiGetRequest(endpoint, true);
    if (response.error) {
        logError(response);
        return { error: response.error.message };
    } else {
        store.dispatch(fetchedLogEntries({ key: uid, data: response.results }));
        return response;
    }
};

// Clear log entries from log book
export const clearLogEntries = async (uid, logBookKey) => {
    const endpoint = `${uid}/${logBookKey}/logs/clear`;
    const response = await api.restStrapiPostRequest(endpoint, true);
    if (response.error) {
        logError(response);
        return { error: response.error.message };
    } else {
        return response;
    }
};

// Delete log group
export const deleteLogGroup = async (uid, logBookKey, group) => {
    const endpoint = `${uid}/${logBookKey}/groups/${group}`;
    const response = await api.restStrapiDeleteRequest(endpoint, true);
    if (response.error) {
        logError(response);
        return { error: response.error.message };
    } else {
        return response;
    }
};
