import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Label from './partials/Label';
import styles from '../Fields.module.scss';

const Boolean = ({ field }) => {
    const value = !!field.value;

    // Hook: Correct field value if string
    useLayoutEffect(() => {
        if (value !== field.value) {
            field.onChange && field.onChange(field.name, value);
        }
    }, [field.value]);

    const onToggle = () => {
        const checked = !value;
        field.onChange && field.onChange(field.name, checked);
    };

    return (
        <div
            ref={field.wrapperRef}
            className={classnames(
                'fieldWrapper typeBoolean',
                styles.typeBoolean,
                field.wrapperClass,
                value && 'checked',
                field.focused && 'focused',
                field.disabled && 'disabled'
            )}
            onFocus={() => field.onFocus && field.onFocus(field.name)}
            onBlur={() => field.onBlur && field.onBlur(field.name)}
            tabIndex={-1}>
            <div className={classnames('field', field.fieldClass)}>
                <input
                    ref={field.inputRef}
                    id={field.name}
                    className={field.inputClass}
                    name={field.name}
                    type='checkbox'
                    checked={value ? 'checked' : ''}
                    readOnly='readOnly'
                    required={field.required && 'required'}
                    style={{ display: 'none' }}
                />
                <div className={classnames('inputBoolean', value ? 'checked' : '')} onClick={onToggle}>
                    <div className='handle' />
                </div>
                <Label field={field} />
                {field.afterInput}
            </div>
        </div>
    );
};

Boolean.propTypes = {
    field: PropTypes.shape({
        // Base props
        name: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        placeholder: PropTypes.string,
        required: PropTypes.bool,
        // Ref props
        inputRef: PropTypes.object,
        wrapperRef: PropTypes.object,
        // Callback props
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        onClick: PropTypes.func,
        // Class props
        wrapperClass: PropTypes.string,
        fieldClass: PropTypes.string,
        inputClass: PropTypes.string,
        // Label props
        label: PropTypes.string,
        labelClass: PropTypes.string,
        hideLabel: PropTypes.bool,
        hideRequiredSign: PropTypes.bool,
        // Other props
        afterInput: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    }),
};

export default Boolean;
