import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as endpoints from './_endpoints';

const AuthRedirect = ({ allowableRolesTypes = [] }) => {
    const user = useSelector((state) => state.auth.user);

    if (allowableRolesTypes.includes(user.role.type)) {
        return '';
    } else {
        return <Navigate to={endpoints.NOT_FOUND} />;
    }
};

AuthRedirect.propTypes = {
    allowableRolesTypes: PropTypes.array,
};

export default AuthRedirect;
