import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectorCurrentUserPermissions } from '@/store/slices/auth';
import styles from './ExportEddData.module.scss';
import { Button, Select, SelectMultiple } from '@/components/ui/FormFields';
import * as Filters from '@/components/metrics-account/filters';
import { eddDataTypes, exportTemplates } from './_exportOptions';
import LoadingIcon from '@/components/ui/LoadingIcon/LoadingIcon';
import { createCSVFileDownload } from '@/util/files';
import { useIsMounted } from '@/util/hooks';

import { userHasPolicies } from '@/services/strapi/permissions';
import { clearTimezoneFromDate } from '@/util/format';

const ExportEddData = ({ account }) => {
    // State
    const [fetching, setFetching] = useState(false);
    const [dataType, setDataType] = useState('');
    const [templateName, setTemplateName] = useState('');
    const [exportFields, setExportFields] = useState([]);
    const [dateRange, setDateRange] = useState({
        startTime: clearTimezoneFromDate(new Date(new Date().setHours(0, 0, 0, 0))).getTime(),
        endTime: clearTimezoneFromDate(new Date(new Date().setHours(0, 0, 0, 0))).getTime(),
        presetID: 'this-year',
        presetLabel: '',
    });

    // Selectors
    const user = useSelector((state) => state.auth.user);
    const currentUserPermissions = useSelector((state) => selectorCurrentUserPermissions(state, account.slug));

    // Hooks
    const isMounted = useIsMounted();

    // User Access Check
    if (!userHasPolicies(user, currentUserPermissions, ['can-export-api-data'])) {
        return '';
    }

    // Event Handlers
    const onGenerateExport = () => {
        const data = {
            dataType: dataType,
            fields: exportFields,
            filters: {
                startDate: new Date(dateRange.startTime).toISOString(),
                endDate: new Date(dateRange.endTime).toISOString(),
            },
            template: templateName ? templateName : 'default',
        };

        setFetching(true);
        account.getApiExportData(data).then((response) => {
            if (!response.error) {
                const date = new Date();
                const csvContent = response
                    .map((row) => {
                        return row.map((entry) => `"${entry}"`).join(',');
                    })
                    .join('\n');
                createCSVFileDownload(csvContent, `edd_export_${templateName}_${dataType}s_${date.toISOString()}`);
            }
            isMounted() && setFetching(false);
        });
    };

    // Get Template Options
    const getTemplateOptions = (dataType) => {
        if (!dataType) {
            return [];
        }
        const templateObject = exportTemplates.find((item) => item.key === dataType);
        return templateObject ? templateObject.templates : [];
    };

    // On Template Change
    const onTemplateChange = (nextTemplate) => {
        if (nextTemplate === 'default') {
            setExportFields([]);
        } else {
            const dataTypeFields = dataType ? eddDataTypes.find((item) => item.value === dataType).fields : [];
            setExportFields(dataTypeFields.map((field) => field.value));
        }
        setTemplateName(nextTemplate);
    };

    // Renders
    const renderExportForm = () => {
        const templateOptions = getTemplateOptions(dataType);
        const fieldOptions = dataType ? eddDataTypes.find((item) => item.value === dataType).fields : [];

        return (
            <div className='eddExportForm'>
                <h2>Export EDD Data</h2>
                <p>EDD data is exported from the EDD WP instance and no data is stored.</p>

                <p>1. Choose data type to export</p>
                <Select
                    field={{
                        options: eddDataTypes,
                        value: dataType,
                        required: true,
                        onChange: (name, value) => {
                            setDataType(value);
                            setExportFields([]);
                        },
                    }}
                />

                <p>2. Select date range</p>
                <Filters.DateRange dateRange={dateRange} onChange={(value) => setDateRange(value)} />

                <p>3. Select an export template</p>
                <Select
                    field={{
                        options: templateOptions,
                        value: templateName,
                        required: true,
                        onChange: (name, value) => {
                            onTemplateChange(value);
                        },
                    }}
                />

                <p>4. Select fields to export</p>
                <SelectMultiple
                    field={{
                        options: fieldOptions,
                        value: exportFields,
                        onChange: (name, value) => {
                            setExportFields(value);
                            setTemplateName('default');
                        },
                        summaryLabels: {
                            blank: 'No Fields Selected',
                            multiple: '{count} Fields Selected',
                            all: 'All Fields Selected',
                        },
                    }}
                />

                <p>5. Generate export file</p>
                <Button
                    field={{
                        value: 'Generate Export File',
                        disabled: !dataType || fetching,
                        onClick: onGenerateExport,
                        afterInput: fetching && <LoadingIcon />,
                    }}
                />
            </div>
        );
    };

    return <div className={styles.module}>{renderExportForm()}</div>;
};

ExportEddData.propTypes = {
    account: PropTypes.object.isRequired,
};

export default ExportEddData;
