import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Label from './partials/Label';
import styles from '../Fields.module.scss';

const Button = ({ field }) => {
    return (
        <div
            ref={field.wrapperRef}
            className={classnames('fieldWrapper typeButton', styles.typeButton, field.wrapperClass, field.disabled && 'disabled')}
            onFocus={() => field.onFocus && field.onFocus(field.name)}
            onBlur={() => field.onBlur && field.onBlur(field.name)}
            tabIndex={-1}>
            <Label field={field} />
            <div className={classnames('field', field.disabled && 'disabled', field.fieldClass)}>
                <button
                    ref={field.inputRef}
                    id={field.name}
                    className={field.inputClass}
                    type='button'
                    disabled={field.disabled}
                    onClick={() => field.onClick && field.onClick(field.name)}>
                    {field.value ? field.value : ''}
                </button>
                {field.afterInput}
            </div>
        </div>
    );
};

Button.propTypes = {
    field: PropTypes.shape({
        // Base props
        name: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        placeholder: PropTypes.string,
        required: PropTypes.bool,
        // Ref props
        inputRef: PropTypes.object,
        wrapperRef: PropTypes.object,
        // Callback props
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        onClick: PropTypes.func,
        // Class props
        wrapperClass: PropTypes.string,
        fieldClass: PropTypes.string,
        inputClass: PropTypes.string,
        // Label props
        label: PropTypes.string,
        labelClass: PropTypes.string,
        hideLabel: PropTypes.bool,
        hideRequiredSign: PropTypes.bool,
        // Other props
        afterInput: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    }),
};

export default Button;
