import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectorProducts } from '@/store/slices/products';
import { selectorDataset } from '@/store/slices/datasets';
import { selectorFilters } from '@/store/slices/filters';
import commonStyles from '@/components/metrics-account/reports/CommonStyles.module.scss';
import { DropdownPanel, LoadingIcon } from '@/components/ui';
import ReportFilters from './partials/ReportFilters';
import VideoList from './partials/VideoList';
import { useIsMounted } from '@/util/hooks';
import { createSalesQueryFromFilters, validateSalesQuery } from '@/components/metrics-account/reports/_sales';
import { createFormData } from '@/components/metrics-account/reports/_common';
import { createSocialMediaPostsQueryFromFilters, validateSocialMediaPostsQuery } from '@/components/metrics-account/reports/_socialMediaPosts';
import styles from './ReportYouTubeSalesAnalytics.module.scss';
import classnames from 'classnames';

const ReportYouTubeSalesAnalytics = ({ account, reportID }) => {
    // Datasets
    const datasetPostsKey = `${account.slug}/${reportID}/posts`;
    const datasetSalesKey = `${account.slug}/${reportID}/sales`;
    const filtersKey = `${account.slug}/${reportID}`;

    // State
    const [fetching, setFetching] = useState(false);
    const [showFilters, setShowFilters] = useState(true);
    const [userMessage, setUserMessage] = useState({});

    // Selectors
    const accountProducts = useSelector((state) => selectorProducts(state, account.slug));
    const datasetPosts = useSelector((state) => selectorDataset(state, datasetPostsKey));
    const datasetSales = useSelector((state) => selectorDataset(state, datasetSalesKey));
    const filters = useSelector((state) => selectorFilters(state, filtersKey));

    // Hook: useIsMounted
    const isMounted = useIsMounted();

    // Hook: Get Account Products
    useLayoutEffect(() => {
        if (!accountProducts.length) {
            setFetching(true);
            account.getAllProducts().then(() => {
                isMounted() && setFetching(false);
            });
        }
    }, [account]);

    // Hook: Get videos dataset
    useLayoutEffect(() => {
        if (Object.keys(filters).length <= 0) return;

        const sanitizedFilters = {
            period: { ...filters.period },
            platforms: ['youtube'],
        };

        const socialMediaPostsQuery = createSocialMediaPostsQueryFromFilters(sanitizedFilters);
        const queryValidation = validateSocialMediaPostsQuery(socialMediaPostsQuery);

        if (queryValidation.valid) {
            const formData = createFormData(socialMediaPostsQuery);
            setFetching(true);
            setUserMessage({ className: 'pending', message: queryValidation.message });
            account.getSocialMediaPosts(formData).then((response) => {
                account.saveDataset(datasetPostsKey, response);
                isMounted() && setFetching(false);
                isMounted() && setUserMessage({});
            });
        } else {
            setUserMessage({ className: 'error', message: queryValidation.message });
        }
    }, [filters]);

    // Hook: Get sales dataset
    useLayoutEffect(() => {
        if (Object.keys(filters).length <= 0) return;

        const sanitizedFilters = {
            productIDs: filters.productIDs,
            categories: filters.categories,
            types: filters.types,
            period: { ...filters.period },
        };

        // Extend period to around video publish date
        const offsetDays = parseInt(filters.salesDays);
        sanitizedFilters.period.startTime = sanitizedFilters.period.startTime - offsetDays * 86400 * 1000;
        sanitizedFilters.period.endTime = sanitizedFilters.period.endTime + offsetDays * 86400 * 1000;

        const salesQuery = createSalesQueryFromFilters(sanitizedFilters, accountProducts);
        const queryValidation = validateSalesQuery(salesQuery);

        if (queryValidation.valid) {
            const formData = createFormData(salesQuery);
            setFetching(true);
            setUserMessage({ className: 'pending', message: queryValidation.message });
            account.getSales(formData).then((response) => {
                account.saveDataset(datasetSalesKey, response);
                isMounted() && setFetching(false);
                isMounted() && setUserMessage({});
            });
        } else {
            setUserMessage({ className: 'error', message: queryValidation.message });
        }
    }, [filters]);

    return (
        <div className={classnames(commonStyles.module, styles.module)}>
            {/* Header */}
            <div className='viewHeader'>
                <h3>YouTube to Sales Analytics</h3>
                <div className='headerControls'>
                    <p className='showFilters' onClick={() => setShowFilters(!showFilters)}>
                        <i className='fas fa-filter' /> Filters
                    </p>
                </div>
                <div className='fetchingStatus'>{fetching && <LoadingIcon />}</div>
                <div className='userMessage'>{userMessage.message && <p className={userMessage.className}>{userMessage.message}</p>}</div>
            </div>

            {/* Filters */}
            <DropdownPanel show={showFilters} hideButton={true}>
                <ReportFilters
                    account={account}
                    filtersKey={filtersKey}
                    options={{
                        products: accountProducts,
                    }}
                />
            </DropdownPanel>

            {/* Result */}
            {Object.keys(filters).length ? (
                <VideoList videos={datasetPosts} sales={datasetSales} analyticsDay={filters.analyticsDay} salesDays={filters.salesDays} />
            ) : (
                ''
            )}
        </div>
    );
};

ReportYouTubeSalesAnalytics.propTypes = {
    account: PropTypes.object.isRequired,
    reportID: PropTypes.string.isRequired,
};

export default ReportYouTubeSalesAnalytics;
