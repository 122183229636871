export const defaultPolicies = [
    {
        name: 'Can View Data',
        key: 'can-view-data',
    },
    {
        name: 'Can Import Data',
        key: 'can-import-data',
    },
    {
        name: 'Can Delete Data',
        key: 'can-delete-data',
    },
    {
        name: 'Can Export Data',
        key: 'can-export-data',
    },
    {
        name: 'Can Export API Data',
        key: 'can-export-api-data',
    },
    {
        name: 'Can Quick Sync Data',
        key: 'can-quick-sync-data',
    },
    {
        name: 'Can Edit Sync Config',
        key: 'can-edit-sync-config',
    },
    {
        name: 'Can Edit Account Settings',
        key: 'can-edit-account-settings',
    },
    {
        name: 'Can Edit Users',
        key: 'can-edit-users',
    },
    {
        name: 'Can Manage Account',
        key: 'can-manage-account',
    },
];
