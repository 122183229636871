import { createSlice } from '@reduxjs/toolkit';
import { actionResetStore } from '../actions';

const initialState = [];
const blankValue = [];

const slice = createSlice({
    name: 'reports',
    initialState: initialState,
    reducers: {
        fetchedReports: (state, action) => {
            const index = state.findIndex((object) => {
                return object.key === action.payload.key;
            });
            if (index === -1) {
                state.push({ key: action.payload.key, data: action.payload.data });
            } else {
                state[index] = { key: action.payload.key, data: action.payload.data };
            }
            return state;
        },
        updatedReport: (state, action) => {
            const index = state.findIndex((object) => {
                return object.key === action.payload.key;
            });
            if (index === -1) {
                state.push({ key: action.payload.key, data: action.payload.data });
            } else {
                const data = state[index].data;
                const reportIndex = data.findIndex((report) => {
                    return report.id === action.payload.data.id;
                });
                if (reportIndex === -1) {
                    data.push(action.payload.data);
                } else {
                    data[reportIndex] = action.payload.data;
                }
                state[index].data = data;
            }
            return state;
        },
        deletedReport: (state, action) => {
            const index = state.findIndex((object) => {
                return object.key === action.payload.key;
            });
            if (index !== -1) {
                const data = state[index].data;
                const reportIndex = data.findIndex((report) => {
                    return report.id === action.payload.data.id;
                });
                if (reportIndex !== -1) {
                    data.splice(reportIndex, 1);
                }
                state[index].data = data;
            }
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(actionResetStore, (state, action) => {
            return initialState;
        });
    },
});

// Actions
export const { fetchedReports, updatedReport, deletedReport } = slice.actions;

// Reducer
export default slice.reducer;

// Selectors
export const selectorReports = (state, key) => {
    const entry = state.reports.find((object) => {
        return object.key == key;
    });
    return entry ? entry.data : blankValue;
};

export const selectorReport = (state, key, id) => {
    const entry = state.reports.find((object) => {
        return object.key == key;
    });
    if (entry) {
        const report = entry.data.find((report) => {
            return report.id == id;
        });
        return report ? report : null;
    } else {
        return null;
    }
};
