import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import * as Filters from '@/components/metrics-account/filters';
import { reduceFilters, matchAgainstCachedFilters, initFilters, updateFilters } from '@/services/filters';
import { salesTypeOptions, analyticsDayOptions, salesDayOptions } from '@/components/metrics-account/reports/ReportYouTubeSalesAnalytics/constants';
import { clearTimezoneFromDate } from '@/util/format';
import { Select } from '@/components/ui/FormFields';

const ReportFilters = ({ account, filtersKey, options }) => {
    // Reducers
    const [filters, dispatchFilters] = useReducer(
        reduceFilters,
        matchAgainstCachedFilters(filtersKey, {
            productIDs: [],
            categories: {
                metaKey: 'categories',
                metaName: 'Categories',
                entryIdKey: 'id',
                entryNameKey: 'name',
                values: [],
            },
            types: [],
            analyticsDay: 1,
            salesDays: 1,
            period: {
                startTime: clearTimezoneFromDate(new Date(new Date().setHours(0, 0, 0, 0))).getTime(),
                endTime: clearTimezoneFromDate(new Date(new Date().setHours(0, 0, 0, 0))).getTime(),
                presetID: 'this-year',
                presetLabel: '',
            },
        }),
        initFilters
    );

    // On Filter Change
    const onFilterChange = (fieldID, value) => {
        dispatchFilters({ type: 'update', payload: { fieldID: fieldID, value: value } });
    };

    // On Apply Filters
    const onApplyFilters = () => {
        updateFilters(filtersKey, filters);
    };

    return (
        <div className='reportFilters'>
            <div>
                <h6>Video Filters</h6>
                <div className='reportFilterGroup'>
                    <Filters.FilterWrapper>
                        <>
                            <strong>Video Date Range:</strong> {filters.period.presetLabel}
                        </>
                        <Filters.DateRange dateRange={filters.period} onChange={(value) => onFilterChange('period', value)} />
                    </Filters.FilterWrapper>

                    <Filters.FilterWrapper>
                        <strong>Analytics Screenshot</strong>
                        <Select
                            field={{
                                options: analyticsDayOptions,
                                value: filters.analyticsDay,
                                onChange: (name, value) => onFilterChange('analyticsDay', value),
                                required: true,
                            }}
                        />
                    </Filters.FilterWrapper>
                </div>
            </div>

            <div>
                <h6>Sales Filters</h6>
                <div className='reportFilterGroup'>
                    <Filters.FilterWrapper>
                        <strong>Products</strong>
                        <Filters.ProductSelect
                            products={options.products}
                            selectedProductIDs={filters.productIDs}
                            onChange={(value) => onFilterChange('productIDs', value)}
                        />
                    </Filters.FilterWrapper>

                    <Filters.FilterWrapper>
                        <strong>Categories</strong>
                        <Filters.MetaSelect
                            products={options.products}
                            metaField={filters.categories}
                            onChange={(value) => onFilterChange('categories', value)}
                        />
                    </Filters.FilterWrapper>

                    <Filters.FilterWrapper>
                        <strong>Sales Type</strong>
                        <Filters.SalesTypeSelect
                            salesTypeOptions={salesTypeOptions}
                            selectedTypeValues={filters.types}
                            onChange={(value) => onFilterChange('types', value)}
                        />
                    </Filters.FilterWrapper>

                    <Filters.FilterWrapper>
                        <strong>Sales Number Screenshot</strong>
                        <Select
                            field={{
                                options: salesDayOptions,
                                value: filters.salesDays,
                                onChange: (name, value) => onFilterChange('salesDays', value),
                                required: true,
                            }}
                        />
                    </Filters.FilterWrapper>

                    <Filters.FilterWrapper>
                        <button className='btnApplyFilters' onClick={onApplyFilters}>
                            Generate
                        </button>
                    </Filters.FilterWrapper>
                </div>
            </div>
        </div>
    );
};

ReportFilters.propTypes = {
    account: PropTypes.object.isRequired,
    filtersKey: PropTypes.string.isRequired,
    options: PropTypes.object,
};

export default ReportFilters;
