import React, {useEffect, useState} from "react";
import {useIsMounted} from "@/util/hooks";
import PropTypes from 'prop-types';

const SyncStatus = ( {account} ) => {

    // State
    const [syncStatus, setSyncStatus] = useState({});

    // Hooks
    const isMounted = useIsMounted();

    useEffect( () => {

        let interval;
        const intervalFunction = () => {
            account.getSyncStatus().then( (data) => {
                isMounted() && setSyncStatus( data );
                if( !data.status || data.status !== "syncing" ){
                    clearInterval(interval);
                    account.refreshAccount().then();
                }
            });
        };

        if( account.syncing ){
            interval = setInterval( intervalFunction, 2000 );
            intervalFunction();
        }

        return () => {
            clearInterval(interval);
        }

    }, [account] )

    if( syncStatus ){
        switch( syncStatus.status ){
            case "syncing":
                return(
                    <p className="syncing">
                        <span className="icon"><i className="fas fa-sync fa-spin"/></span>
                        {syncStatus.message}
                    </p>
                )
            case "success":
                return(
                    <p className="success">{syncStatus.message}</p>
                )
            case "error":
                return(
                    <p className="error">{syncStatus.message}</p>
                )
            default:
                return ""
        }
    }else{
        return "";
    }

}

SyncStatus.propTypes = {
    account: PropTypes.object.isRequired
}

export default SyncStatus;