import React, { useLayoutEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './ReportPostModeration.module.scss';
import commonStyles from '@/components/metrics-account/reports/CommonStyles.module.scss';
import { createSocialMediaPostsQueryFromFilters, getKeywordsFromQueriesSetting, validateSocialMediaPostsQuery } from '../_socialMediaPosts';
import { createFormData } from '../_common';
import { useSelector } from 'react-redux';
import { selectorDataset } from '@/store/slices/datasets';
import { selectorFilters } from '@/store/slices/filters';
import { useIsMounted } from '@/util/hooks';
import { DropdownPanel, LoadingIcon } from '@/components/ui';
import classnames from 'classnames';
import PostsList from '@/components/metrics-account/reports/ReportPostModeration/partials/PostsList';
import ReportFilters from './partials/ReportFilters';

const ReportPostModeration = ({ account, reportID }) => {
    const datasetPostsKey = `${account.slug}/${reportID}/posts`;
    const filtersKey = `${account.slug}/${reportID}`;

    // State
    const [fetching, setFetching] = useState(false);
    const [showFilters, setShowFilters] = useState(true);
    const [userMessage, setUserMessage] = useState({});

    // Selectors
    const datasetPosts = useSelector((state) => selectorDataset(state, datasetPostsKey));
    const filters = useSelector((state) => selectorFilters(state, filtersKey));

    // Hooks
    const isMounted = useIsMounted();

    // Const
    const accountQueries = useMemo(() => account.getSavedQueries(), [account]);

    // Hook: Get Posts Dataset
    useLayoutEffect(() => {
        if (Object.keys(filters).length <= 0) return;

        const sanitizedFilters = {
            platforms: filters.platforms,
            search: filters.search,
            keywords: filters.keywords,
            period: filters.period,
        };

        const socialMediaPostsQuery = createSocialMediaPostsQueryFromFilters(sanitizedFilters);
        const queryValidation = validateSocialMediaPostsQuery(socialMediaPostsQuery);

        if (queryValidation.valid) {
            const formData = createFormData(socialMediaPostsQuery);
            setFetching(true);
            setUserMessage({ className: 'pending', message: queryValidation.message });
            account.getSocialMediaPosts(formData).then((response) => {
                account.saveDataset(datasetPostsKey, response);
                isMounted() && setFetching(false);
                isMounted() && setUserMessage({});
            });
        } else {
            setUserMessage({ className: 'error', message: queryValidation.message });
        }
    }, [filters]);

    // Memo: Keyword Options
    const keywordOptions = useMemo(() => {
        const keywords = getKeywordsFromQueriesSetting(accountQueries);
        return keywords.map((keyword) => {
            return {
                value: keyword,
                name: keyword,
            };
        });
    }, [accountQueries]);

    // Memo: Selected Keywords
    const selectedKeywords = useMemo(() => {
        const keywords = filters.keywords ? [...filters.keywords] : [];
        if (filters.search) {
            keywords.push(filters.search);
        }
        return keywords;
    }, [filters]);

    return (
        <div className={classnames(commonStyles.module, styles.module)}>
            {/* Header */}
            <div className='viewHeader'>
                <h3>Moderate Social Media Posts</h3>
                <div className='headerControls'>
                    <p className='showFilters' onClick={() => setShowFilters(!showFilters)}>
                        <i className='fas fa-filter' /> Filters
                    </p>
                </div>
                <div className='fetchingStatus'>{fetching && <LoadingIcon />}</div>
                <div className='userMessage'>{userMessage.message && <p className={userMessage.className}>{userMessage.message}</p>}</div>
            </div>

            {/* Filters */}
            <DropdownPanel show={showFilters} hideButton={true}>
                <ReportFilters
                    account={account}
                    filtersKey={filtersKey}
                    options={{
                        keywordOptions: keywordOptions,
                    }}
                />
            </DropdownPanel>

            {/* PostsList */}
            <PostsList account={account} keywords={selectedKeywords} posts={datasetPosts} />
        </div>
    );
};

ReportPostModeration.propTypes = {
    account: PropTypes.object.isRequired,
    reportID: PropTypes.string.isRequired,
};

export default ReportPostModeration;
