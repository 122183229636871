import {getBestTimeTickFormat} from "./_timeFunctions";

/** --------------------------------------------------------------
 Report Functions
 -------------------------------------------------------------- */

export const generateKeywordsSummary = (posts, selectedKeywords) =>{

    // Default summary
    const postsSummary = {
        totalPosts: 0,
        keywords: {},
    }

    // Add keyword objects
    for( const keyword of selectedKeywords ){
        postsSummary.keywords[keyword.toLowerCase()] = {
            total: 0
        }
    }

    // Loop through posts
    for( const post of posts ){

        for( const keyword of selectedKeywords ) {

            if (post.title && post.title.toLowerCase().includes(keyword.toLowerCase())) {
                postsSummary.keywords[keyword].total++;
            } else if (post.content && post.content.toLowerCase().includes(keyword.toLowerCase())) {
                postsSummary.keywords[keyword].total++;
            }
        }

        postsSummary.totalPosts++;
    }

    return postsSummary;
}

export const generateKeywordChartEntries = (posts, startTime, endTime, minTicks, offset = 0, selectedKeywords) =>{

    const chartEntries = [];

    // Generate time ticks
    const timeTickFormat = getBestTimeTickFormat(startTime, endTime, minTicks);
    const timeTicks = timeTickFormat.getTicks( startTime, endTime);

    // Early return if no valid time ticks were generated
    if( !timeTicks ){ return chartEntries }

    // Loop through time ticks (ignore last one)
    for( let i=0; i<timeTicks.length-1+offset; i++ ){

        // Get posts that are within the time range
        let periodPosts = [];

        if( i >= offset ) {
            periodPosts = posts.filter((post) => {
                if (post.datetime) {
                    const postTime = new Date(post.datetime).getTime();
                    return postTime >= timeTicks[i-offset] && postTime < timeTicks[i+1-offset]
                } else {
                    return false;
                }
            });
        }

        // Create new chart data point
        const chartEntry = {
            datetime: timeTickFormat.formatDate( startTime, endTime, new Date(timeTicks[i]) ),
            posts: periodPosts.length
        }

        // Create empty object for keywords
        selectedKeywords.forEach( (keyword) => {
            chartEntry[keyword] = 0;
        });

        // Process period posts
        for( const post of periodPosts ){

            for( const keyword of selectedKeywords ){

                if( post.title && post.title.toLowerCase().includes(keyword.toLowerCase()) ){
                    chartEntry[keyword]++;
                }else if( post.content && post.content.toLowerCase().includes(keyword.toLowerCase()) ){
                    chartEntry[keyword]++;
                }
            }
        }

        chartEntries.push( chartEntry );
    }

    return chartEntries;
}

/** --------------------------------------------------------------
 Filter Functions
 -------------------------------------------------------------- */

export const validateSocialMediaPostsQuery = (queryObj) => {
    if( !queryObj.platforms || queryObj.platforms.length < 1 ){
        return {
            valid: false,
            message: "Please select at least one platform."
        };
    }
    return {
        valid: true,
        message: "Generating report, please wait."
    }
}

export const createSocialMediaPostsQueryFromFilters = (filters) => {

    let platforms = [];
    let search = "";
    let keywords = [];
    let startDate = new Date().toISOString();
    let endDate = new Date().toISOString();

    // Platforms
    if( filters.hasOwnProperty("platforms") ){
        platforms = filters['platforms'];
    }

    // Search String
    if( filters.hasOwnProperty("search") ){
        search = filters['search'];
    }

    // Keywords
    if( filters.hasOwnProperty("keywords") ){
        keywords = filters['keywords'];
    }

    // Period
    if( filters.hasOwnProperty("period") ){
        startDate = filterStartDate(filters.period.startTime).toISOString();
    }

    if( filters.hasOwnProperty("period") ){
        endDate = filterEndDate(filters.period.endTime).toISOString();
    }

    return {
        platforms: platforms,
        s: search,
        keywords: keywords,
        startDate: startDate,
        endDate: endDate,
    }

}

export const filterStartDate = (startTime) => {
    return new Date( startTime );
}

export const filterEndDate = (endTime) => {
    return new Date( endTime + (1000 * 60 * 60 * 24) );
}

/** --------------------------------------------------------------
 Other Functions
 -------------------------------------------------------------- */

/** Breaks down the Sync Config object and retrieves all the keywords in one array */
export const getKeywordsFromQueriesSetting = (queries) => {

    const returnArr = [];

    // Loop through queries
    for( let i=0; i<queries.length; i++ ) {
        const query = queries[i];
        const keywords = query.hasOwnProperty("keywords") ? query.keywords : [];

        for( let j=0; j<keywords.length; j++ ) {
            const keyword = keywords[j];
            if( !returnArr.includes(keyword) ){
                returnArr.push(keyword);
            }
        }
    }

    return returnArr;
}


/** Wrap string keywords in span elements */
export const highlightKeywords = ( string = '', keywords ) => {

    let returnString = string;

    for( let i=0; i<keywords.length; i++ ){
        const keyword = keywords[i];
        if (keyword.length < 2){ continue; }
        let reg = new RegExp('('+keyword+')', 'gi');
        returnString = returnString.replace(reg, '<span class="highlight">$1</span>');
    }

    return returnString;
}

