export const userLoginFields = [
    {
        "name": "identifier",
        "label": "Email",
        "type": "email",
        "required": true,
        "wrapperClass": "flex-span-12",
    },
    {
        "name": "password",
        "label": "Password",
        "type": "password",
        "required": true,
        "wrapperClass": "flex-span-12",
        "enablePasswordShow": true
    },
    {
        "name": "submit",
        "value": "Log In",
        "type": "submit"
    }
]

export const userForgetPasswordFields = [
    {
        "name": "identifier",
        "label": "Email",
        "type": "email",
        "required": true,
        "wrapperClass": "flex-span-12"
    },
    {
        "name": "submit",
        "value": "Confirm",
        "type": "submit"
    }
]

export const userResetPasswordFields = [
    {
        "name": "password",
        "label": "Password",
        "type": "password",
        "required": true,
        "wrapperClass": "flex-span-12",
        "enablePasswordShow": true,
    },
    {
        "name": "passwordConfirmation",
        "label": "Confirm Password",
        "type": "password",
        "required": true,
        "wrapperClass": "flex-span-12",
        "enablePasswordShow": true,
    },
    {
        "name": "submit",
        "value": "Reset Password",
        "type": "submit"
    }
]