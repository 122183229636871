export const salesTypeOptions = [
    {
        value: "sale",
        name: "Sales",
    },
    {
        value: "renewal",
        name: "Renewals",
    },
    {
        value: "refunded",
        name: "Refunds",
    },
    {
        value: "cancelled",
        name: "Cancelled",
    },
    {
        value: "failed",
        name: "Failed",
    },
]

export const reportOptions = {

    salesTypes: [
        {
            key: "sale",
            label: "Sales",
            revenue: "add",
            fill: "#1b6b47"
        },
        {
            key: "renewal",
            label: "Renewals",
            revenue: "add",
            fill: "#2db377"
        },
        {
            key: "refunded",
            label: "Refunds",
            revenue: "ignore",
            fill: "#ffcc7d"
        },
        {
            key: "cancelled",
            label: "Cancelled",
            revenue: "ignore",
            fill: "#666666"
        },
        {
            key: "failed",
            label: "Failed",
            revenue: "ignore",
            fill: "#d67575"
        },
    ]
}