import React, {useEffect, useRef} from "react";
import {showFadeIn, hideFadeOut} from "@/animations";
import PropTypes from "prop-types";

const MenuDropdown = ({refParent, show = false, hooks = {}, children}) => {

    // Refs
    const refDropdown = useRef(null);

    // Add event listeners
    useEffect(() => {
        document.addEventListener('mousedown', onDocumentClick);
        return () => {
            document.removeEventListener('mousedown', onDocumentClick);
        }
    });

    // Animation
    useEffect( () => {
        show ? showFadeIn(refDropdown.current) : hideFadeOut(refDropdown.current);
    }, [show] )

    // On document click
    const onDocumentClick = (e) => {
        if (show && !refParent.current.contains(e.target) && !refDropdown.current.contains(e.target)) {
            hooks.onClickOutside && hooks.onClickOutside();
        }
    }

    return (
        <ul ref={refDropdown} style={{display:"none"}} className="menuList menuDropdown">
            {children}
        </ul>
    );
};

MenuDropdown.propTypes = {
    refParent: PropTypes.object.isRequired,
    show: PropTypes.bool,
    hooks: PropTypes.shape({
        onClickOutside: PropTypes.func
    }),
    children: PropTypes.node
}

export default MenuDropdown;
