import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Status = ({ report }) => {
    const { status, status_message } = report;

    if (status !== 'completed') {
        return <p className={classNames('statusMessage', status)}>{status_message}</p>;
    } else {
        return null;
    }
};

Status.propTypes = {
    report: PropTypes.object.isRequired,
};

export default Status;
