import React from 'react';
import FormHandler from '@/components/ui/FormHandler/FormHandler';
import PageWrapper from '../PageWrapper';
import styles from './Test.module.scss';

const FormHandlerTestPage = () => {
    const formFields = [
        {
            type: 'text',
            name: 'testText',
            label: 'Text Input',
        },
        {
            type: 'password',
            name: 'testPassword',
            label: 'Password Input',
            enablePasswordShow: true,
            enablePasswordGenerator: true,
        },
        {
            type: 'email',
            name: 'testEmail',
            label: 'Email Input',
        },
        {
            type: 'number',
            name: 'testNumber',
            label: 'Number Input',
        },
        {
            type: 'url',
            name: 'testURL',
            label: 'URL Input',
        },
        {
            type: 'boolean',
            name: 'testBoolean',
            label: 'Boolean Input',
        },
        {
            type: 'file',
            name: 'testFile',
            label: 'File Input',
        },
        {
            type: 'submit',
            name: 'submit',
            value: 'Test Submit',
        },
    ];

    // On form input change
    const onChange = (name, value) => {
        console.log('onChange', name, value);
    };

    // On form submit
    const onSubmit = (data) => {
        console.log('onSubmit', data);
    };

    return (
        <PageWrapper pageClass={styles.module}>
            <h3>Form Handler Test Page</h3>
            <FormHandler
                fields={formFields}
                formOptions={{
                    formClass: 'formStyleA',
                }}
                callbacks={{
                    onChange: onChange,
                    onSubmit: onSubmit,
                }}
            />
        </PageWrapper>
    );
};

export default FormHandlerTestPage;
