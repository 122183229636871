import React from 'react';
import classnames from 'classnames';
import MenuTopLeft from '../menus/MenuTopLeft';
import MenuTopRight from '../menus/MenuTopRight';
import styles from './NavTop.module.scss';

const NavTop = () => {
    return (
        <div className={classnames('topMenu', styles.module)}>
            <div className='menuTopLeft'>
                <MenuTopLeft />
            </div>
            <div className='menuTopRight'>
                <MenuTopRight />
            </div>
        </div>
    );
};

export default NavTop;
