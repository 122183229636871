import store from '@/store';
import { appReady, appPathChanged } from '@/store/slices/app';
import { getAllEntries } from './strapi/metrics-account/account';
import { validateCurrentUser } from '@/services/strapi/auth';

export const initApp = async () => {
    // Auth
    const auth = await validateCurrentUser();

    // Collections
    if( auth ) {
        await loadAppResources();
    }

    // Init done, App ready
    store.dispatch(appReady());
};

export const loadAppResources = async () => {
    // Collections
    await getAllEntries();
}

export const setCurrentAppPath = (path) => {
    store.dispatch(appPathChanged(path));
};
