import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DropdownButton } from '@/components/ui';
import { userHasAnyPermission } from '@/services/strapi/permissions';
import { selectorCurrentUserPermissions } from '@/store/slices/auth';

const DropdownMenu = ({ items, label, permissionsKey = '' }) => {
    // Selectors
    const user = useSelector((state) => state.auth.user);
    const userPermissions = useSelector((state) => selectorCurrentUserPermissions(state, permissionsKey));

    const menuItems = useMemo(() => {
        let menuItems = [...items];

        // Filter items by permissions
        if (permissionsKey) {
            menuItems = items.filter((item) => {
                if (item.hasOwnProperty('permissions') && item.permissions.length > 0) {
                    return userHasAnyPermission(user, userPermissions, item.permissions);
                } else {
                    return true;
                }
            });
        }

        // Generate menu items
        return menuItems.map((item, key) => {
            const content = (
                <>
                    {item.icon && (
                        <span className='icon'>
                            <i className={item.icon} />
                        </span>
                    )}
                    {item.label}
                </>
            );

            if (item.link) {
                return (
                    <Link key={key} to={item.link}>
                        {content}
                    </Link>
                );
            } else {
                return (
                    <div key={key} onClick={item.action && item.action}>
                        {content}
                    </div>
                );
            }
        });
    }, [items, user, userPermissions, permissionsKey]);

    if (menuItems.length < 1) {
        return '';
    }

    return (
        <DropdownButton label={label} className={'dropdownSelect'}>
            {menuItems}
        </DropdownButton>
    );
};

DropdownMenu.propTypes = {
    items: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired,
    permissionsKey: PropTypes.string,
};

export default DropdownMenu;
