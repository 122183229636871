import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectorReport } from '@/store/slices/reports';
import { selectorReportItems } from '@/store/slices/reportItems';
import ReportItem from '../../partials/ReportItem';
import Pagination from '../../partials/Pagination';
import ExportChannel from './ExportChannel';
import { LoadingIcon } from '@/components/ui';

const ResultsChannel = ({ tool }) => {
    // Router
    const { reportId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    // Pagination
    const currentPage = searchParams.get('page') ? parseInt(searchParams.get('page')) : 1;
    const pageSize = searchParams.get('pageSize') ? parseInt(searchParams.get('pageSize')) : 10;

    // State
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);

    // Selectors
    const report = useSelector((state) => selectorReport(state, tool.uid, reportId));
    const reportItems = useSelector((state) => selectorReportItems(state, reportId));

    // Effect: Set curreny page
    const setCurrentPage = (val) => {
        searchParams.set('page', val);
        setSearchParams(searchParams);
    };

    // Effect: Set page size
    const setPageSize = (val) => {
        searchParams.set('pageSize', val);
        setSearchParams(searchParams);
    };

    // Effect: Get report items
    useLayoutEffect(() => {
        if (!report) return;
        setLoading(true);
        const pagination = { page: currentPage, pageSize: pageSize };
        tool.getReportItems(report.id, pagination).then((res) => {
            if (res.pagination) {
                setTotal(res.pagination.total);
            }
            setLoading(false);
        });
    }, [report, currentPage, pageSize]);

    // Bail if no report id found
    if (!report) {
        return null;
    }

    const { results } = report;
    const startingIndex = (currentPage - 1) * pageSize + 1;

    // Stats
    const reportStats = results && results.stats ? results.stats : null;
    const itemsTotal = reportStats ? reportStats.total : 0;
    const itemsDone = reportStats ? reportStats.done : 0;
    const itemsRetry = reportStats ? reportStats.retry : 0;
    const itemsError = reportStats ? reportStats.error : 0;
    const itemsShorts = reportStats ? reportStats.shorts : 0;

    // Results data
    const channel = results && results.channel ? results.channel : null;
    const channelName = channel ? channel.name : 'Fetching channel...';
    const channelUrl = channel ? channel.url : '#';

    const ReportItems = () => {
        if (!reportItems || reportItems.length === 0) {
            return <p className='noResults'>No results found.</p>;
        }
        return reportItems.map((item, index) => {
            const reportItemNo = startingIndex + index;
            return <ReportItem key={item.id} item={item} index={reportItemNo} />;
        });
    };

    return (
        <>
            <ExportChannel tool={tool} startingIndex={startingIndex} />
            <div className='results'>
                <div className='resultsHeader'>
                    <h4 className='heading'>
                        <a href={channelUrl} target='_blank'>
                            {channelName}
                        </a>
                    </h4>
                    <p className='loading'>{loading && <LoadingIcon />}</p>
                    <p className='info'>
                        <span>Total Vids: {itemsTotal}</span>|<span>Done: {itemsDone}</span>|<span>Retry: {itemsRetry}</span>|
                        <span>Error: {itemsError}</span>|<span>Shorts: {itemsShorts}</span>
                    </p>
                    <Pagination page={currentPage} limit={pageSize} total={total} setPage={setCurrentPage} setLimit={setPageSize} />
                </div>

                <ReportItems />
                <div className='resultsFooter'>
                    <p className='loading'>{loading && <LoadingIcon />}</p>
                    <Pagination page={currentPage} limit={pageSize} total={total} setPage={setCurrentPage} setLimit={setPageSize} />
                </div>
            </div>
        </>
    );
};

ResultsChannel.propTypes = {
    tool: PropTypes.object.isRequired,
};

export default ResultsChannel;
