export const toolUid = 'event-logger';

export const policies = [
    {
        name: 'Can View Logs',
        key: 'can-view-logs',
    },
    {
        name: 'Can Manage Logs',
        key: 'can-manage-logs',
    },
    {
        name: 'Can Edit Users',
        key: 'can-edit-users',
    },
];
