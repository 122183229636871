import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import MenuList from '@/components/nav/partials/MenuList';
import * as endpoints from '@/router/_endpoints';
import { filterCompileMenuItems } from '@/components/nav/_navFunctions';

const MenuSidebar = () => {
    // Store
    const metricsAccounts = useSelector((state) => state.accounts);

    // Menu model
    const menuItems = [
        {
            id: 'dashboard',
            label: 'Dashboard',
            link: endpoints.DASHBOARD,
            icon: 'fas fa-tachometer-alt',
        },
        {
            id: 'metricsAccounts',
            label: 'Metrics Accounts',
            icon: 'fas fa-chart-area',
            showDropdown: true,
            hideDropdownOnClickOutside: false,
            submenu: [
                {
                    id: (entry) => entry.id,
                    label: (entry) => entry.accountName,
                    link: (entry) => `/metrics-account/${entry.slug}/`,
                    empty: () => 'No Accounts Found',
                    filter: 'arrayOfObjects',
                    arrayID: 'metrics-accounts',
                },
            ],
        },
        {
            id: 'tools',
            label: 'Tools',
            icon: 'fa-solid fa-screwdriver-wrench',
            showDropdown: true,
            hideDropdownOnClickOutside: false,
            submenu: [
                {
                    id: 'youtube-perforamnce-tool',
                    label: 'Youtube Performance',
                    link: endpoints.YOUTUBE_PERFORMANCE_TOOL,
                },
                {
                    id: 'event-logger-tool',
                    label: 'Event Logger',
                    link: endpoints.EVENT_LOGGER,
                },
            ],
        },
    ];

    const filteredItems = useMemo(() => filterCompileMenuItems(menuItems, { 'metrics-accounts': metricsAccounts }), [metricsAccounts]);

    const hooks = {
        beforeItemLabel: (item) =>
            item.icon && (
                <span className='icon'>
                    <i className={item.icon} />
                </span>
            ),
        afterItemLabel: (item) =>
            item.submenu && (
                <span className='dropdownCaret'>
                    <i className='fas fa-caret-left' />
                </span>
            ),
        filterBeforeItemsRender: (items) =>
            items.map((item) => {
                if (item.isCurrent || item.hasCurrent) {
                    return { ...item, showDropdown: true };
                } else {
                    return item;
                }
            }),
    };

    return <MenuList menuItems={filteredItems} hooks={hooks} />;
};

export default MenuSidebar;
