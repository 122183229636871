import {getTypesAsSelectOptions, getIntegrationIDs} from "@/components/metrics-account/accountTypes";
import {getIntegrationFields} from "@/components/metrics-account/integrationFields";

export const createFormFields = [
    {
        name: "accountName",
        label: "Account Name",
        type: "text",
        required: true,
        wrapperClass: "flex-span-12"
    },
    {
        name: "accountType",
        label: "Type",
        type: "select",
        options: getTypesAsSelectOptions()
    },
    {
        type: "dynamicFieldset",
        populate: (accountTypeID) => {
            const integrationIDs = getIntegrationIDs( accountTypeID );
            const integrationFields = [];
            integrationIDs.forEach( (integrationID) => {
                const fields = getIntegrationFields( integrationID );
                integrationFields.push(...fields);
            })
            return integrationFields;
        }
    },
    {
        name: "submit",
        value: "Create Metrics Account",
        type: "submit"
    }
]