import React from "react";
import styles from "./LoadingIcon.module.scss"
import classnames from "classnames";
import PropTypes from "prop-types";

const LoadingIcon = ( {children} ) => {

    return(
        <span className={classnames("loadingIcon", styles.module)}>
            <i className="fas fa-circle-notch fa-spin"/>
            {children}
        </span>
    )
}

LoadingIcon.propTypes = {
    text: PropTypes.string
}

export default LoadingIcon;