import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { selectorReport } from '@/store/slices/reports';
import { useSelector } from 'react-redux';
import { selectorReportItems } from '@/store/slices/reportItems';
import PDFDocument from '../../partials/export/PDFDocument';
import ExportReportPDF from '../../partials/export/ExportReportPDF';
import ExportReportCSV from '../../partials/export/ExportReportCSV';

const ExportKeyword = ({ tool, startingIndex = 1 }) => {
    // Router
    const { reportId } = useParams();

    // Selectors
    const report = useSelector((state) => selectorReport(state, tool.uid, reportId));
    const reportItems = useSelector((state) => selectorReportItems(state, reportId));

    const createExportFilename = () => {
        if (!report) return '';
        const { parameters, results } = report;
        const { videosNumber, keyword } = parameters;
        const keywordString = keyword ? keyword.replace(/\s/g, '_') : '';
        return `keyword_search_${keywordString}_${videosNumber}_${report.last_run}`;
    };

    const createPDFHeading = () => {
        if (!report) return '';
        const { parameters, results } = report;
        const { videosNumber } = parameters;
        const keywordString = parameters.keyword;
        return `Keyword Search | "${keywordString}" | ${videosNumber} Videos`;
    };

    // Momo: Create PDF Document
    const pdfDocument = useMemo(() => {
        if (!report || !reportItems) return null;
        return <PDFDocument report={report} reportItems={reportItems} heading={createPDFHeading()} startingIndex={startingIndex} />;
    }, [report, reportItems, startingIndex]);

    // Bail if no report or report items found
    if (!report || !reportItems) return null;

    return (
        <div className='exportOptions'>
            <ExportReportCSV
                buttonTitle={`Export ${reportItems.length} Videos (CSV)`}
                reportItems={reportItems}
                filename={createExportFilename()}
                startingIndex={startingIndex}
            />
            <ExportReportPDF buttonTitle={`Export ${reportItems.length} Videos (PDF)`} document={pdfDocument} filename={createExportFilename()} />
        </div>
    );
};

ExportKeyword.propTypes = {
    tool: PropTypes.object.isRequired,
    startingIndex: PropTypes.number,
};

export default ExportKeyword;
