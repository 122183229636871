import React, { useState, useEffect } from 'react';
import { getUserRoles } from '@/services/strapi/permissions';
import UserCreateForm from '@/components/forms/users/UserCreateForm';
import UserTable from '@/components/screens/settings/ScreenManageAppUsers/partials/UserTable';
import AuthRedirect from '@/router/AuthRedirect';
import styles from './ScreenManageAppUsers.module.scss';

const ScreenManageAppUsers = () => {
    // State
    const [roles, setRoles] = useState([]);

    // Get Roles
    useEffect(() => {
        getUserRoles().then((roles) => {
            setRoles(roles);
        });
    }, []);

    return (
        <>
            <AuthRedirect allowableRolesTypes={['admin']} />
            <div className={styles.module}>
                <h2 className='viewHeading'>Manage App Users</h2>
                <h5>Create New User</h5>
                <UserCreateForm roles={roles} />
                <h5>Manage Users</h5>
                <UserTable roles={roles} />
            </div>
        </>
    );
};

export default ScreenManageAppUsers;
