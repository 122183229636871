import React from 'react';
import { defaultPolicies } from '@/components/metrics-account/classes/defaultPolicies';
import { SyncStatus } from '@/components/metrics-account/partials';
import { filterFormData } from '@/services/strapi/dataProcessing';
import * as sync from '@/services/strapi/metrics-account/sync';
import * as users from '@/services/strapi/metrics-account/users';
import * as permissions from '@/services/strapi/metrics-account/permissions';
import * as account from '@/services/strapi/metrics-account/account';
import * as dataImports from '@/services/strapi/metrics-account/data-imports';
import * as dataExports from '@/services/strapi/metrics-account/data-exports';
import * as settings from '@/services/strapi/metrics-account/settings';
import * as integrations from '@/services/strapi/metrics-account/integrations';
import * as products from '@/services/strapi/metrics-account/products';
import * as sales from '@/services/strapi/metrics-account/sales';
import * as socialMediaPosts from '@/services/strapi/metrics-account/social-media-posts';
import * as datasets from '@/services/strapi/datasets';

class Account {
    constructor(account) {
        this.id = account.id;
        this.slug = account.slug;
        this.accountName = account.accountName;
        this.settings = account.settings;
        this.syncing = account.syncing;
    }

    refreshAccount = async () => {
        return await account.getOneEntry(this.id);
    };

    /** Render */
    getStatusArea = () => {
        return <SyncStatus account={this} />;
    };

    getMenuArea = () => '';

    getBodyArea = () => '';

    /** Constants */
    getRelatedCollectionTypes = () => [];

    getPolicies = () => {
        return defaultPolicies;
    };

    /** Account Settings */
    getAccountSettings = () => {
        return this.settings;
    };

    setAccountSettings = async (key, data) => {
        const formData = new FormData();
        formData.append('data', JSON.stringify(data));
        return await settings.setSetting(this.id, key, formData);
    };

    /** Sync */
    startDataSync = async () => {
        return await sync.startQuickDataSync(this.id);
    };

    startCustomDataSync = async (fields) => {
        const formData = new FormData();
        const { data, files } = filterFormData(fields);
        formData.append('data', JSON.stringify(data));
        return await sync.startCustomDataSync(this.id, formData);
    };

    getSyncStatus = async () => {
        return await sync.getSyncStatus(this.id);
    };

    getSyncConfig = async () => {
        return await sync.getSyncConfig(this.id);
    };

    setDefaultSync = async (fields) => {
        const formData = new FormData();
        const { data, files } = filterFormData(fields);
        formData.append('data', JSON.stringify(data));
        return await sync.setDefaultSync(this.id, formData);
    };

    getSyncCronJobs = async () => {
        return await sync.getSyncCronJobs(this.id);
    };

    addSyncCronJob = async (fields) => {
        const formData = new FormData();
        const { data, files } = filterFormData(fields);
        formData.append('data', JSON.stringify(data));
        return await sync.addSyncCronJob(this.id, formData);
    };

    deleteSyncCronJob = async (key) => {
        const formData = new FormData();
        formData.append('frequency', key);
        return await sync.removeSyncCronJob(this.id, formData);
    };

    /** Users */
    getUsers = async () => {
        return await users.getAccountUsers(this.id);
    };

    addUser = async (userID) => {
        const formData = new FormData();
        formData.append('userID', userID);
        return await users.addAccountUser(this.id, formData);
    };

    removeUser = async (userID) => {
        const formData = new FormData();
        formData.append('userID', userID);
        return await users.removeAccountUser(this.id, formData);
    };

    setUserPermissions = async (userID, permissionsObject) => {
        const formData = new FormData();
        formData.append('userID', userID);
        formData.append('permissions', JSON.stringify(permissionsObject));
        return await permissions.updateAccountUserPermissions(this.id, formData);
    };

    getCurrentUserPermissions = async () => {
        return await permissions.getCurrentUserPermissions(this.id, this.slug);
    };

    getAllUsersPermissions = async () => {
        return await permissions.getAllAccountUserPermissions(this.id);
    };

    /** Integrations */
    getIntegrationComponents = async () => {
        return await integrations.getIntegrationComponents(this.id);
    };

    getIntegrationAuthUrl = async (componentID, params = {}) => {
        return await integrations.getIntegrationAuthUrl(this.id, componentID, params);
    };

    setIntegrationAccessTokens = async (componentID, code, params = {}) => {
        const formData = new FormData();
        formData.append('code', code);
        return await integrations.setAccessTokens(this.id, componentID, formData, params);
    };

    /** Collection Types */
    getAllProducts = async () => {
        return await products.getAllAccountProducts(this);
    };

    getSales = async (formData, query) => {
        return await sales.getAccountSales(this, formData, query);
    };

    getSocialMediaPosts = async (formData, query) => {
        return await socialMediaPosts.getAccountSocialMediaPosts(this, formData, query);
    };

    /** Datasets */
    saveDataset = (key, dataset) => {
        return datasets.storeDataset(key, dataset);
    };

    /** Data Import */
    getDataImportSchema = async (dataType) => {
        const formData = new FormData();
        formData.append('dataType', dataType);
        return await dataImports.getDataImportSchema(this.id, formData);
    };

    getDataImportEntries = async () => {
        return await dataImports.getDataImportEntries(this.id);
    };

    startDataImport = async (dataImportID) => {
        return await dataImports.startDataImport(this.id, dataImportID);
    };

    createDataImport = async (data, file) => {
        const formData = new FormData();
        formData.append('data', JSON.stringify(data));
        formData.append('csvFile', file, file.name);
        return await dataImports.createDataImport(this.id, formData);
    };

    deleteDataImport = async (dataImportID) => {
        return await dataImports.deleteDataImport(this.id, dataImportID);
    };

    /** Data Export */
    getCollectionTypeExportData = async (data) => {
        const formData = new FormData();
        formData.append('data', JSON.stringify(data));
        return await dataExports.getCollectionTypeExportData(this.id, formData);
    };

    getApiExportData = async (data) => {
        const formData = new FormData();
        formData.append('data', JSON.stringify(data));
        return await dataExports.getApiExportData(this.id, formData);
    };
}

export default Account;
