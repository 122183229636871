const now = new Date();
const date = now.getDate();
const month = now.getMonth();
const quarter = Math.floor( (now.getMonth()) / 3 );
const fullYear = now.getFullYear();

export const datePresets = [
    {
        id: "last-7-days",
        label: "Last 7 Days",
        getStartDate: (offset = 0) => new Date( fullYear, month, date - 6 + (offset * 7) ),
        getEndDate: (offset = 0) => new Date( fullYear, month , date + (offset * 7) )
    },
    {
        id: "last-30-days",
        label: "Last 30 Days",
        getStartDate: (offset = 0) => new Date( fullYear, month, date - 30 + (offset * 31) ),
        getEndDate: (offset = 0) => new Date( fullYear, month , date + (offset * 31) )
    },
    {
        id: "this-month",
        label: "This Month",
        getStartDate: (offset = 0) => new Date( fullYear, month + offset, 1 ),
        getEndDate: (offset = 0) => new Date( fullYear, month + 1 + offset, 0 )
    },
    {
        id: "last-month",
        label: "Last Month",
        getStartDate: (offset = 0) => new Date( fullYear, month - 1 + offset, 1 ),
        getEndDate: (offset = 0) => new Date( fullYear, month + offset, 0 )
    },
    {
        id: "this-quarter",
        label: "This Quarter",
        getStartDate: (offset = 0) => new Date( fullYear, quarter * 3 + (offset * 3), 1 ),
        getEndDate: (offset = 0) => new Date( fullYear, quarter * 3 + 3 + (offset * 3), 0 )
    },
    {
        id: "last-quarter",
        label: "Last Quarter",
        getStartDate: (offset = 0) => new Date( fullYear, quarter * 3 - 3 + (offset * 3), 1 ),
        getEndDate: (offset = 0) => new Date( fullYear, quarter * 3 + (offset * 3), 0 )
    },
    {
        id: "this-year",
        label: "This Year",
        getStartDate: (offset = 0) => new Date( fullYear + offset, 0, 1 ),
        getEndDate: (offset = 0) => new Date( fullYear + 1 + offset, 0, 0 )
    },
    {
        id: "last-year",
        label: "Last Year",
        getStartDate: (offset = 0) => new Date( fullYear - 1 + offset, 0, 1 ),
        getEndDate: (offset = 0) => new Date( fullYear + offset, 0, 0 )
    },
    {
        id: "year-to-date",
        label: "Year To Date",
        getStartDate: (offset = 0) => new Date( fullYear + offset, 0, 1 ),
        getEndDate: (offset = 0) => new Date( fullYear + offset, month, date )
    },
    {
        id: "custom",
        label: "Custom"
    }
];


export const relativeDatePresets = [
    {
        id: "previous-period",
        label: "Previous Period",
        getStartDate: (relativeDateRange) => {
            const preset = getPreset(relativeDateRange.presetID);
            if( preset.id === "custom" ){
                const dayDifference = Math.floor( (relativeDateRange.endTime - relativeDateRange.startTime) / (1000 * 3600 * 24) );
                return new Date( relativeDateRange.startTime - ( dayDifference + 1 ) * (1000 * 3600 * 24) );
            }else {
                return preset.getStartDate(-1);
            }
        },
        getEndDate: (relativeDateRange) => {
            const preset = getPreset(relativeDateRange.presetID);
            if( preset.id === "custom" ){
                const dayDifference = Math.floor( (relativeDateRange.endTime - relativeDateRange.startTime) / (1000 * 3600 * 24) );
                return new Date( relativeDateRange.endTime - ( dayDifference + 1 ) * (1000 * 3600 * 24) );
            }else {
                return preset.getEndDate(-1);
            }
        },
    },
    {
        id: "previous-year",
        label: "Previous Year",
        getStartDate: (relativeDateRange) => {
            const date = new Date( relativeDateRange.startTime );
            date.setFullYear( date.getFullYear() - 1 );
            return date;
        },
        getEndDate: (relativeDateRange) => {
            const date = new Date( relativeDateRange.endTime );
            date.setFullYear( date.getFullYear() - 1 );
            return date;
        },
    },
    {
        id: "custom",
        label: "Custom"
    }
]

export const getAllPresets = () => {
    return datePresets;
}

export const getAllRelativePresets = () => {
    return relativeDatePresets;
}

export const getPreset = (presetID) => {
    const preset = datePresets.find( (preset) => preset.id === presetID );
    return preset ? preset : null;
}

export const getRelativePreset = (presetID) => {
    const preset = relativeDatePresets.find( (preset) => preset.id === presetID );
    return preset ? preset : null;
}