import dateFormat from "dateformat";

/** Array of possible time intervals for chart ticks (from largest step to lowest step) */
export const dateTickFormats = [
    {
        name: "Yearly",
        getTicks: (startTime, endTime) => getMonthlyIntervalTicks(startTime, endTime, 12),
        formatDate: (startTime, endTime, date) => dateFormat(date, getDateFormatMask(startTime, endTime))
    },
    {
        name: "Quarter",
        getTicks: (startTime, endTime) => getMonthlyIntervalTicks(startTime, endTime, 3),
        formatDate: (startTime, endTime, date) => dateFormat(date, getDateFormatMask(startTime, endTime))
    },
    {
        name: "Bi-Monthly",
        getTicks: (startTime, endTime) => getMonthlyIntervalTicks(startTime, endTime, 2),
        formatDate: (startTime, endTime, date) => dateFormat(date, getDateFormatMask(startTime, endTime))
    },
    {
        name: "Monthly",
        getTicks: (startTime, endTime) => getMonthlyIntervalTicks(startTime, endTime, 1),
        formatDate: (startTime, endTime, date) => dateFormat(date, getDateFormatMask(startTime, endTime))
    },
    {
        name: "14-Days",
        getTicks: (startTime, endTime) => getTimeIntervalTicks(startTime, endTime, 1000 * 60 * 60 * 24 * 14),
        formatDate: (startTime, endTime, date) => dateFormat(date, getDateFormatMask(startTime, endTime))
    },
    {
        name: "7-Days",
        getTicks: (startTime, endTime) => getTimeIntervalTicks(startTime, endTime, 1000 * 60 * 60 * 24 * 7),
        formatDate: (startTime, endTime, date) => dateFormat(date, getDateFormatMask(startTime, endTime))
    },
    {
        name: "1-Day",
        getTicks: (startTime, endTime) => getTimeIntervalTicks(startTime, endTime, 1000 * 60 * 60 * 24),
        formatDate: (startTime, endTime, date) => dateFormat(date, getDateFormatMask(startTime, endTime))
    },
    {
        name: "12-Hours",
        getTicks: (startTime, endTime) => getTimeIntervalTicks(startTime, endTime, 1000 * 60 * 60 * 12),
        formatDate: (startTime, endTime, date) => dateFormat(date, getDateFormatMask(startTime, endTime, true))
    },
    {
        name: "6-Hours",
        getTicks: (startTime, endTime) => getTimeIntervalTicks(startTime, endTime, 1000 * 60 * 60 * 6),
        formatDate: (startTime, endTime, date) => dateFormat(date, getDateFormatMask(startTime, endTime, true))
    },
    {
        name: "4-Hours",
        getTicks: (startTime, endTime) => getTimeIntervalTicks(startTime, endTime, 1000 * 60 * 60 * 4),
        formatDate: (startTime, endTime, date) => dateFormat(date, getDateFormatMask(startTime, endTime, true))
    },
    {
        name: "2-Hours",
        getTicks: (startTime, endTime) => getTimeIntervalTicks(startTime, endTime, 1000 * 60 * 60 * 2),
        formatDate: (startTime, endTime, date) => dateFormat(date, getDateFormatMask(startTime, endTime, true))
    },
    {
        name: "1-Hour",
        getTicks: (startTime, endTime) => getTimeIntervalTicks(startTime, endTime, 1000 * 60 * 60),
        formatDate: (startTime, endTime, date) => dateFormat(date, getDateFormatMask(startTime, endTime, true))
    },
    // {
    //     name: "30-Minutes",
    //     getTicks: (startTime, endTime) => getTimeIntervalTicks(startTime, endTime, 1000 * 60 * 30),
    //     formatDate: (startTime, endTime, date) => dateFormat(date, getDateFormatMask(startTime, endTime, true))
    // },
    // {
    //     name: "10-Minutes",
    //     getTicks: (startTime, endTime) => getTimeIntervalTicks(startTime, endTime, 1000 * 60 * 10),
    //     formatDate: (startTime, endTime, date) => dateFormat(date, getDateFormatMask(startTime, endTime, true))
    // }
]

const getTimeIntervalTicks = (startTime, endTime, interval) => {
    let ticks = [];
    let i = 1;
    let currentTime = startTime;
    while( currentTime < endTime ){
        ticks.push(currentTime);
        currentTime = startTime + (i * interval);
        i++;
    }
    ticks.push(endTime);
    return ticks;
}

const getMonthlyIntervalTicks = (startTime, endTime, monthlyInterval) => {
    let ticks = [];
    let currentTime = startTime;
    while( currentTime < endTime ){
        ticks.push(currentTime);
        const currentDate = new Date(currentTime);
        const currentMonth = currentDate.getMonth()
        let nextYear = currentDate.getFullYear();
        let nextMonth =  currentMonth + monthlyInterval;
        const nextDate = new Date(nextYear, nextMonth, 1);
        currentTime = nextDate.getTime();
    }
    ticks.push(endTime);
    return ticks;
}

const getDateFormatMask = (startTime, endTime, showTime = false) => {
    const startDate = new Date(startTime);
    const endDate = new Date(endTime);

    const sameYear = startDate.getFullYear() === endDate.getFullYear();
    const sameMonth = (sameYear && startDate.getMonth() === endDate.getMonth());
    const sameDay = (sameMonth && Math.abs(startDate.getDate() - endDate.getDate()) <= 1 );

    let mask = "";

    if( sameDay ){ mask = "" }
    else if( sameMonth ){ mask = "d mmm" }
    else if( sameYear ){ mask = "d mmm" }
    else{ mask = "d mmm 'yy" }

    if( showTime ){
        mask = (mask + " H:MM").trim();
    }

    return mask;
}

/** Return array of timestamps at regular intervals between two dates */
export const getBestTimeTickFormat = (startTime, endTime, minTicks = 1) => {

    let matchedFormat = dateTickFormats.find( (dateTickFormat) => {
        const ticks = dateTickFormat.getTicks(startTime, endTime);
        if( ticks.length >= minTicks ){
            return dateTickFormat;
        }
    });

    return matchedFormat ? matchedFormat : dateTickFormats[dateTickFormats.length - 1];
}