import {gsap} from "gsap"

export const showFadeIn = (element, duration = 0.2) =>{
    gsap.defaults({duration: 0})
    let tl = gsap.timeline();
    tl.to( element, {clearProps: "display"});
    tl.to( element, {opacity: 1, duration: duration});
}

export const hideFadeOut = (element, duration = 0.2) => {
    gsap.defaults({duration: 0})
    let tl = gsap.timeline();
    tl.to( element, {opacity: 0, duration: duration});
    tl.to( element, {display: "none"});
}

export const changeOpacity = (element, duration = 0.2, opacity) => {
    gsap.defaults({duration: 0})
    let tl = gsap.timeline();
    tl.to( element, {opacity: opacity, duration: duration});
}

export const showByHeight = (element, duration = 0.2) => {
    gsap.defaults({duration: 0})
    let tl = gsap.timeline();
    tl.to( element, {clearProps: "display"});
    tl.to( element, {height: "auto", opacity: 1, duration: duration});
    tl.to( element, {clearProps: "overflow"});
}

export const hideByHeight = (element, duration = 0.2) => {
    gsap.defaults({duration: 0})
    let tl = gsap.timeline();
    tl.to( element, {overflow: "hidden"});
    tl.to( element, {height: 0, opacity: 0, duration: duration});
    tl.to( element, {display: "none"});

}