import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from '../Fields.module.scss';
import classnames from 'classnames';
import Label from './partials/Label';

const Select = ({ field }) => {
    // State
    const [showDropdown, setShowDropdown] = useState(false);

    // Refs
    const refDropdown = React.createRef();
    const refSelectButton = React.createRef();

    // Register click event
    useEffect(() => {
        document.addEventListener('mousedown', eventClicked);
        return () => {
            document.removeEventListener('mousedown', eventClicked);
        };
    });

    // Memo
    const selectedOption = useMemo(() => {
        const matchedOption = field.options.find((option) => option.value === field.value);
        if (matchedOption) {
            return matchedOption;
        } else {
            return {
                value: '',
                name: '',
            };
        }
    }, [field.value]);

    const eventClicked = (e) => {
        if (
            refSelectButton.current &&
            !refSelectButton.current.contains(e.target) &&
            refDropdown.current &&
            !refDropdown.current.contains(e.target)
        ) {
            setShowDropdown(false);
            field.onBlur && field.onBlur(field.name);
        }
    };

    // Event Handlers
    const onOptionChange = (nextValue) => {
        field.onChange && field.onChange(field.name, nextValue);
        setShowDropdown(false);
    };

    // Select Options
    const selectOptions = field.options.map((option, key) => {
        return (
            <option key={key} value={option.value}>
                {option.name}
            </option>
        );
    });

    // Dropdown Items
    const dropdownItems = field.options.map((option) => {
        return (
            <li
                key={option.value}
                className={classnames('item', field.value === option.value && 'selected')}
                onClick={() => onOptionChange(option.value)}>
                {option.name}
            </li>
        );
    });

    return (
        <div
            ref={field.wrapperRef}
            className={classnames(
                'fieldWrapper typeSelect',
                styles.typeSelect,
                field.wrapperClass,
                !field.value && 'empty',
                field.focused && 'focused',
                field.disabled && 'disabled'
            )}
            onFocus={() => field.onFocus && field.onFocus(field.name)}
            tabIndex={-1}>
            <Label field={field} />
            <div className={classnames('field', field.fieldClass)}>
                <div className={classnames('selectElement', field.inputClass)}>
                    <button
                        ref={refSelectButton}
                        className={showDropdown ? 'focus' : ''}
                        onClick={(e) => {
                            e.preventDefault();
                            setShowDropdown(!showDropdown);
                        }}>
                        <span className='label'>{selectedOption.name}</span>
                        <span className='icon'>
                            <i className='fas fa-caret-down' />
                        </span>
                    </button>
                    {showDropdown && (
                        <div ref={refDropdown} className={'dropdown'}>
                            {!field.required && (
                                <li className={classnames('item', field.value === '' && 'selected')} onClick={() => onOptionChange('')}>
                                    {field.placeholder ? field.placeholder : '--'}
                                </li>
                            )}
                            {dropdownItems}
                        </div>
                    )}
                </div>

                <select
                    ref={field.inputRef}
                    id={field.name}
                    name={field.name}
                    value={field.value ? field.value : ''}
                    required={field.required && 'required'}
                    onChange={(e) => onOptionChange(e.target.value)}>
                    <option value='' default disabled={field.required && 'disabled'}>
                        {field.placeholder}
                    </option>
                    {selectOptions}
                </select>

                {field.afterInput}
            </div>
        </div>
    );
};

Select.propTypes = {
    field: PropTypes.shape({
        // Base props
        name: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        options: PropTypes.array,
        placeholder: PropTypes.string,
        required: PropTypes.bool,
        // Ref props
        inputRef: PropTypes.object,
        wrapperRef: PropTypes.object,
        // Callback props
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        onClick: PropTypes.func,
        // Class props
        wrapperClass: PropTypes.string,
        fieldClass: PropTypes.string,
        inputClass: PropTypes.string,
        // Label props
        label: PropTypes.string,
        labelClass: PropTypes.string,
        hideLabel: PropTypes.bool,
        hideRequiredSign: PropTypes.bool,
        // Other props
        afterInput: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    }),
};

export default Select;
