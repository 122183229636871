import React, { useState } from 'react';
import styles from './PostsList.module.scss';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { highlightKeywords } from '../../_socialMediaPosts';

const PostsList = ({ account, posts, keywords = [] }) => {
    // State
    const [activePosts, setActivePosts] = useState([]);

    const toggleActivePost = (postID) => {
        const nextActivePosts = [...activePosts];
        if (!activePosts.includes(postID)) {
            nextActivePosts.push(postID);
            setActivePosts(nextActivePosts);
        } else {
            const index = activePosts.indexOf(postID);
            if (index > -1) {
                const nextActivePosts = [...activePosts];
                nextActivePosts.splice(index, 1);
                setActivePosts(nextActivePosts);
            }
        }
    };

    const postItems = posts.map((post, index) => {
        return (
            <div key={post.postID} className='postItem'>
                <div className='postHeader' onClick={() => toggleActivePost(post.postID)}>
                    <div className='postIndex'>{index + 1}</div>
                    <div className='postTitle'>
                        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{highlightKeywords(post.title, keywords)}</ReactMarkdown>
                    </div>
                    <div className='postPlatform'>{account.getPlatformOptions().find((option) => option.value === post.platform).value}</div>
                </div>
                {activePosts.includes(post.postID) && (
                    <div>
                        <div className='postMeta'>
                            <a href={post.meta.url}>{post.meta.url}</a>
                        </div>
                        <div className='postBody'>
                            <ReactMarkdown rehypePlugins={[rehypeRaw]}>{highlightKeywords(post.content, keywords)}</ReactMarkdown>
                        </div>
                    </div>
                )}
            </div>
        );
    });

    return <div className={classnames('postList', styles.module)}>{postItems}</div>;
};

PostsList.propTypes = {
    account: PropTypes.object.isRequired,
    posts: PropTypes.array.isRequired,
    keywords: PropTypes.array.isRequired,
};

export default PostsList;
