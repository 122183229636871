import React from 'react';
import PropTypes from 'prop-types';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from '@/router/PrivateRoute';
import { useSelector } from 'react-redux';
import { userHasAnyPermission } from '@/services/strapi/permissions';
import { selectorCurrentUserPermissions } from '@/store/slices/auth';

const ViewRouter = ({ views, permissionsKey = "" }) => {
    
    // Selectors
    const user = useSelector((state) => state.auth.user);
    const userPermissions = useSelector((state) => selectorCurrentUserPermissions(state, permissionsKey));

    const viewsWithEndpoints = views.filter((view) => view.hasOwnProperty('endpoint'));

    const ViewRoutes = viewsWithEndpoints.map((view, key) => {
        let component = view.component;

        // Check for user permissions
        if (view.hasOwnProperty('permissions') && !userHasAnyPermission(user, userPermissions, view.permissions)) {
            component = <p>You don't have the required permissions to access this page.</p>;
        }

        return <Route key={key} path={view.endpoint} element={<PrivateRoute element={component} />} />;
    });

    return (
        <Routes>
            {ViewRoutes}
            <Route path='*' element={<p>Requested view doesn't exist.</p>} />
        </Routes>
    );
};

ViewRouter.propTypes = {
    views: PropTypes.array.isRequired,
    permissionsKey: PropTypes.string,
};

export default ViewRouter;
