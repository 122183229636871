import Tool from '@/components/tools/Tool.class';
import ViewRouter from '@/components/screens/router/ViewRouter/ViewRouter';
import AccountSettings from './screens/settings/AccountSettings';
import ScreenManageAccountUsers from '@/components/screens/settings/ScreenManageAccountUsers/ScreenManageAccountUsers';
import { policies } from './constants';
import ToolWrapper from './ToolWrapper/ToolWrapper';
import * as logEntries from '@/services/strapi/tools/logEntries';

class Event_Logger extends Tool {
    constructor(uid) {
        super(uid);
    }

    // Views
    getStatusArea = () => {};

    getMainViews = () => [
        {
            endpoint: '',
            component: <ToolWrapper tool={this} />,
            permissions: [
                {
                    type: 'userHasPolicies',
                    value: ['can-view-logs'],
                },
            ],
        },
        {
            endpoint: 'options/*',
            component: <ViewRouter views={this.getSettingsViews()} permissionsKey={this.uid} />,
        },
    ];

    getSettingsViews = () => [
        {
            endpoint: 'log-books',
            link: 'options/log-books/',
            label: 'Log Books',
            icon: 'fas fa-list',
            permissions: [
                {
                    type: 'userHasPolicies',
                    value: ['can-manage-logs'],
                },
            ],
            component: <AccountSettings tool={this} />,
        },
        {
            endpoint: 'user-permissions',
            link: 'options/user-permissions/',
            label: 'User Permissions',
            icon: 'fas fa-user-cog',
            permissions: [
                {
                    type: 'userHasPolicies',
                    value: ['can-edit-users'],
                },
            ],
            component: (
                <ScreenManageAccountUsers
                    handlers={{
                        getUsers: this.getUsers,
                        addUser: this.addUser,
                        removeUser: this.removeUser,
                        getAllUsersPermissions: this.getAllUsersPermissions,
                        setUserPermissions: this.setUserPermissions,
                    }}
                    policies={policies}
                />
            ),
        },
    ];

    // Extend Tool API

    // Get log entries count in log book
    getLogEntriesCount = async (logBookKey = null, group = null) => {
        const query = {};
        if (logBookKey) query.logbook = logBookKey;
        if (group) query.group = group;
        return await logEntries.getLogEntriesCount(this.uid, query);
    };

    // Get log groups count in log book
    getLogGroupsCount = async (logBookKey = null) => {
        const query = {};
        if (logBookKey) query.logbook = logBookKey;
        return await logEntries.getLogGroupsCount(this.uid, query);
    };

    // Get log entries
    getLogEntries = async (logBookKey = null, group = null, pagination = {}) => {
        const query = {};
        if (pagination) query.pagination = pagination;
        if (logBookKey) query.logbook = logBookKey;
        if (group) query.group = group;
        return await logEntries.getLogEntries(this.uid, query);
    };

    // Clear log entries from log book
    clearLogEntries = async (logBookKey) => {
        return await logEntries.clearLogEntries(this.uid, logBookKey);
    };

    // Delete log group
    deleteLogGroup = async (logBookKey, group) => {
        return await logEntries.deleteLogGroup(this.uid, logBookKey, group);
    };
}

export default Event_Logger;
