import * as api from "@/services/strapi/rest";
import {logError} from "@/util/debugger";

/** Define collection type basename */
const basename = "metrics-accounts";

/** Default Query */
export const defaultQuery = {
    populate: '*',
    pagination: {
        page: 1,
        pageSize: 500,
    }
}

/** Get csv export data for collection type */
export const getCollectionTypeExportData = async (id, body = new FormData(), query = {}) => {
    let pageCount = 1;
    let page = 1;
    const allItems = [];

    while( pageCount >= page  ){
        const queryString = api.createQueryString({...defaultQuery, pagination: {...defaultQuery.pagination, page: page}}, query );
        const response = await api.restStrapiPostRequest(`${basename}/${id}/data-export/?${queryString}`, true, body);
        if( !response.error ){
            allItems.push(...response.results);
            pageCount = response.pagination.pageCount;
        }else{
            logError(response);
            return response;
        }
        page++;
    }

    return allItems;
}

/** Get csv export data for API data */
export const getApiExportData = async (id, body = new FormData(), query = {}) => {

    let page = 1;
    let pageItems = [];
    const allItems = [];

    do{
        const queryString = api.createQueryString({...defaultQuery, pagination: {...defaultQuery.pagination, page: page}}, query );
        const response = await api.restStrapiPostRequest(`${basename}/${id}/api-data-export/?${queryString}`, true, body);
        if( !response.error ){
            pageItems = response
            allItems.push(...pageItems);
        }else{
            logError(response);
            return response;
        }
        page++;
    } while( pageItems.length > 0 );

    return allItems;
}
