import Text from './Fields/Text';
import Textarea from './Fields/Textarea';
import Url from './Fields/Url';
import Number from './Fields/Number';
import Select from './Fields/Select';
import SelectMultiple from './Fields/SelectMultiple';
import Email from './Fields/Email';
import Password from './Fields/Password';
import Boolean from './Fields/Boolean';
import File from './Fields/File';
import Button from './Fields/Button';
import Hidden from './Fields/Hidden';
import Submit from './Fields/Submit';
import FormHeader from './Fields/FormHeader';

export { default as Text } from './Fields/Text';
export { default as Textarea } from './Fields/Textarea';
export { default as Url } from './Fields/Url';
export { default as Number } from './Fields/Number';
export { default as Select } from './Fields/Select';
export { default as SelectMultiple } from './Fields/SelectMultiple';
export { default as Email } from './Fields/Email';
export { default as Password } from './Fields/Password';
export { default as Boolean } from './Fields/Boolean';
export { default as File } from './Fields/File';
export { default as Button } from './Fields/Button';
export { default as Hidden } from './Fields/Hidden';
export { default as Submit } from './Fields/Submit';
export { default as FormHeader } from './Fields/FormHeader';

const typeToComponent = {
    text: Text,
    textarea: Textarea,
    url: Url,
    number: Number,
    select: Select,
    selectMultiple: SelectMultiple,
    email: Email,
    password: Password,
    boolean: Boolean,
    file: File,
    button: Button,
    hidden: Hidden,
    submit: Submit,
    formHeader: FormHeader,
};

export default typeToComponent;

/** Detect if the given input element is autofilled by the browser */
export const detectAutofill = async (element) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(window.getComputedStyle(element, null).getPropertyValue('appearance') === 'menulist-button');
        }, 500);
    });
};
