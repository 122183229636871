import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import FormHandler from '@/components/ui/FormHandler/FormHandler';
import { userResetPasswordFields } from './_formFields';
import { resetUserPassword } from '@/services/strapi/auth';
import * as endpoints from '@/router/_endpoints';

const ResetPasswordForm = ({ code }) => {
    // Handler Form Submit
    const onSubmit = async (data) => {
        const password = data.find((input) => input.name === 'password').value;
        const passwordConfirmation = data.find((input) => input.name === 'passwordConfirmation').value;

        // Catch early password mismatch
        if (password !== passwordConfirmation) {
            return {
                status: 'error',
                message: 'Passwords do not match.',
                clearFields: true,
            };
        }

        // Catch early if password is too short
        if (password.length < 8) {
            return {
                status: 'error',
                message: 'Password is too short.',
                clearFields: true,
            };
        }

        const res = await resetUserPassword(password, passwordConfirmation, code);

        if (res && !res.error) {
            return {
                status: 'success',
                message: 'Password Reset. Please Log In.',
                clearFields: true,
            };
        } else {
            return {
                status: 'error',
                message: 'Something went wrong. Please try Again.',
                clearFields: true,
            };
        }
    };

    return (
        <>
            <FormHandler
                fields={userResetPasswordFields}
                formOptions={{
                    formClass: 'formStyleA',
                }}
                callbacks={{ onSubmit: onSubmit }}
            />
            <p>
                <Link to={endpoints.LOGIN}>Log In</Link>
                <span> | </span>
                <Link to={endpoints.FORGOT_PASSWORD}>Forgot your password?</Link>
            </p>
        </>
    );
};

ResetPasswordForm.propTypes = {
    code: PropTypes.string,
};

export default ResetPasswordForm;
