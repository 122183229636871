import * as users from '@/services/strapi/tools/users';
import * as permissions from '@/services/strapi/tools/permissions';
import * as settings from '@/services/strapi/tools/settings';
import * as reports from '@/services/strapi/tools/reports';

class Tool {
    constructor(uid) {
        this.uid = uid;
    }

    /** Users */
    getUsers = async () => {
        return await users.getAccountUsers(this.uid);
    };

    addUser = async (userID) => {
        const formData = new FormData();
        formData.append('userID', userID);
        return await users.addAccountUser(this.uid, formData);
    };

    removeUser = async (userID) => {
        const formData = new FormData();
        formData.append('userID', userID);
        return await users.removeAccountUser(this.uid, formData);
    };

    /** Permissions */
    setUserPermissions = async (userID, permissionsObject) => {
        const formData = new FormData();
        formData.append('userID', userID);
        formData.append('permissions', JSON.stringify(permissionsObject));
        return await permissions.updateAccountUserPermissions(this.uid, formData);
    };

    getCurrentUserPermissions = async () => {
        return await permissions.getCurrentUserPermissions(this.uid);
    };

    getAllUsersPermissions = async () => {
        return await permissions.getAllAccountUserPermissions(this.uid);
    };

    /** Settings */
    getSettings = async () => {
        return await settings.getSettings(this.uid);
    };

    setSettings = async (settingsObject) => {
        const formData = new FormData();
        formData.append('data', JSON.stringify(settingsObject));
        return await settings.setSettings(this.uid, formData);
    };

    /** Reports */
    countReports = async (filters = {}) => {
        const formData = new FormData();
        formData.append('data', JSON.stringify(filters));
        return await reports.countReports(this.uid, formData);
    };

    getReports = async (pagination = {}, filters = {}, sort = {}) => {
        const query = { pagination, sort, filters };
        return await reports.getReports(this.uid, query);
    };

    getReportItems = async (id, pagination = {}, filters = {}, sort = {}, data = {}) => {
        const query = { pagination, sort, filters };
        const formData = new FormData();
        formData.append('data', JSON.stringify(data));
        return await reports.getReportItems(this.uid, id, query, formData);
    };

    scheduleReport = async (id = null, params) => {
        const formData = new FormData();
        formData.append('data', JSON.stringify(params));
        return await reports.scheduleReport(this.uid, id, formData);
    };

    editReport = async (id, data) => {
        const formData = new FormData();
        formData.append('data', JSON.stringify(data));
        return await reports.editReport(this.uid, id, formData);
    };

    stopReport = async (id) => {
        return await reports.stopReport(this.uid, id);
    };

    resumeReport = async (id) => {
        return await reports.resumeReport(this.uid, id);
    };

    retryReport = async (id) => {
        return await reports.retryReport(this.uid, id);
    };

    deleteReport = async (id) => {
        return await reports.deleteReport(this.uid, id);
    };
}

export default Tool;
