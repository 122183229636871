export const salesTypeOptions = [
    {
        value: "sale",
        name: "Sales",
    },
    {
        value: "renewal",
        name: "Renewals",
    },
    {
        value: "refunded",
        name: "Refunds",
    },
    {
        value: "cancelled",
        name: "Cancelled",
    },
    {
        value: "failed",
        name: "Failed",
    },
]

export const analyticsDayOptions = [
    {
        value: 1,
        name: "1 day after publish"
    },
    {
        value: 3,
        name: "3 days after publish"
    },
    {
        value: 7,
        name: "7 days after publish"
    },
    {
        value: 14,
        name: "14 days after publish"
    }
]

export const salesDayOptions = [
    {
        value: 1,
        name: "1 day before and after publish"
    },
    {
        value: 3,
        name: "3 days before and after publish"
    },
    {
        value: 7,
        name: "7 days before and after publish"
    },
]