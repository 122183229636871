import React from "react";
import PropTypes from "prop-types";
import styles from "../../Fields.module.scss";
import classnames from "classnames";

const Label = ({field}) => {

    if( !field.hideLabel && field.label ) {
        return (
            <label htmlFor={field.name} className={classnames("fieldLabel", styles.fieldLabel, field.labelClass)}>
                {field.label} {field.required && !field.hideRequiredSign && <span className='required'>*</span>}
            </label>
        )
    }else{
        return "";
    }

}

Label.propTypes = {
    field: PropTypes.shape({
        name : PropTypes.string,
        label: PropTypes.string,
        required: PropTypes.bool,
        labelClass: PropTypes.string,
        hideLabel: PropTypes.bool,
        hideRequiredSign: PropTypes.bool
    }),
}

export default Label;