const integrations = [
    {
        id: "integrations.easy-digital-downloads",
        fields: [
            {
                label: "EDD API Credentials",
                type: "formHeader",
                wrapperClass: "flex-span-12"
            },
            {
                name: "websiteURL",
                label: "Website URL",
                type: "url",
                required: true,
                wrapperClass: "flex-span-12",
            },
            {
                name: "publicKey",
                label: "Public Key",
                type: "text",
                required: true,
                wrapperClass: "flex-span-6",
            },
            {
                name: "token",
                label: "Token",
                type: "password",
                required: true,
                wrapperClass: "flex-span-6",
                enablePasswordShow: true,
                autoComplete: "new-password",
            }
        ]
    },
    {
        id: "integrations.social-media-listening",
        fields: []
    },
    {
        id: "integrations.youtube-analytics-and-reporting",
        fields: [
            {
                label: "YouTube API Credentials",
                type: "formHeader",
                wrapperClass: "flex-span-12"
            },
            {
                name: "channel_id",
                label: "YouTube Channel ID",
                type: "text",
                required: true,
                wrapperClass: "flex-span-6",
            },
            // {
            //     name: "private_key",
            //     label: "Service Account Private Key",
            //     type: "password",
            //     required: true,
            //     wrapperClass: "flex-span-6",
            //     enablePasswordShow: true,
            //     autoComplete: "new-password"
            // },
            // {
            //     name: "client_email",
            //     label: "Service Account Private Email",
            //     type: "text",
            //     required: true,
            //     wrapperClass: "flex-span-6",
            // },
            {
                name: "client_id",
                label: "Client ID",
                type: "text",
                required: true,
                wrapperClass: "flex-span-6",
            },
            {
                name: "client_secret",
                label: "Client Secret",
                type: "password",
                required: true,
                wrapperClass: "flex-span-6",
                enablePasswordShow: true,
                autoComplete: "new-password",
            },
            // {
            //     name: "jsonKey",
            //     label: "JSON Key File",
            //     type: "file",
            //     extensions: ['.json'],
            //     required: true
            // }
        ]
    }
];

export const getIntegrationFields = (integrationID) => {
    const integration = integrations.find( (entry) => entry.id === integrationID );
    const integrationFields = [
        {
            name: integrationID + ".__component",
            value: integrationID,
            label: "Integration ID",
            type: "hidden",
            required: true,
            dataFilter: {
                type: "dynamicZone",
                name: "integrations",
                componentID: integrationID
            }
        },
    ];
    integration.fields.forEach( (field) => {
        integrationFields.push({
            ...field,
            name: `${integrationID}.${field.name}`,
            dataFilter: {
                type: "dynamicZone",
                name: "integrations",
                componentID: integrationID
            }
        });
    })
    return integrationFields;
}