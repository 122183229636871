import React from 'react';
import PropTypes from 'prop-types';
import { createCSVFileDownload } from '@/util/files';
import { Button } from '@/components/ui/FormFields';
import { webarchiveSnapshotTimestampToDate } from '@/util/format';

const ExportReportCSV = ({ buttonTitle, reportItems, filename, startingIndex = 1 }) => {
    if (!reportItems) return null;

    const getReportItemData = (item, index) => {
        const indexNo = startingIndex + index;
        const { video, analytics, channel } = item.data;
        const { group } = item;

        if (!video || !analytics || !channel) return ['Error: Missing data'];

        return [
            indexNo,
            group,
            video.id,
            video.title,
            video.url,
            new Date(video.publishedAt).toLocaleString(),
            video.duration,
            video.viewCount,
            analytics.snapshotTimestamp ? webarchiveSnapshotTimestampToDate(analytics.snapshotTimestamp).toLocaleString() : null,
            analytics.snapshotViewCount,
            analytics.viewCountChange,
            analytics.monthlyAverageViews,
            channel.name,
            channel.id,
        ].map((entry) => (entry ? `"${entry.toString().replace(/"/g, '""')}"` : ''));
    };

    // On Export
    const onExport = () => {
        // Filename
        const csvTitle = filename ? filename : 'Export';
        // CSV Headers
        const headers = [
            'no',
            'group',
            'video_id',
            'title',
            'url',
            'publishedAt',
            'duration',
            'viewCount',
            'snapshotTimestamp',
            'snapshotViewCount',
            'viewCountChange',
            'monthlyAverageViews',
            'channel_name',
            'channel_id',
        ].map((entry) => `"${entry}"`);

        // CSV Data
        const data = reportItems.map((item, index) => {
            return getReportItemData(item, index);
        });

        const csvString = [headers.join(','), ...data.map((row) => row.join(','))].join('\n');

        createCSVFileDownload(csvString, csvTitle);
    };

    return (
        <Button
            field={{
                name: 'exportButtonCSV',
                value: buttonTitle,
                inputClass: 'primary',
                wrapperClass: 'exportButton',
                onClick: onExport,
            }}
        />
    );
};

ExportReportCSV.propTypes = {
    buttonTitle: PropTypes.string.isRequired,
    reportItems: PropTypes.array.isRequired,
    filename: PropTypes.string.isRequired,
    startingIndex: PropTypes.number,
};

export default ExportReportCSV;
