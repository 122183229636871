import React from 'react';
import PropTypes from 'prop-types';
import { SelectMultiple } from '@/components/ui/FormFields';
import classnames from 'classnames';

const CountrySelect = ({ countryOptions = [], regionOptions = [], selectedCountryValues = [], onChange }) => {
    // On Change
    const onSelectionChange = (keys) => {
        const nextSelectedCountryIDs = countryOptions.filter((option) => keys.includes(option.value)).map((option) => option.value);
        onChange(nextSelectedCountryIDs);
    };

    return (
        <div className={classnames('CountrySelect')}>
            <SelectMultiple
                field={{
                    options: countryOptions,
                    optionGroups: regionOptions,
                    value: selectedCountryValues,
                    onChange: (name, value) => onSelectionChange(value),
                    summaryLabels: {
                        blank: 'Select Countries',
                        multiple: '{count} Countries Selected',
                        all: 'All Countries',
                    },
                }}
            />
        </div>
    );
};

CountrySelect.propTypes = {
    countryOptions: PropTypes.array.isRequired,
    selectedCountryValues: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default CountrySelect;
