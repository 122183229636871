import React, { useState, useLayoutEffect, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectorToolSettings } from '@/store/slices/tools';
import { useIsMounted } from '@/util/hooks';
import { LoadingIcon } from '@/components/ui';
import FormHandler from '@/components/ui/FormHandler/FormHandler';
import styles from './AccountSettings.module.scss';
import logEntries from '@/store/slices/logEntries';

const AccountSettings = ({ tool }) => {
    // States
    const [fetching, setFetching] = useState(true);
    const [logBooks, setLogBooks] = useState([]);
    const [logBookData, setLogBookData] = useState({});
    const [sanitizedName, setSanitizedName] = useState('');
    const [sanitizedKey, setSanitizedKey] = useState('');
    const [error, setError] = useState('');

    // Selector
    const settings = useSelector((state) => selectorToolSettings(state, tool.uid));

    // Hooks
    const isMounted = useIsMounted();

    // Effect
    useLayoutEffect(() => {
        tool.getSettings().then(() => {
            isMounted && setFetching(false);
        });
    }, []);

    useLayoutEffect(() => {
        if (settings && settings.logBooks) {
            const nextLogBookData = {};
            const promises = [];
            settings.logBooks.forEach((logBook) => {
                promises.push(
                    tool.getLogEntriesCount(logBook.key).then((logEntriesCount) => {
                        nextLogBookData[logBook.key] = nextLogBookData[logBook.key]
                            ? { ...nextLogBookData[logBook.key], logEntriesCount: logEntriesCount }
                            : { logEntriesCount };
                    })
                );
                promises.push(
                    tool.getLogGroupsCount(logBook.key).then((logGroupsCount) => {
                        nextLogBookData[logBook.key] = nextLogBookData[logBook.key]
                            ? { ...nextLogBookData[logBook.key], logGroupsCount: logGroupsCount }
                            : { logGroupsCount };
                    })
                );
            });
            Promise.all(promises).then(() => {
                isMounted && setLogBooks(settings.logBooks);
                isMounted && setLogBookData(nextLogBookData);
            });
        }
    }, [settings]);

    // Fields
    const fields = useMemo(() => {
        return [
            {
                type: 'formHeader',
                label: 'Create New Logbook',
            },
            {
                name: 'name',
                label: 'Logbook Name',
                type: 'text',
                required: true,
                wrapperClass: 'wrapper',
                inputClass: 'input',
                onChange: (name, value) => onNameChange(name, value),
                value: sanitizedName,
            },
            {
                name: 'key',
                label: 'Logbook Key',
                type: 'text',
                required: true,
                wrapperClass: 'wrapper',
                inputClass: 'input',
                onChange: (name, value) => onKeyChange(name, value),
                value: sanitizedKey,
            },
            {
                name: 'submit',
                type: 'submit',
                value: 'Create Logbook',
            },
        ];
    }, [settings, sanitizedName, sanitizedKey]);

    // Handlers

    // Save name and create sanitized key
    const onNameChange = (name, value) => {
        // Remove spaces and make lowercase
        setSanitizedName(value);
        onKeyChange('key', value);
    };

    // Sanitize key input
    const onKeyChange = (name, value) => {
        // Remove spaces and make lowercase, remove special characters
        const sanitizedKey = value
            .replace(/\s/g, '-')
            .toLowerCase()
            .replace(/[^a-z0-9-]/g, '');
        setSanitizedKey(sanitizedKey);
    };

    // Create Logbook
    const onCreateLogbook = async (data) => {
        setError('');
        setFetching(true);

        const nextLogBooks = [...logBooks];
        const name = data.find((field) => field.name === 'name').value;
        const key = data.find((field) => field.name === 'key').value;

        if (!key || !name) {
            setError('Name and Key are required.');
            setFetching(false);
            return;
        }

        // Check if key is already in use
        const logBookIndex = nextLogBooks.findIndex((logBook) => logBook.key === key);
        if (logBookIndex !== -1) {
            setError('Logbook key is already in use.');
            setFetching(false);
            return;
        }

        // Add new log book
        nextLogBooks.push({ name, key });

        // Update settings
        const nextSettings = {
            ...settings,
            logBooks: nextLogBooks,
        };

        tool.setSettings(nextSettings).then((response) => {
            isMounted && setFetching(false);
            if (response.error) {
                setError(response.error);
                return;
            }
        });
    };

    // Remove Logbook
    const onRemoveLogbook = (logBook) => {
        setFetching(true);

        const nextLogBooks = logBooks.filter((item) => item.key !== logBook.key);

        // Update settings
        const nextSettings = {
            ...settings,
            logBooks: nextLogBooks,
        };

        tool.setSettings(nextSettings).then((response) => {
            isMounted && setFetching(false);
            if (response.error) {
                setError(response.error);
                return;
            }
        });
    };

    // Clear Logbook
    const onClearLogbook = (logBook) => {
        setFetching(true);
        tool.clearLogEntries(logBook.key).then(() => {
            tool.getSettings().then(() => {
                isMounted && setFetching(false);
            });
        });
    };

    if (fetching) {
        return <LoadingIcon />;
    }

    // Render Logbooks
    const renderLogbooks = () => {
        if (logBooks.length === 0) {
            return <p>No log books found.</p>;
        }

        return (
            <div className='logBookTable'>
                <div className='gridItem itemStyleHeader'>Name</div>
                <div className='gridItem itemStyleHeader'>Key</div>
                <div className='gridItem itemStyleHeader'>Groups</div>
                <div className='gridItem itemStyleHeader'>Log Entries</div>
                <div className='gridItem itemStyleHeader'>Actions</div>

                {logBooks.map((logBook, key) => {
                    return (
                        <Fragment key={key}>
                            <div className='gridItem itemStyleCell'>{logBook.name}</div>
                            <div className='gridItem itemStyleCell'>{logBook.key}</div>
                            <div className='gridItem itemStyleCell'>{logBookData[logBook.key].logGroupsCount}</div>
                            <div className='gridItem itemStyleCell'>{logBookData[logBook.key].logEntriesCount}</div>
                            <div className='gridItem itemStyleCell'>
                                {logBookData[logBook.key].logEntriesCount > 0 && (
                                    <span className='deleteAction' onClick={() => onClearLogbook(logBook)}>
                                        Clear
                                    </span>
                                )}
                                {logBookData[logBook.key].logEntriesCount === 0 && (
                                    <span className='deleteAction' onClick={() => onRemoveLogbook(logBook)}>
                                        Delete
                                    </span>
                                )}
                            </div>
                        </Fragment>
                    );
                })}
            </div>
        );
    };

    return (
        <div className={styles.module}>
            <h3>Logbooks</h3>
            {renderLogbooks()}

            <FormHandler
                fields={fields}
                formOptions={{
                    formClass: 'formStyleB',
                }}
                callbacks={{
                    onSubmit: (data) => onCreateLogbook(data),
                }}
            />
            {error && <p className='error'>{error}</p>}
        </div>
    );
};

AccountSettings.propTypes = {
    tool: PropTypes.object.isRequired,
};

export default AccountSettings;
