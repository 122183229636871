export const userCreateFields = [
    {
        name: "email",
        label: "Email",
        type: "email",
        required: true,
        wrapperClass: "flex-span-12"
    },
    {
        name: "displayName",
        label: "Display Name",
        type: "text",
        required: true,
        wrapperClass: "flex-span-12"
    },
    {
        name: "role",
        label: "Access Level",
        type: "select",
        required: true,
        wrapperClass: "flex-span-12",
        options: []
    },
    {
        name: "password",
        label: "Password",
        type: "password",
        required: true,
        wrapperClass: "flex-span-12",
        enablePasswordShow: true,
        enablePasswordGenerator: true,
        autoComplete: "new-password"
    },
    {
        name: "confirmed",
        label: "Skip email confirmation?",
        type: "boolean"
    },
    {
        name: "submit",
        value: "Create User",
        type: "submit"
    }
]

export const userEditFields = [
    {
        name: "email",
        label: "Email",
        type: "email",
        wrapperClass: "flex-span-12"
    },
    {
        name: "displayName",
        label: "Display Name",
        type: "text",
        wrapperClass: "flex-span-12"
    },
    {
        name: "role",
        label: "Access Level",
        type: "select",
        required: true,
        wrapperClass: "flex-span-12",
        options: []
    },
    {
        name: "password",
        label: "Password",
        type: "password",
        wrapperClass: "flex-span-12",
        enablePasswordShow: true,
        enablePasswordGenerator: true,
        autoComplete: "new-password"

    },
    {
        name: "confirmed",
        label: "Confirmed",
        type: "boolean"
    },
    {
        name: "submitUserUpdate",
        value: "Update User",
        type: "submit"
    },
    {
        name: "submitSendConfirmation",
        value: "Send Confirmation Email",
        type: "submit",
        fieldClass: "colorSimple"
    },
    {
        name: "submitDeleteUser",
        value: "Delete User",
        type: "submit",
        fieldClass: "colorDelete"
    }
]