import React from 'react';
import MenuSidebar from '@/components/nav/menus/MenuSidebar';
import classnames from 'classnames';
import styles from './NavSidebar.module.scss';

const NavSidebar = () => {
    return (
        <div className={classnames('sideMenu', styles.module)}>
            <MenuSidebar />
        </div>
    );
};

export default NavSidebar;
