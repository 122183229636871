import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const Pagination = ({ page, limit, total, setPage, setLimit }) => {
    // Calculate total pages
    const totalPages = Math.max(1, Math.ceil(total / limit));

    // Effect: Reset Page on total change
    useEffect(() => {
        setPage(1);
        setLimit(limit);
    }, [total]);

    // Handler: Change page
    const onPageChange = (page) => {
        let pageInt = parseInt(page);
        if (pageInt < 1) {
            pageInt = 1;
        }
        if (pageInt > totalPages) {
            pageInt = totalPages;
        }
        setPage(pageInt);
    };

    // Handler: Change limit
    const onLimitChange = (size) => {
        const sizeInt = parseInt(size);
        setLimit(sizeInt);
        // Limit page number
        if (page * sizeInt > total) {
            setPage(Math.max(1, Math.ceil(total / sizeInt)));
        }
    };

    return (
        <div className='pagination'>
            <button onClick={() => onPageChange(page - 1)}>
                <i className='fa-solid fa-chevron-left' />
            </button>
            <span className='pageNo'>
                <input type='text' onChange={(e) => onPageChange(e.target.value)} value={page} />
                <span className='delimiter'>/</span>
                <span>{totalPages}</span>
            </span>
            <button onClick={() => onPageChange(page + 1)}>
                <i className='fa-solid fa-chevron-right' />
            </button>
            <span className='label'>Page Size:</span>
            <select onChange={(e) => onLimitChange(e.target.value)} value={limit.toString()}>
                <option value='5'>5</option>
                <option value='10'>10</option>
                <option value='20'>20</option>
                <option value='50'>50</option>
                <option value='100'>100</option>
                <option value='500'>500</option>
                <option value='1000'>1000</option>
                <option value='1000000'>All</option>
            </select>
        </div>
    );
};

Pagination.propTypes = {
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    setPage: PropTypes.func.isRequired,
    setLimit: PropTypes.func.isRequired,
};

export default Pagination;
