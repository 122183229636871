import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import * as Filters from '@/components/metrics-account/filters';
import { reduceFilters, matchAgainstCachedFilters, initFilters, updateFilters } from '@/services/filters';
import { clearTimezoneFromDate } from '@/util/format';
import { SelectMultiple } from '@/components/ui/FormFields';
import { Boolean } from '@/components/ui/FormFields';

const ReportFilters = ({ account, filtersKey, options }) => {
    // Reducers
    const [filters, dispatchFilters] = useReducer(
        reduceFilters,
        matchAgainstCachedFilters(filtersKey, {
            platforms: [],
            periodA: {
                startTime: clearTimezoneFromDate(new Date(new Date().setHours(0, 0, 0, 0))).getTime(),
                endTime: clearTimezoneFromDate(new Date(new Date().setHours(0, 0, 0, 0))).getTime(),
                presetID: 'this-month',
                presetLabel: '',
            },
            compareEnabled: false,
            periodB: {
                startTime: clearTimezoneFromDate(new Date(new Date().setHours(0, 0, 0, 0))).getTime(),
                endTime: clearTimezoneFromDate(new Date(new Date().setHours(0, 0, 0, 0))).getTime(),
                presetID: 'previous-period',
                presetLabel: '',
            },
            search: '',
            keywords: [],
        }),
        initFilters
    );

    // On Filter Change
    const onFilterChange = (fieldID, value) => {
        dispatchFilters({ type: 'update', payload: { fieldID: fieldID, value: value } });
    };

    // On Apply Filters
    const onApplyFilters = () => {
        updateFilters(filtersKey, filters);
    };

    return (
        <div className='reportFilters reportFilterGroup'>
            <Filters.FilterWrapper>
                <strong>Keywords</strong>
                <SelectMultiple
                    field={{
                        options: options.keywordOptions,
                        value: filters.keywords,
                        onChange: (name, value) => onFilterChange('keywords', value),
                        summaryLabels: {
                            blank: 'Select Keywords',
                            multiple: '{count} Keywords Selected',
                            all: 'All Keywords',
                        },
                    }}
                />
            </Filters.FilterWrapper>

            <Filters.FilterWrapper>
                <strong>Search Text</strong>
                <Filters.TextInput value={filters.search} onChange={(value) => onFilterChange('search', value)} />
            </Filters.FilterWrapper>

            <Filters.FilterWrapper>
                <strong>Platforms</strong>
                <SelectMultiple
                    field={{
                        options: account.getPlatformOptions(),
                        value: filters.platforms,
                        onChange: (name, value) => onFilterChange('platforms', value),
                        summaryLabels: {
                            blank: 'Select Platforms',
                            multiple: '{count} Platforms Selected',
                            all: 'All Platforms',
                        },
                    }}
                />
            </Filters.FilterWrapper>

            <Filters.FilterWrapper>
                <>
                    <strong>Date Range:</strong> {filters.periodA.presetLabel}
                </>
                <Filters.DateRange dateRange={filters.periodA} onChange={(value) => onFilterChange('periodA', value)} />
            </Filters.FilterWrapper>

            <Filters.FilterWrapper>
                <>
                    <Boolean field={{ value: filters.compareEnabled, onChange: (name, value) => onFilterChange('compareEnabled', value) }} />
                    <strong>Compare Range:</strong> {filters.periodB.presetLabel}
                </>
                <Filters.DateRange
                    dateRange={filters.periodB}
                    relativeDateRange={filters.periodA}
                    onChange={(value) => onFilterChange('periodB', value)}
                />
            </Filters.FilterWrapper>

            <Filters.FilterWrapper>
                <button className='btnApplyFilters' onClick={onApplyFilters}>
                    Generate
                </button>
            </Filters.FilterWrapper>
        </div>
    );
};

ReportFilters.propTypes = {
    account: PropTypes.object.isRequired,
    filtersKey: PropTypes.string.isRequired,
    options: PropTypes.object,
};

export default ReportFilters;
