import React from 'react';
import { Account } from '@/components/metrics-account/classes';
import ViewRouter from '@/components/screens/router/ViewRouter/ViewRouter';
import DropdownMenu from '@/components/screens/ui/DropdownMenu/DropdownMenu';
import { ViewReportKeywordTracking, ViewReportPostModeration } from '@/components/metrics-account/reports';
import { ViewSyncSettings, ViewSocialMediaQueries } from '@/components/metrics-account/settings';
import ScreenManageAccountUsers from '@/components/screens/settings/ScreenManageAccountUsers/ScreenManageAccountUsers';

class Account_Social_Media_Listening extends Account {
    constructor(account) {
        super(account);
    }

    getPlatformOptions = () => [
        {
            value: 'reddit',
            name: 'Reddit',
        },
    ];

    getSavedQueries = () => {
        const settings = this.getAccountSettings();
        return settings.hasOwnProperty('queries') ? settings.queries : [];
    };

    setQueries = async (data) => {
        return await this.setAccountSettings('queries', data);
    };

    getMainViews = () => [
        {
            endpoint: '',
            component: <div>Dashboard Placeholder</div>,
        },
        {
            endpoint: 'options/*',
            component: <ViewRouter views={this.getSettingsViews()} permissionsKey={this.slug} />,
        },
        {
            endpoint: 'reports/*',
            permissions: [
                {
                    type: 'userHasPolicies',
                    value: ['can-view-data'],
                },
            ],
            component: <ViewRouter views={this.getReportViews()} permissionsKey={this.slug} />,
        },
    ];

    getReportViews = () => [
        {
            id: 'keyword-tracking',
            endpoint: 'keyword-tracking',
            link: 'reports/keyword-tracking/',
            label: 'Keyword Tracking',
            permissions: [
                {
                    type: 'userHasPolicies',
                    value: ['can-view-data'],
                },
            ],
            component: <ViewReportKeywordTracking account={this} reportID='keywordTracking' />,
        },
        {
            id: 'post-moderation',
            endpoint: 'post-moderation',
            link: 'reports/post-moderation/',
            label: 'Post Moderation',
            permissions: [
                {
                    type: 'userHasPolicies',
                    value: ['can-view-data'],
                },
            ],
            component: <ViewReportPostModeration account={this} reportID='postModeration' />,
        },
    ];

    getSettingsViews = () => [
        {
            endpoint: 'user-permissions',
            link: 'options/user-permissions/',
            label: 'User Permissions',
            icon: 'fas fa-user-cog',
            permissions: [
                {
                    type: 'userHasPolicies',
                    value: ['can-edit-users'],
                },
            ],
            component: (
                <ScreenManageAccountUsers
                    handlers={{
                        getUsers: this.getUsers,
                        addUser: this.addUser,
                        removeUser: this.removeUser,
                        getAllUsersPermissions: this.getAllUsersPermissions,
                        setUserPermissions: this.setUserPermissions,
                    }}
                    policies={this.getPolicies()}
                />
            ),
        },
        {
            endpoint: 'query-social-media',
            link: 'options/query-social-media/',
            label: 'Query Social Media',
            icon: 'fas fa-comment',
            permissions: [
                {
                    type: 'userHasPolicies',
                    value: ['can-view-data', 'can-edit-integration-options'],
                },
            ],
            component: <ViewSocialMediaQueries account={this} />,
        },
        {
            endpoint: 'sync-configuration',
            link: 'options/sync-configuration/',
            label: 'Sync Configuration',
            icon: 'fas fa-clock-rotate-left',
            permissions: [
                {
                    type: 'userHasPolicies',
                    value: ['can-edit-sync-config'],
                },
            ],
            component: <ViewSyncSettings account={this} />,
        },
        {
            label: 'Sync Recent Data',
            icon: 'fas fa-sync-alt',
            permissions: [
                {
                    type: 'userHasPolicies',
                    value: ['can-quick-sync-data'],
                },
            ],
            action: async () => this.startDataSync(),
        },
    ];

    getMenuArea = () => {
        return (
            <>
                <DropdownMenu items={this.getReportViews()} label='Reports' permissionsKey={this.slug} />
                <DropdownMenu items={this.getSettingsViews()} label='Options' permissionsKey={this.slug} />
            </>
        );
    };

    getBodyArea = () => {
        return <ViewRouter views={this.getMainViews()} permissionsKey={this.slug} />;
    };
}

export default Account_Social_Media_Listening;
