import FormChannel from './channel/FormChannel';
import QuotaCalculatorChannel from './channel/QuotaCalculatorChannel';
import ResultsChannel from './channel/ResultsChannel';

import FormKeyword from './keyword/FormKeyword';
import QuotaCalculatorKeyword from './keyword/QuotaCalculatorKeyword';
import ResultsKeyword from './keyword/ResultsKeyword';

import FormSingleVideo from './single-video/FormSingleVideo';
import QuotaCalculatorSingleVideo from './single-video/QuotaCalculatorSingleVideo';
import ResultsSingleVideo from './single-video/ResultsSingleVideo';

import FormKeywordBatch from './keyword-batch/FormKeywordBatch';
import QuotaCalculatorKeywordBatch from './keyword-batch/QuotaCalculatorKeywordBatch';
import ResultsKeywordBatch from './keyword-batch/ResultsKeywordBatch';

export const modes = [
    {
        id: 'channel',
        title: 'Channel Scrape',
        QueryForm: FormChannel,
        QuotaCalculator: QuotaCalculatorChannel,
        Results: ResultsChannel,
    },
    {
        id: 'keyword',
        title: 'Keyword Search',
        QueryForm: FormKeyword,
        QuotaCalculator: QuotaCalculatorKeyword,
        Results: ResultsKeyword,
    },
    {
        id: 'keyword-batch',
        title: 'Keyword Batch Search',
        QueryForm: FormKeywordBatch,
        QuotaCalculator: QuotaCalculatorKeywordBatch,
        Results: ResultsKeywordBatch,
    },
    {
        id: 'single-video',
        title: 'Single Video',
        QueryForm: FormSingleVideo,
        QuotaCalculator: QuotaCalculatorSingleVideo,
        Results: ResultsSingleVideo,
    },
];
