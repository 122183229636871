export const LOGIN = '/login/';
export const LOGOUT = '/login/?logout';
export const FORGOT_PASSWORD = '/forgot-password/';
export const RESET_PASSWORD = '/reset-password/';

export const HOME_PAGE = '/';
export const DASHBOARD = '/dashboard/';

export const MANAGE_USERS = '/manage-users/';

export const CREATE_METRICS_ACCOUNT = '/create-metrics-account/';
export const METRICS_ACCOUNT_SINGLE = '/metrics-account/:slug/';

// Tools
export const YOUTUBE_PERFORMANCE_TOOL = '/tools/youtube-performance/';
export const EVENT_LOGGER = '/tools/event-logger/';

// System Pages
export const NOT_FOUND = '/404/';

// Test Pages
export const TEST_FORM_HANDLER = '/test-form-handler/';
