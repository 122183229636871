export const toolUid = 'youtube-performance-tool';

export const policies = [
    {
        name: 'Can View Reports',
        key: 'can-view-reports',
    },
    {
        name: 'Can Run Reports',
        key: 'can-run-reports',
    },
    {
        name: 'Can Delete Reports',
        key: 'can-delete-reports',
    },
    {
        name: 'Can Edit Settings',
        key: 'can-edit-settings',
    },
    {
        name: 'Can Manage Users',
        key: 'can-edit-users',
    },
];
