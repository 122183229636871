import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import BodySingleVideo from './modes/BodySingleVideo';
import BodyChannel from './modes/BodyChannel';
import BodyKeyword from './modes/BodyKeyword';
import BodyKeywordBatch from './modes/BodyKeywordBatch';
import Status from './Status';
import Actions from './Actions';
import { formatDateString } from '@/util/format';
import styles from './Report.module.scss';

const Report = ({ tool, report, selected = false }) => {
    // Deconstruct
    const { parameters, favorite, last_run, status } = report;

    // Handlers
    const onFavoriteClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const data = {
            favorite: !favorite,
        };
        tool.editReport(report.id, data);
    };

    const onDeleteReport = (e) => {
        e.stopPropagation();
        e.preventDefault();
        tool.deleteReport(report.id);
    };

    // Report body
    const ReportBody = () => {
        const mode = parameters.mode;
        switch (mode) {
            //TODO: Add report item body component for channel and keywords
            case 'single-video':
                return <BodySingleVideo report={report} />;
            case 'channel':
                return <BodyChannel report={report} />;
            case 'keyword':
                return <BodyKeyword report={report} />;
            case 'keyword-batch':
                return <BodyKeywordBatch report={report} />;
            default:
                return (
                    <div className='report-item invalid-report'>
                        <p>Invalid Report</p>
                    </div>
                );
        }
    };

    // Favorite
    const Favorite = () => {
        return (
            <div className='favorite'>
                <span className={favorite ? 'icon-true' : 'icon-false'} onClick={(e) => onFavoriteClick(e)}>
                    <i className='fa-solid fa-star' />
                </span>
            </div>
        );
    };

    return (
        <div className={styles.module}>
            <div className={classnames('wrapper', selected ? 'selected' : '')}>
                <ReportBody />
                <div className={classnames('statusArea', status)}>
                    <Status report={report} />
                    <Actions tool={tool} report={report} />
                </div>
                <div className='other'>
                    <Favorite />
                    <p>ID: {report.id}</p>
                    <p>Last run on {formatDateString(new Date(last_run))}</p>
                    <p className='delete' onClick={(e) => onDeleteReport(e)}>
                        Delete
                    </p>
                </div>
            </div>
        </div>
    );
};

Report.propTypes = {
    tool: PropTypes.object.isRequired,
    report: PropTypes.object.isRequired,
    selected: PropTypes.bool,
};

export default Report;
