import React from 'react';
import * as endpoints from '@/router/_endpoints';
import MenuList from '@/components/nav/partials/MenuList';
import { filterCompileMenuItems } from '@/components/nav/_navFunctions';

const MenuTopLeft = () => {
    // Menu model
    const menuItems = [
        {
            id: 'metricsAccounts',
            label: 'Metrics Accounts',
            hideDropdownOnClickOutside: true,
            roleAccess: ['admin'],
            submenu: [
                {
                    id: 'create-metrics-accounts',
                    label: 'Create New Account',
                    link: endpoints.CREATE_METRICS_ACCOUNT,
                    roleAccess: ['admin'],
                },
            ],
        },
        {
            id: 'users',
            label: 'Users',
            hideDropdownOnClickOutside: true,
            roleAccess: ['admin'],
            submenu: [
                {
                    id: 'manage-users',
                    label: 'Manage Users',
                    link: endpoints.MANAGE_USERS,
                },
            ],
        },
    ];

    const filteredItems = filterCompileMenuItems(menuItems);

    return <MenuList menuItems={filteredItems} />;
};

export default MenuTopLeft;
