import React from 'react';
import PropTypes from 'prop-types';
import { secondsToMs, webarchiveSnapshotTimestampToDate } from '@/util/format';
import { formatDateString, formatNumberWithCommas, percentageIncreaseDecrease } from '@/util/format';
import { Text, Link, View, Image, StyleSheet } from '@react-pdf/renderer';

const ReportItemPDF = ({ item, index = null, showDescription = false }) => {
    const { status, status_message } = item;

    // Placehold for invalid item
    const InvalidItem = () => {
        return (
            <View style={[styles.reportItem, styles.reportItem.invalid[status]]}>
                <Text style={[styles.statusMessage, styles.statusMessage[status]]}>{status_message}</Text>
            </View>
        );
    };

    if (status !== 'done' || !item.data) {
        return <InvalidItem />;
    }

    // Deconstruct item
    const { video, channel, analytics } = item.data;

    if (!video || !channel || !analytics) {
        return <InvalidItem />;
    }

    let formattedDate = null;

    if (analytics.snapshotTimestamp) {
        const snapshotDate = webarchiveSnapshotTimestampToDate(analytics.snapshotTimestamp);
        formattedDate = formatDateString(snapshotDate);
    } else {
        formattedDate = 'n/a';
    }

    const currentViewCount = analytics.currentViewCount;
    const snapshotViewCount = analytics.snapshotViewCount;
    const viewCountChange = currentViewCount - snapshotViewCount;
    const monthlyAverageViews = analytics.monthlyAverageViews;
    const percentageIncrease = snapshotViewCount
        ? formatNumberWithCommas(percentageIncreaseDecrease(snapshotViewCount, currentViewCount)) + '%'
        : 'n/a';
    const publishDate = new Date(video.publishedAt);

    const formattedViewCount = currentViewCount ? formatNumberWithCommas(currentViewCount) : 'n/a';
    const formattedSelectedSnapViewCount = snapshotViewCount ? formatNumberWithCommas(snapshotViewCount) : 'n/a';
    const formattedViewCountChange = viewCountChange ? formatNumberWithCommas(viewCountChange) : 'n/a';
    const formattedMonthlyAverageViews = monthlyAverageViews ? formatNumberWithCommas(monthlyAverageViews) : 'n/a';

    // Thumbnail through CORS proxy server
    const thumbnailUrl = 'https://corsproxy.io/?' + encodeURIComponent(video.thumbnailUrl);

    return (
        <View style={styles.reportItem} wrap={false}>
            <View style={styles.videoHeader}>
                <Text style={styles.heading}>
                    {index ? <Text style={styles.index}>{index}. </Text> : ''}
                    <Link style={styles.link} href={video.url} target='_blank'>
                        {video.title}
                    </Link>
                </Text>
            </View>

            <View style={styles.videoMetaWrapper}>
                <View style={styles.thumbnailWrapper}>
                    <Image style={styles.videoThumb} src={thumbnailUrl} />
                </View>
                <View style={styles.metaDetailsWrapper}>
                    <View style={styles.tableMeta}>
                        <Text style={styles.tableMeta.cellHeader}>Channel</Text>
                        <Text style={styles.tableMeta.cellData}>
                            <Link style={styles.link} href={channel.url} target='_blank'>
                                {channel.name}
                            </Link>
                        </Text>

                        <Text style={styles.tableMeta.cellHeader}>Channel ID</Text>
                        <Text style={styles.tableMeta.cellData}>{channel.id}</Text>

                        <Text style={styles.tableMeta.cellHeader}>Video ID</Text>
                        <Text style={styles.tableMeta.cellData}>{video.id}</Text>

                        <Text style={styles.tableMeta.cellHeader}>Duration</Text>
                        <Text style={styles.tableMeta.cellData}>{secondsToMs(video.duration)}</Text>

                        <Text style={styles.tableMeta.cellHeader}>Publish Date</Text>
                        <Text style={styles.tableMeta.cellData}>{formatDateString(publishDate)}</Text>

                        {item.group && (
                            <>
                                <Text style={styles.tableMeta.cellHeader}>Group</Text>
                                <Text style={styles.tableMeta.cellData}>{item.group}</Text>
                            </>
                        )}
                    </View>
                </View>
            </View>

            <View style={styles.videoStatsWrapper}>
                <View style={styles.tableStats}>
                    <View style={styles.tableStats.rowHeader}>
                        <Text style={styles.tableStats.cellHeader}>Closest Snapshot</Text>
                        <Text style={styles.tableStats.cellHeader}>Current Views</Text>
                        <Text style={styles.tableStats.cellHeader}>Historic Views</Text>
                        <Text style={styles.tableStats.cellHeader}>Change in Views</Text>
                        <Text style={styles.tableStats.cellHeader}>Average Views/Month</Text>
                        <Text style={styles.tableStats.cellHeader}>% Increase (since Snapshot)</Text>
                    </View>

                    <View style={styles.tableStats.rowData}>
                        <Text style={styles.tableStats.cellData}>{formattedDate}</Text>
                        <Text style={styles.tableStats.cellData}>{formattedViewCount}</Text>
                        <Text style={styles.tableStats.cellData}>{formattedSelectedSnapViewCount}</Text>
                        <Text style={styles.tableStats.cellData}>{formattedViewCountChange}</Text>
                        <Text style={styles.tableStats.cellData}>{formattedMonthlyAverageViews}</Text>
                        <Text style={styles.tableStats.cellData}>{percentageIncrease}</Text>
                    </View>
                </View>
            </View>

            {showDescription && (
                <View style={styles.videoDescription}>
                    <Text>Description: </Text>
                    <Text>{video.description}</Text>
                </View>
            )}
        </View>
    );
};

ReportItemPDF.propTypes = {
    item: PropTypes.object.isRequired,
    index: PropTypes.number,
    showDescription: PropTypes.bool,
};

export default ReportItemPDF;

// Create styles
const styles = StyleSheet.create({
    reportItem: {
        marginBottom: 10,
        invalid: {
            error: {
                border: '1px solid #cc5252',
            },
            retry: {
                border: '1px solid #cca364',
            },
        },
    },
    statusMessage: {
        fontSize: 12,
        padding: 5,
        error: {
            color: '#cc5252',
        },
        retry: {
            color: '#cca364',
        },
    },
    videoHeader: {
        marginBottom: 5,
    },
    heading: {
        fontSize: 12,
        color: '#0289cc',
    },
    link: {
        color: '#0289cc',
        //textDecoration: 'none',
    },
    index: {
        color: '#000000',
    },
    videoMetaWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        columnGap: 15,
        marginBottom: 5,
    },
    thumbnailWrapper: {
        flexDirection: 'column',
        width: '30%',
    },
    videoThumb: {
        width: 320 * 0.6,
        height: 180 * 0.6,
        borderRadius: 5,
    },
    metaDetailsWrapper: {
        flexDirection: 'column',
        width: '60%',
    },
    tableMeta: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        columnGap: 5,

        cellHeader: {
            padding: 3,
            fontSize: 10,
            fontWeight: 'bold',
            width: '25%',
        },
        cellData: {
            padding: 3,
            fontSize: 10,
            width: '65%',
        },
    },
    videoStatsWrapper: {
        flexDirection: 'row',
        columnGap: 15,
    },
    tableStats: {
        width: '100%',
        rowHeader: {
            flexDirection: 'row',
            justifyContent: 'space-around',
            backgroundColor: '#026ea3',
        },
        rowData: {
            flexDirection: 'row',
            justifyContent: 'space-around',
            backgroundColor: '#fafafa',
        },
        cellHeader: {
            flex: 1,
            fontSize: 10,
            padding: 3,
            color: 'white',
            textAlign: 'center',
            alignSelf: 'center',
        },
        cellData: {
            flex: 1,
            fontSize: 10,
            padding: 3,
            textAlign: 'center',
            alignSelf: 'center',
        },
    },
    videoDescription: {
        fontSize: 10,
        padding: 5,
    },
});
