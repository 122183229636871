import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./Loader.module.scss";
import PageWrapper from "@/pages/PageWrapper";

const Loader = ({size = "small"}) => {
    return (
        <PageWrapper pageClass={classnames("pageLoader", styles.module)}>
            <span className={classnames("loadingIcon", size)}>
                <i className="fas fa-compact-disc fa-spin"/>
            </span>
        </PageWrapper>
    );
};

Loader.propTypes = {
    size: PropTypes.string
}

export default Loader;