import { createSlice } from '@reduxjs/toolkit';
import { actionResetStore } from '../actions';

const initialState = [];
const blankValue = [];

const slice = createSlice({
    name: 'logEntries',
    initialState: initialState,
    reducers: {
        fetchedLogGroups: (state, action) => {
            const index = state.findIndex((object) => {
                return object.key === action.payload.key;
            });
            if (index === -1) {
                state.push({ key: action.payload.key, groups: action.payload.groups, data: [] });
            } else {
                state[index] = { ...state[index], groups: action.payload.groups };
            }
            return state;
        },
        fetchedLogEntries: (state, action) => {
            const index = state.findIndex((object) => {
                return object.key === action.payload.key;
            });
            if (index === -1) {
                state.push({ key: action.payload.key, data: action.payload.data });
            } else {
                state[index] = { ...state[index], data: action.payload.data };
            }
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(actionResetStore, (state, action) => {
            return initialState;
        });
    },
});

// Actions
export const { fetchedLogGroups, fetchedLogEntries, deletedLogEntry } = slice.actions;

// Reducer
export default slice.reducer;

// Selectors
export const selectorLogGroups = (state, key) => {
    const entry = state.logEntries.find((object) => {
        return object.key === key;
    });
    return entry && entry.groups ? entry.groups : blankValue;
};

export const selectorLogEntries = (state, key) => {
    const entry = state.logEntries.find((object) => {
        return object.key === key;
    });
    return entry && entry.data ? entry.data : blankValue;
};
