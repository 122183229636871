export const syncNowFormFields = [
    {
        name: "type",
        label: "Type",
        type: "select",
        required: true,
        options: [
            {name: "Full Sync", value: "full"},
            {name: "Partial Sync", value: "partial"}
        ]
    },
    {
        name: "hours",
        label: "Last Hours to Sync",
        type: "number",
        min: 1,
        defaultValue: 12,
        conditions: [{name: "type", value: "partial"}]
    },
    {
        name: "cleanSync",
        label: "Delete Old Entries",
        type: "boolean",
        conditions: [{name: "type", value: "full"}]
    },
    {
        name: "submit",
        value: "Sync Now",
        type: "submit"
    },
]

export const setDefaultSyncFields = [
    {
        name: "type",
        label: "Type",
        type: "select",
        required: true,
        options: [
            {name: "Full Sync", value: "full"},
            {name: "Partial Sync", value: "partial"}
        ]
    },
    {
        name: "hours",
        label: "Last Hours to Sync",
        type: "number",
        min: 1,
        defaultValue: 12,
        conditions: [{name: "type", value: "partial"}]
    },
    {
        name: "cleanSync",
        label: "Delete Old Entries",
        type: "boolean",
        conditions: [{name: "type", value: "full"}]
    },
    {
        name: "submit",
        value: "Set Default Sync",
        type: "submit"
    },
]

export const createCronJobFields = [
    {
        name: "cronTime",
        label: "Frequency",
        type: "select",
        required: true,
        options: [
            {name: "Every Hour", value: "0 */1 * * *"},
            {name: "Every 6 Hours", value: "0 */6 * * *"},
            {name: "Every 12 Hours", value: "0 */12 * * *"},
            {name: "Every Day", value: "0 0 */1 * *"},
            {name: "Every Week", value: "0 0 * * 0"},
            {name: "Every Month", value: "0 0 1 * *"},
        ]
    },
    {
        name: "type",
        label: "Type",
        type: "select",
        required: true,
        options: [
            {name: "Full Sync", value: "full"},
            {name: "Partial Sync", value: "partial"}
        ]
    },
    {
        name: "hours",
        label: "Last Hours to Sync",
        type: "number",
        min: 1,
        defaultValue: 12,
        conditions: [{name: "type", value: "partial"}]
    },
    {
        name: "cleanSync",
        label: "Delete Old Entries",
        type: "boolean",
        conditions: [{name: "type", value: "full"}]
    },
    {
        name: "submit",
        value: "Create Cron Job",
        type: "submit"
    },
]