import store from '@/store';
import { filtersUpdated } from '@/store/slices/filters';

// Update filters in store
export const updateFilters = (key, data) => {
    store.dispatch(filtersUpdated({ key: key, data: data }));
    cacheFilters(key, data);
};

// Init filters
export const initFilters = (initialFilters) => {
    return initialFilters;
};

// Reduce filters
export const reduceFilters = (state, action) => {
    switch (action.type) {
        case 'update':
            return { ...state, [action.payload.fieldID]: action.payload.value };
        default:
            return { ...state };
    }
};

// Match filters against cached filters
export const matchAgainstCachedFilters = (key, defaultFilters) => {
    const cachedFiltersString = localStorage.getItem(key);
    if (!cachedFiltersString) return defaultFilters;

    const cachedFilters = JSON.parse(cachedFiltersString);
    const matchedFilters = { ...defaultFilters };

    const filterKeys = Object.keys(matchedFilters);

    filterKeys.forEach((key) => {
        matchedFilters[key] = cachedFilters[key] ? cachedFilters[key] : matchedFilters[key];
    });

    return matchedFilters;
};

// Cache filters in local storage
export const cacheFilters = (key, filters) => {
    localStorage.setItem(key, JSON.stringify(filters));
};