import React, { useLayoutEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './ReportKeywordTracking.module.scss';
import commonStyles from '@/components/metrics-account/reports/CommonStyles.module.scss';
import {
    filterEndDate,
    filterStartDate,
    generateKeywordsSummary,
    generateKeywordChartEntries,
    getKeywordsFromQueriesSetting,
} from '../_socialMediaPosts';
import { createFormData } from '../_common';
import { useSelector } from 'react-redux';
import { selectorDataset } from '@/store/slices/datasets';
import { selectorFilters } from '@/store/slices/filters';
import { useIsMounted } from '@/util/hooks';
import { DropdownPanel, LoadingIcon } from '@/components/ui';
import classnames from 'classnames';
import ReportFilters from './partials/ReportFilters';
import ReportChart from './partials/ReportChart';
import { createSocialMediaPostsQueryFromFilters, validateSocialMediaPostsQuery } from '../_socialMediaPosts';
import SummaryStats from './partials/SummaryStats';

const ReportKeywordTracking = ({ account, reportID }) => {
    const datasetPostsID_A = `${account.slug}/${reportID}/posts_a`;
    const datasetPostsID_B = `${account.slug}/${reportID}/posts_b`;
    const filtersID = `${account.slug}/${reportID}`;

    // State
    const [fetching, setFetching] = useState(false);
    const [showFilters, setShowFilters] = useState(true);
    const [userMessage, setUserMessage] = useState({});

    // Selectors
    const datasetPostsA = useSelector((state) => selectorDataset(state, datasetPostsID_A));
    const datasetPostsB = useSelector((state) => selectorDataset(state, datasetPostsID_B));
    const filters = useSelector((state) => selectorFilters(state, filtersID));

    // Hooks
    const isMounted = useIsMounted();

    // Const
    const accountQueries = useMemo(() => account.getSavedQueries(), [account]);

    // Hook: Get Posts Dataset A
    useLayoutEffect(() => {
        if (Object.keys(filters).length <= 0) return;

        const sanitizedFilters = {
            platforms: filters.platforms,
            search: filters.search,
            keywords: filters.keywords,
            period: filters.periodA,
        };

        const socialMediaPostsQuery = createSocialMediaPostsQueryFromFilters(sanitizedFilters);
        const queryValidation = validateSocialMediaPostsQuery(socialMediaPostsQuery);

        if (queryValidation.valid) {
            const formData = createFormData(socialMediaPostsQuery);
            setFetching(true);
            setUserMessage({ className: 'pending', message: queryValidation.message });
            account.getSocialMediaPosts(formData).then((response) => {
                account.saveDataset(datasetPostsID_A, response);
                isMounted() && setFetching(false);
                isMounted() && setUserMessage({});
            });
        } else {
            setUserMessage({ className: 'error', message: queryValidation.message });
        }
    }, [filters]);

    // Hook: Get Posts Dataset B
    useLayoutEffect(() => {
        if (Object.keys(filters).length <= 0) return;
        if (!filters.compareEnabled) return;

        const sanitizedFilters = {
            platforms: filters.platforms,
            search: filters.search,
            keywords: filters.keywords,
            period: filters.periodB,
        };

        const socialMediaPostsQuery = createSocialMediaPostsQueryFromFilters(sanitizedFilters);
        const queryValidation = validateSocialMediaPostsQuery(socialMediaPostsQuery);

        if (queryValidation.valid) {
            const formData = createFormData(socialMediaPostsQuery);
            setFetching(true);
            setUserMessage({ className: 'pending', message: queryValidation.message });
            account.getSocialMediaPosts(formData).then((response) => {
                account.saveDataset(datasetPostsID_B, response);
                isMounted() && setFetching(false);
                isMounted() && setUserMessage({});
            });
        } else {
            setUserMessage({ className: 'error', message: queryValidation.message });
        }
    }, [filters]);

    // Memo: Keyword Options
    const keywordOptions = useMemo(() => {
        const keywords = getKeywordsFromQueriesSetting(accountQueries);
        return keywords.map((keyword) => {
            return {
                value: keyword,
                name: keyword,
            };
        });
    }, [accountQueries]);

    // Memo: Selected Keywords
    const selectedKeywords = useMemo(() => {
        const keywords = filters.keywords ? [...filters.keywords] : [];
        if (filters.search) {
            keywords.push(filters.search);
        }
        return keywords;
    }, [filters]);

    // Memo: Generate Keyword Summary A
    const keywordsSummaryA = useMemo(() => {
        return generateKeywordsSummary(datasetPostsA, selectedKeywords);
    }, [datasetPostsA]);

    // Memo: Generate Keyword Summary B
    const keywordsSummaryB = useMemo(() => {
        return generateKeywordsSummary(datasetPostsB, selectedKeywords);
    }, [datasetPostsB]);

    // Hook: Generate Chart Data A
    const chartEntriesA = useMemo(() => {
        if (filters.periodA) {
            const startTime = filterStartDate(filters.periodA.startTime).getTime();
            const endTime = filterEndDate(filters.periodA.endTime).getTime();
            return generateKeywordChartEntries(datasetPostsA, startTime, endTime, 12, 0, selectedKeywords);
        } else {
            return [];
        }
    }, [datasetPostsA]);

    // Hook: Generate Chart Data B
    const chartEntriesB = useMemo(() => {
        if (filters.periodB) {
            const startTime = filterStartDate(filters.periodB.startTime).getTime();
            const endTime = filterEndDate(filters.periodB.endTime).getTime();
            return generateKeywordChartEntries(datasetPostsB, startTime, endTime, 12, 0, selectedKeywords);
        } else {
            return [];
        }
    }, [datasetPostsB]);

    // Combine keyword summaries
    const keywordSummaries = [];
    if (filters.periodA) {
        keywordSummaries.push(keywordsSummaryA);
    }
    if (filters.compareEnabled && filters.periodB) {
        keywordSummaries.push(keywordsSummaryB);
    }

    return (
        <div className={classnames(commonStyles.module, styles.module)}>
            {/* Header */}
            <div className='viewHeader'>
                <h3>Keyword Tracking</h3>
                <div className='headerControls'>
                    <p className='showFilters' onClick={() => setShowFilters(!showFilters)}>
                        <i className='fas fa-filter' /> Filters
                    </p>
                </div>
                <div className='fetchingStatus'>{fetching && <LoadingIcon />}</div>
                <div className='userMessage'>{userMessage.message && <p className={userMessage.className}>{userMessage.message}</p>}</div>
            </div>

            {/* Filters */}
            <DropdownPanel show={showFilters} hideButton={true}>
                <ReportFilters
                    account={account}
                    filtersKey={filtersID}
                    options={{
                        keywordOptions: keywordOptions,
                    }}
                />
            </DropdownPanel>

            {/*/!* Report Stats *!/*/}

            {/* Summary Stats */}
            <SummaryStats keywordsSummaries={keywordSummaries} periods={[filters.periodA, filters.periodB]} selectedKeywords={selectedKeywords} />

            {chartEntriesA && <ReportChart chartEntries={chartEntriesA} keywords={selectedKeywords} />}
            {filters.compareEnabled && chartEntriesB && <ReportChart chartEntries={chartEntriesB} keywords={selectedKeywords} />}
        </div>
    );
};

ReportKeywordTracking.propTypes = {
    account: PropTypes.object.isRequired,
    reportID: PropTypes.string.isRequired,
};

export default ReportKeywordTracking;
