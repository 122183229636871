import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectorReport } from '@/store/slices/reports';
import { selectorReportItems } from '@/store/slices/reportItems';
import { PDFViewer } from '@react-pdf/renderer';
import PDFDocument from '../../partials/export/PDFDocument';

const PreviewReportPDF = ({ tool }) => {
    // Get report ID from URL
    const urlParams = useParams();
    const reportID = parseInt(urlParams.reportID);

    // Effect
    useEffect(() => {
        tool.getReports();
        tool.getReportItems(reportID);
    }, []);

    const report = useSelector((state) => selectorReport(state, tool.uid, reportID));
    const reportItems = useSelector((state) => selectorReportItems(state, reportID));

    if (!report) return null;

    return (
        <PDFViewer style={{ width: '100%', height: '100vh' }}>
            <PDFDocument report={report} reportItems={reportItems} heading='Preview Report' />
        </PDFViewer>
    );
};

export default PreviewReportPDF;
