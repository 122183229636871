import { createSlice } from '@reduxjs/toolkit';
import { actionResetStore } from '../actions';

const initialState = {
    appReady: false,
    currentPath: '/',
};

const slice = createSlice({
    name: 'app',
    initialState: initialState,
    reducers: {
        appReady: (state, action) => {
            state.appReady = true;
        },
        appPathChanged: (state, action) => {
            state.currentPath = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(actionResetStore, (state, action) => {
            return { ...initialState, appReady: true };
        });
    },
});

// Actions
export const { appReady, appPathChanged } = slice.actions;

// Reducer
export default slice.reducer;
