import React, { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { clearUserAuthentication } from '@/services/strapi/auth';
import LoginForm from '@/components/forms/account/LoginForm';
import * as endpoints from '@/router/_endpoints';
import PageWrapper from '@/pages/PageWrapper';
import { loadAppResources } from '@/services/app';
import classnames from 'classnames';
import styles from './Account.module.scss';

const Login = () => {
    // useLocation
    const location = useLocation();

    // Get current user from store
    const user = useSelector((state) => state.auth.user);

    // Get redirect path after login (either previous page or home page)
    const redirectPath = location.state ? location.state.from.pathname : endpoints.HOME_PAGE;

    // Get query parameters
    const queryParams = Object.fromEntries(new URLSearchParams(location.search).entries());

    useLayoutEffect(() => {
        // Check for "logout" query parameter
        if (queryParams['logout'] !== undefined) {
            clearUserAuthentication();
        }
    }, [location]);

    // If user is logged in, redirect to selected path
    if (user) {
        return <Navigate to={redirectPath} />;
    }

    // Handler login attempts
    const onLogin = () => {
        loadAppResources();
    };

    return (
        <PageWrapper pageClass={classnames('pageLogin', styles.module)}>
            <div className='contentWrapper'>
                {queryParams['confirmed'] !== undefined && <p className='confirmationMsg'>Your account has been confirmed, please log in.</p>}
                <LoginForm onLogin={onLogin} />
            </div>
        </PageWrapper>
    );
};

export default Login;
