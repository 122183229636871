import React from 'react';
import { Link } from 'react-router-dom';
import FormHandler from '@/components/ui/FormHandler/FormHandler';
import { userForgetPasswordFields } from './_formFields';
import { sendPasswordRecoveryEmail } from '@/services/strapi/auth';
import * as endpoints from '@/router/_endpoints';

const ForgetPasswordForm = () => {
    // Handler Form Submit
    const onSubmit = async (data) => {
        const identifier = data.find((input) => input.name === 'identifier').value;
        const res = await sendPasswordRecoveryEmail(identifier);

        if (res && !res.error) {
            return {
                status: 'success',
                message: 'All Good. Please check your email.',
                clearFields: true,
            };
        } else {
            return {
                status: 'error',
                message: 'Something went wrong. Please try Again.',
                clearFields: true,
            };
        }
    };

    return (
        <>
            <FormHandler
                fields={userForgetPasswordFields}
                formOptions={{
                    formClass: 'formStyleA',
                }}
                callbacks={{ onSubmit: onSubmit }}
            />
            <p>
                <Link to={endpoints.LOGIN}>Log In</Link>
            </p>
        </>
    );
};

export default ForgetPasswordForm;
