import React from 'react';
import PropTypes from 'prop-types';
import ProgressBar from '../ProgressBar';
import { formatNumberWithCommas, approximateIntegerWithPrefix } from '@/util/format';

const BodyChannel = ({ report }) => {
    // Deconstruct
    const { parameters, results } = report;

    const Snippet = () => {
        // Results data
        const channel = results && results.channel ? results.channel : null;
        const channelName = channel ? channel.name : 'Fetching channel...';
        const channelVideoCount = channel ? channel.videoCount : '-';
        const channelSubCount = channel ? channel.subscriberCount : '-';

        return (
            <div className='snippet'>
                <p className='snippterHeader'>
                    <span className='snipptTitle'>{channelName}</span>
                    <ProgressBar report={report} />
                </p>
                <p className='snippetInfo'>
                    Vids: {formatNumberWithCommas(channelVideoCount)}
                    <span className='delimiter'>|</span>
                    Subs: {approximateIntegerWithPrefix(channelSubCount)}
                </p>
            </div>
        );
    };

    const Stats = () => {
        let modeString = parameters.scrapeMode;
        switch (parameters.scrapeMode) {
            case 'most-recent':
                modeString = 'Most Recent';
                break;
            case 'most-popular':
                modeString = 'Most Popular';
                break;
            default:
                break;
        }

        return (
            <p className='stats'>
                <span>Channel</span>
                <span className='delimiter'>|</span>
                <span>
                    {parameters.videosNumber} {modeString}
                </span>
            </p>
        );
    };

    return (
        <>
            <Snippet />
            <Stats />
        </>
    );
};

BodyChannel.propTypes = {
    report: PropTypes.object.isRequired,
};

export default BodyChannel;
