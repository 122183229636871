import { createSlice } from '@reduxjs/toolkit';
import { actionResetStore } from '../actions';

// Sanitized entity
export const sanitizeEntity = (entity) => {
    if (entity.attributes) {
        return {
            id: entity.id,
            slug: entity.attributes.slug,
            accountName: entity.attributes.accountName,
            syncing: entity.attributes.syncing,
            settings: entity.attributes.settings,
            config: entity.attributes.config,
        };
    } else {
        return {
            id: entity.id,
            slug: entity.slug,
            accountName: entity.accountName,
            syncing: entity.syncing,
            settings: entity.settings,
            config: entity.config,
        };
    }
};

const initialState = [];

const slice = createSlice({
    name: 'accounts',
    initialState: initialState,
    reducers: {
        fetchedAll: (state, action) => {
            return action.payload;
        },
        fetchedOne: (state, action) => {
            const index = state.findIndex((entity) => {
                return entity.id === action.payload.id;
            });
            if (index === -1) {
                state.push(action.payload);
            } else {
                state[index] = action.payload;
            }
            return state;
        },
        deletedOne: (state, action) => {
            return state.filter((entity) => {
                return entity.id !== action.payload.id;
            });
        },
    },
    extraReducers: (builder) => {
        builder.addCase(actionResetStore, (state, action) => {
            return initialState;
        });
    }
});

// Actions
export const { fetchedAll, fetchedOne, deletedOne } = slice.actions;

// Reducer
export default slice.reducer;

// Selectors
export const selectorAccountBySlug = (state, slug) => {
    return state.accounts.find((entry) => entry.slug === slug);
};
