import * as api from '@/services/strapi/rest';
import { logError } from '@/util/debugger';

/** Add Account User */
export const addAccountUser = async (uid, body = new FormData()) => {
    const endpoint = `${uid}/add-user/`;
    const response = await api.restStrapiPutRequest(endpoint, true, body);
    if (response.error) {
        logError(response);
        return false;
    } else {
        return response;
    }
};

/** Remove Account User */
export const removeAccountUser = async (uid, body = new FormData()) => {
    const endpoint = `${uid}/remove-user/`;
    const response = await api.restStrapiPutRequest(endpoint, true, body);
    if (response.error) {
        logError(response);
        return false;
    } else {
        return response;
    }
};

/** Get Account Users */
export const getAccountUsers = async (uid) => {
    const endpoint = `${uid}/users/`;
    const response = await api.restStrapiGetRequest(endpoint, true);
    if (response.error) {
        logError(response);
        return [];
    } else {
        return response;
    }
};
