import React from 'react';
import PropTypes from 'prop-types';

const ProgressBar = ({ report }) => {
    const { status, results } = report;

    // Stats
    const reportStats = results && results.stats ? results.stats : null;
    const itemsTotal = reportStats ? reportStats.total : 0;
    const itemsDone = reportStats ? reportStats.done : 0;
    const itemsRetry = reportStats ? reportStats.retry : 0;
    const itemsError = reportStats ? reportStats.error : 0;
    const reportSuccess = itemsDone === itemsTotal;

    let icon = null;
    switch (status) {
        case 'init':
            icon = <i className='fas fa-circle-notch fa-spin' />;
            break;
        case 'pending':
            icon = <i className='fas fa-clock' />;
            break;
        case 'processing':
            icon = <i className='fas fa-circle-notch fa-spin' />;
            break;
        case 'error':
            icon = <i className='fa-solid fa-xmark' />;
            break;
        case 'completed':
            icon = <i className='fa-solid fa-check' />;
            break;
        case 'stopped':
            icon = <i className='fa-solid fa-pause' />;
            break;
        case 'timeoout':
            icon = <i className='fa-solid fa-timer' />;
            break;
        default:
            icon = <i className='fa-solid fa-xmark' />;
            break;
    }

    return (
        <span className={`progressBar ${status}`}>
            <span className='icon'>{icon}</span>
            <span className='count'>{itemsDone}</span>
            {itemsRetry > 0 && <span className='delimiter'>|</span>}
            {itemsRetry > 0 && <span className='count retry'>{itemsRetry}</span>}
            {itemsError > 0 && <span className='delimiter'>|</span>}
            {itemsError > 0 && <span className='count error'>{itemsError}</span>}
        </span>
    );
};

ProgressBar.propTypes = {
    report: PropTypes.object.isRequired,
};

export default ProgressBar;
