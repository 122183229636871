import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import FormHandler from '@/components/ui/FormHandler/FormHandler';
import { createUser, getAllUsers } from '@/services/strapi/users';
import { sendConfirmationEmail } from '@/services/strapi/auth';
import { userCreateFields } from './_formFields';
import { toFormData } from '@/util/forms';

const UserCreateForm = ({ roles = [] }) => {
    // Add roles to field
    const fields = useMemo(() => {
        const roleOptions = roles
            .filter((role) => role.type !== 'public')
            .map((role) => {
                return {
                    name: `${role.name}: ${role.description}`,
                    value: role.id,
                };
            });

        return userCreateFields.map((field) => {
            if (field.name === 'role') {
                return { ...field, options: roleOptions };
            } else {
                return field;
            }
        });
    }, [roles]);

    // On form submit
    const onSubmit = async (data) => {
        const emailField = data.find((field) => field.name === 'email');
        const confirmedField = data.find((field) => field.name === 'confirmed');

        if (emailField) {
            data.push({
                name: 'username',
                value: emailField.value,
            });
        }

        const formData = toFormData(data);
        const res = await createUser(formData);

        if (res.error) {
            return {
                status: 'error',
                message: `Error: ${res.error}`,
                clearFields: false,
            };
        } else {
            getAllUsers().then();
            if (!confirmedField.value) {
                // Try and send confirmation email
                const emailRes = await sendConfirmationEmail(emailField.value);
                if (emailRes.error) {
                    return {
                        status: 'error',
                        message: 'User was created, but server failed to send email confirmation.',
                        clearFields: true,
                    };
                } else {
                    return {
                        status: 'success',
                        message: 'User Created. Confirmation email sent.',
                        clearFields: true,
                    };
                }
            } else {
                return {
                    status: 'success',
                    message: 'User was created.',
                    clearFields: true,
                };
            }
        }
    };

    return (
        <FormHandler
            fields={fields}
            formOptions={{
                formClass: 'formStyleA',
            }}
            callbacks={{
                onSubmit: onSubmit,
            }}
        />
    );
};

UserCreateForm.propTypes = {
    roles: PropTypes.array,
};

export default UserCreateForm;
