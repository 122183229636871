import React from 'react';
import PropTypes from 'prop-types';
import ReportItemPDF from '@/components/tools/YouTubePerformance/partials/ReportItemPDF';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

const PDFDocument = ({ report, reportItems = [], heading, startingIndex = 1 }) => {
    if (!report) return null;

    return (
        <Document>
            <Page size='A4' style={styles.page}>
                <View style={{ ...styles.section, ...styles.sectionHeader }}>
                    <Text style={styles.title}>{heading}</Text>
                    <Text style={styles.subtitle}>Report Date: {new Date(report.last_run).toLocaleString()}</Text>
                </View>
                <View style={{ ...styles.section, ...styles.sectionVideos }}>
                    {reportItems.map((item, index) => {
                        const itemIndex = startingIndex + index;
                        return <ReportItemPDF key={item.id} item={item} index={itemIndex} />;
                    })}
                </View>
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
            </Page>
        </Document>
    );
};

export default PDFDocument;

PDFDocument.propTypes = {
    report: PropTypes.object.isRequired,
    reportItems: PropTypes.array.isRequired,
    heading: PropTypes.string.isRequired,
    startingIndex: PropTypes.number,
};

// Create styles
const styles = StyleSheet.create({
    page: {
        paddingTop: 20,
        paddingBottom: 20,
        paddingHorizontal: 25,
    },
    section: {},
    sectionHeader: {
        alignContent: 'center',
        marginBottom: 10,
    },
    title: {
        fontSize: 16,
        textAlign: 'center',
        marginBottom: 5,
        color: '#0289cc',
    },
    subtitle: {
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 5,
        color: '#0289cc',
    },
    sectionVideos: {
        flexGrow: 1,
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 10,
        bottom: 15,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
});
