import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectorReport } from '@/store/slices/reports';
import { selectorReportItems } from '@/store/slices/reportItems';
import ReportItem from '../../partials/ReportItem';
import { LoadingIcon } from '@/components/ui';

const ResultsSingleVideo = ({ tool }) => {
    // Router
    const { reportId } = useParams();

    // State
    const [loading, setLoading] = useState(false);

    // Selectors
    const report = useSelector((state) => selectorReport(state, tool.uid, reportId));
    const reportItems = useSelector((state) => selectorReportItems(state, reportId));

    // Effect: Get report items
    useLayoutEffect(() => {
        if (!report) return;
        setLoading(true);
        const pagination = { page: 1, pageSize: 1 };
        tool.getReportItems(report.id, pagination).then((res) => {
            setLoading(false);
        });
    }, [report]);

    // Bail if no report id found
    if (!report) {
        return null;
    }

    const ReportItems = () => {
        return reportItems.map((item) => {
            const reportItemNo = 1;
            return <ReportItem key={item.id} item={item} index={reportItemNo} />;
        });
    };

    // Bail if no report items found
    if (!reportItems || reportItems.length === 0) return null;

    return (
        <>
            <div className='results'>
                <div className='resultsHeader'>
                    <h4 className='heading'>Single Video</h4>
                    <p className='loading'>{loading && <LoadingIcon />}</p>
                </div>
                <ReportItems />
            </div>
        </>
    );
};

ResultsSingleVideo.propTypes = {
    tool: PropTypes.object.isRequired,
};

export default ResultsSingleVideo;
