import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { SelectMultiple } from '@/components/ui/FormFields';
import classnames from 'classnames';

const MetaSelect = ({ products = [], metaField, onChange }) => {
    // State
    const [selectOptions, setSelectOptions] = useState([]);

    // Sort Meta Options Alphabetically
    useLayoutEffect(() => {
        // Get all product meta options
        const nextSelectOptions = [];
        products.forEach((product) => {
            if (product.meta && product.meta[metaField.metaKey]) {
                const productMetaEntries = product.meta[metaField.metaKey];
                productMetaEntries.forEach((entry) => {
                    if (!nextSelectOptions.find((option) => option.value === entry[metaField.entryIdKey])) {
                        nextSelectOptions.push({
                            value: entry[metaField.entryIdKey],
                            name: entry[metaField.entryNameKey],
                        });
                    }
                });
            }
        });

        // Sort Meta
        nextSelectOptions.sort((a, b) => {
            const nameA = a.name.toLowerCase(),
                nameB = b.name.toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
        });

        setSelectOptions(nextSelectOptions);
    }, [products, metaField]);

    // On Change
    const onSelectionChange = (keys) => {
        const selectedValues = selectOptions.filter((option) => keys.includes(option.value)).map((option) => option.value);
        onChange({ ...metaField, values: selectedValues });
    };

    return (
        <div className={classnames('ProductSelect')}>
            <SelectMultiple
                field={{
                    options: selectOptions,
                    value: metaField.values,
                    onChange: (name, value) => onSelectionChange(value),
                    summaryLabels: {
                        blank: `Select ${metaField.metaName}`,
                        multiple: `{count} ${metaField.metaName} Selected`,
                        all: `All ${metaField.metaName}`,
                    },
                }}
            />
        </div>
    );
};

MetaSelect.propTypes = {
    products: PropTypes.array.isRequired,
    metaField: PropTypes.shape({
        metaKey: PropTypes.string.isRequired,
        metaName: PropTypes.string.isRequired,
        entryIdKey: PropTypes.string.isRequired,
        entryNameKey: PropTypes.string.isRequired,
        values: PropTypes.array.isRequired,
    }),
    onChange: PropTypes.func.isRequired,
};

export default MetaSelect;
