import React from "react"
import {Navigate, useLocation} from 'react-router-dom'
import {redirects} from "./_redirects";

const RedirectHandler = () => {

    // useLocation
    const location = useLocation();
    const pathname = location.pathname;
    const search = location.search;

    // Add trailing slash if missing and redirect
    if( !pathname.endsWith("/") ) {
        const pathWithTrailingSlash = pathname.replace(/\/?$/, '/');
        return <Navigate to={pathWithTrailingSlash + search}/>
    }

    // Make any necessary redirects
    for (const redirect of redirects){
        if( pathname === redirect.from ){
            return <Navigate to={redirect.to} />
        }
    }

    return "";
}


export default RedirectHandler;