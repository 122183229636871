import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { SelectMultiple } from '@/components/ui/FormFields';
import classnames from 'classnames';

const ProductSelect = ({ products = [], selectedProductIDs = [], onChange }) => {
    // State
    const [selectOptions, setSelectOptions] = useState([]);

    // Create Products Options
    useLayoutEffect(() => {
        // Sort Products
        const sortedProducts = [...products].sort((a, b) => {
            const nameA = a.name.toLowerCase(),
                nameB = b.name.toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
        });

        // Create Options List
        let nextSelectOptions = sortedProducts.map((product) => {
            return {
                value: product.productID,
                name: product.name,
            };
        });

        // Add Special option for missing/deleted products
        nextSelectOptions.unshift({
            value: 'no-products',
            name: '[Sales Without Products]',
        });

        setSelectOptions(nextSelectOptions);
    }, [products]);

    // On Change
    const onSelectionChange = (keys) => {
        const nextSelectedProductIDs = selectOptions.filter((option) => keys.includes(option.value)).map((option) => option.value);
        onChange(nextSelectedProductIDs);
    };

    return (
        <div className={classnames('ProductSelect')}>
            <SelectMultiple
                field={{
                    options: selectOptions,
                    value: selectedProductIDs,
                    onChange: (name, value) => onSelectionChange(value),
                    summaryLabels: {
                        blank: 'Select Products',
                        multiple: '{count} Products Selected',
                        all: 'All Products',
                    },
                }}
            />
        </div>
    );
};

ProductSelect.propTypes = {
    products: PropTypes.array.isRequired,
    selectedProductIDs: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default ProductSelect;
