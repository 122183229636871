import { createSlice } from '@reduxjs/toolkit';
import { actionResetStore } from '../actions';

const initialState = [];

const slice = createSlice({
    name: 'users',
    initialState: initialState,
    reducers: {
        fetchedAll: (state, action) => {
            return action.payload;
        },
        fetchedOne: (state, action) => {
            const index = state.findIndex((entity) => {
                return entity.id === action.payload.id;
            });
            if (index === -1) {
                state.push(action.payload);
            } else {
                state[index] = action.payload;
            }
            return state;
        },
        deletedOne: (state, action) => {
            return state.filter((entity) => {
                return entity.id !== action.payload.id;
            });
        },
    },
    extraReducers: (builder) => {
        builder.addCase(actionResetStore, (state, action) => {
            return initialState;
        });
    },
});

// Actions
export const { fetchedAll, fetchedOne, deletedOne } = slice.actions;

// Reducer
export default slice.reducer;
