import React from 'react';
import PropTypes from 'prop-types';
import ReportStatWrapper from '@/components/metrics-account/reports/ui/ReportStatWrapper/ReportStatWrapper';
import { formatNumberWithCommas } from '@/util/format';
import dateFormat from 'dateformat';

const SummaryStats = ({ keywordsSummaries, periods, selectedKeywords }) => {
    const generateTableEntries = () => {
        const percentageChange = (index) => {
            if (index === 0) {
                return <td>-</td>;
            } else {
                const percentageChange =
                    keywordsSummaries[index].totalPosts !== 0
                        ? Math.round(
                              -(
                                  ((keywordsSummaries[index].totalPosts - keywordsSummaries[0].totalPosts) / keywordsSummaries[index].totalPosts) *
                                  100
                              ) * 100
                          ) / 100
                        : 0;
                return <td className={percentageChange >= 0 ? 'green' : 'red'}>{percentageChange} %</td>;
            }
        };

        return keywordsSummaries.map((summary, key) => {
            const period = periods[key];
            const startDate = new Date(period.startTime);
            const endDate = new Date(period.endTime);

            return (
                <tbody key={key}>
                    <tr>
                        <td>
                            {dateFormat(startDate, "d mmm 'yy")} - {dateFormat(endDate, "d mmm 'yy")}
                        </td>
                        <td>
                            <strong>{formatNumberWithCommas(summary.totalPosts)}</strong>
                        </td>
                        <td>
                            {selectedKeywords.map((keyword, key) => {
                                if (summary.keywords.hasOwnProperty(keyword)) {
                                    return (
                                        <span key={key}>
                                            <span>
                                                {keyword} ({summary.keywords[keyword].total})
                                            </span>
                                            {key < selectedKeywords.length - 1 && <span className='delimiter'>|</span>}
                                        </span>
                                    );
                                }
                            })}
                        </td>
                        {percentageChange(key)}
                    </tr>
                </tbody>
            );
        });
    };

    return (
        <div className='reportStats'>
            <ReportStatWrapper className={'summaryStats'}>
                <table>
                    <thead>
                        <tr>
                            <th>Period</th>
                            <th>Total Posts</th>
                            <th>Keyword Hits</th>
                            <th>% Change</th>
                        </tr>
                    </thead>
                    {generateTableEntries()}
                </table>
            </ReportStatWrapper>
        </div>
    );
};

SummaryStats.propTypes = {
    keywordsSummaries: PropTypes.array.isRequired,
    periods: PropTypes.array.isRequired,
    selectedKeywords: PropTypes.array.isRequired,
};

export default SummaryStats;
