import * as api from "@/services/strapi/rest";
import {logError} from "@/util/debugger";

/** Define collection type basename */
const basename = "metrics-accounts";

/** Get import csv template for collection type */
export const getDataImportSchema = async (id, body = new FormData()) => {

    const response = await api.restStrapiPostRequest(`${basename}/${id}/data-import-template/`, true, body);
    if( response.error ){
        logError(response);
        return {};
    }else{
        return response;
    }
}

/** Get data import entries */
export const getDataImportEntries = async (id) => {

    const response = await api.restStrapiGetRequest(`${basename}/${id}/data-imports/`, true);
    if( response.error ){
        logError(response);
        return [];
    }else{
        return response.data;
    }
}

/** Start data import */
export const startDataImport = async (id, dataImportID) => {

    const response = await api.restStrapiPostRequest(`${basename}/${id}/start-data-import/${dataImportID}/`, true);
    if( response.error ){
        logError(response);
        return {};
    }else{
        return response;
    }
}

/** Create data import entry */
export const createDataImport = async (id, body = new FormData()) => {

    const response = await api.restStrapiPostRequest(`${basename}/${id}/data-import/`, true, body);
    if( response.error ){
        logError(response);
        return {};
    }else{
        return response;
    }
}

/** Delete data import */
export const deleteDataImport = async (id, dataImportID) => {

    const response = await api.restStrapiDeleteRequest(`${basename}/${id}/data-import/${dataImportID}/`, true);
    if( response.error ){
        logError(response);
        return {};
    }else{
        return response;
    }
}