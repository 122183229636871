/** Convert array of data to FormData object */
export const toFormData = (fields) => {
    const formData = new FormData();
    fields.forEach( (field) => {
        if( field.hasOwnProperty('file') ){
            formData.append( field.name, field.file, field.fileName );
        }else{
            formData.append( field.name, field.value );
        }
    });
    return formData;
}