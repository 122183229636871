import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectorToolSettings } from '@/store/slices/tools';
import { useIsMounted } from '@/util/hooks';
import LogViewer from '../partials/LogViewer/LogViewer';
import { Select } from '@/components/ui/FormFields';
import { LoadingIcon } from '@/components/ui';
import styles from './ToolWrapper.module.scss';

const ToolWrapper = ({ tool }) => {
    // State
    const [fetching, setFetching] = useState(true);

    // Hooks
    const isMounted = useIsMounted();

    // Effect
    useLayoutEffect(() => {
        tool.getSettings().then(() => {
            isMounted && setFetching(false);
        });
    }, []);

    // Show loading icon if fetching
    if (fetching) {
        return <LoadingIcon />;
    }

    return (
        <div className={styles.module}>
            <div className='main'>
                <LogViewer tool={tool} />
            </div>
        </div>
    );
};

ToolWrapper.propTypes = {
    tool: PropTypes.object.isRequired,
};

export default ToolWrapper;
