import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as endpoints from './_endpoints';

const PrivateRoute = ({ element: Component }) => {
    const user = useSelector((state) => state.auth.user);

    return user ? Component : <Navigate to={{ pathname: endpoints.LOGIN }} />;
};

PrivateRoute.propTypes = {
    element: PropTypes.object.isRequired,
};

export default PrivateRoute;
