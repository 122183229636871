import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./FilterWrapper.module.scss"

const FilterWrapper = ({children}) => {

    const hasLabel = children.length >= 2;

    return (
        <div className={classnames("FilterWrapper", styles.module)}>
            {hasLabel && <div className="filterLabel">{children[0]}</div>}
            <div className="filter">
                {hasLabel ? children[1] : children}
            </div>
        </div>
    )

}

FilterWrapper.propTypes = {
    children: PropTypes.node
}

export default FilterWrapper;