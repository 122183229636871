import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import * as Filters from '@/components/metrics-account/filters';
import { reduceFilters, matchAgainstCachedFilters, initFilters, updateFilters } from '@/services/filters';
import { salesTypeOptions } from '../constants';
import { countries, regions, currencies } from '@/components/metrics-account/filters/constants';
import { clearTimezoneFromDate } from '@/util/format';

const ReportFilters = ({ account, filtersKey, options }) => {
    // Reducers
    const [filters, dispatchFilters] = useReducer(
        reduceFilters,
        matchAgainstCachedFilters(filtersKey, {
            productIDs: [],
            categories: {
                metaKey: 'categories',
                metaName: 'Categories',
                entryIdKey: 'id',
                entryNameKey: 'name',
                values: [],
            },
            countries: [],
            currencies: [],
            types: [],
            period: {
                startTime: clearTimezoneFromDate(new Date(new Date().setHours(0, 0, 0, 0))).getTime(),
                endTime: clearTimezoneFromDate(new Date(new Date().setHours(0, 0, 0, 0))).getTime(),
                presetID: 'this-month',
                presetLabel: '',
            },
        }),
        initFilters
    );

    // On Filter Change
    const onFilterChange = (fieldID, value) => {
        dispatchFilters({ type: 'update', payload: { fieldID: fieldID, value: value } });
    };

    // On Apply Filters
    const onApplyFilters = () => {
        updateFilters(filtersKey, filters);
    };

    return (
        <div className='reportFilters reportFilterGroup'>
            <Filters.FilterWrapper>
                <strong>Products</strong>
                <Filters.ProductSelect
                    products={options.products}
                    selectedProductIDs={filters.productIDs}
                    onChange={(value) => onFilterChange('productIDs', value)}
                />
            </Filters.FilterWrapper>

            <Filters.FilterWrapper>
                <strong>Categories</strong>
                <Filters.MetaSelect
                    products={options.products}
                    metaField={filters.categories}
                    onChange={(value) => onFilterChange('categories', value)}
                />
            </Filters.FilterWrapper>

            <Filters.FilterWrapper>
                <strong>Sales Type</strong>
                <Filters.SalesTypeSelect
                    salesTypeOptions={salesTypeOptions}
                    selectedTypeValues={filters.types}
                    onChange={(value) => onFilterChange('types', value)}
                />
            </Filters.FilterWrapper>

            <Filters.FilterWrapper>
                <strong>Currency</strong>
                <Filters.CurrencySelect
                    currencyOptions={currencies}
                    selectedCurrencies={filters.currencies}
                    onChange={(value) => onFilterChange('currencies', value)}
                />
            </Filters.FilterWrapper>

            <Filters.FilterWrapper>
                <strong>Countries</strong>
                <Filters.CountrySelect
                    countryOptions={countries}
                    regionOptions={regions}
                    selectedCountryValues={filters.countries}
                    onChange={(value) => onFilterChange('countries', value)}
                />
            </Filters.FilterWrapper>

            <Filters.FilterWrapper>
                <>
                    <strong>Date Range:</strong> {filters.period.presetLabel}
                </>
                <Filters.DateRange dateRange={filters.period} onChange={(value) => onFilterChange('period', value)} />
            </Filters.FilterWrapper>

            <Filters.FilterWrapper>
                <button className='btnApplyFilters' onClick={onApplyFilters}>
                    Generate
                </button>
            </Filters.FilterWrapper>
        </div>
    );
};

ReportFilters.propTypes = {
    account: PropTypes.object.isRequired,
    filtersKey: PropTypes.string.isRequired,
    options: PropTypes.object,
};

export default ReportFilters;
