import * as api from '@/services/strapi/rest';
import { logError } from '@/util/debugger';

/** Define collection type basename */
const basename = 'metrics-accounts';

/** Default Query */
export const defaultQuery = {
    populate: '*',
    pagination: {
        page: 1,
        pageSize: 1000,
    },
};

/** Get all collection entries and update the store */
export const getAccountSales = async (account, body = new FormData(), query = {}) => {
    let pageCount = 1;
    let page = 1;
    const allItems = [];

    while (pageCount >= page) {
        const queryString = api.createQueryString({ ...defaultQuery, pagination: { ...defaultQuery.pagination, page: page } }, query);
        const response = await api.restStrapiPostRequest(`${basename}/${account.id}/sales/?${queryString}`, true, body);
        if (!response.error) {
            allItems.push(...response.results);
            pageCount = response.pagination.pageCount;
        } else {
            logError(response);
        }
        page++;
    }

    return allItems;
};
