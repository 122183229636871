import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectorToolSetting } from '@/store/slices/tools';

const ToolStatus = ({ tool }) => {
    const [pollingTimer, setPollingTimer] = useState(null);

    // Ref for cleanup
    const pollingTimerRef = useRef(null);

    // Hooks
    useEffect(() => {
        tool.getSettings().then();

        if (pollingTimerRef.current) {
            clearInterval(pollingTimerRef.current);
        }

        // Keep polling API Quota Remaining
        const timer = setInterval(() => {
            tool.getSettings().then();
        }, 10000);

        setPollingTimer(timer);
        pollingTimerRef.current = timer;

        // Cleanup
        return () => {
            clearInterval(pollingTimerRef.current);
        };
    }, []);

    // Selector
    const apiQuotaRemaining = useSelector((state) => selectorToolSetting(state, tool.uid, 'apiQuotaRemaining'));

    return <span>Daily API Quota Remaining: {apiQuotaRemaining}</span>;
};

ToolStatus.propTypes = {
    tool: PropTypes.object.isRequired,
};

export default ToolStatus;
