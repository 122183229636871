import React, { Fragment, useLayoutEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getAllUsers } from '@/services/strapi/users';
import { useIsMounted } from '@/util/hooks';
import { Select, SelectMultiple } from '@/components/ui/FormFields';
import { LoadingIcon, SegmentLine } from '@/components/ui';
import styles from './ScreenManageAccountUsers.module.scss';

const ScreenManageAccountUsers = ({ handlers, policies }) => {
    // State
    const [fetching, setFetching] = useState(true);
    const [accountUsers, setAccountUsers] = useState([]);
    const [addUserSelectValue, setAddUserSelectValue] = useState(null);
    const [accountUserPermissions, setAccountUserPermissions] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);

    // Selectors
    const globalUsers = useSelector((state) => state.users);

    // Hooks
    const isMounted = useIsMounted();

    // Use Layout Effect
    useLayoutEffect(() => {
        removeErrorMessage();
        setFetching(true);

        const promises = [];

        // Get all app users
        promises.push(getAllUsers().then());

        // Get all account users
        if (handlers.getUsers) {
            promises.push(
                handlers.getUsers().then((data) => {
                    isMounted() && setAccountUsers(data);
                })
            );
        } else {
            setErrorMessage('No "getUsers" handler provided');
        }

        // Get account user permissions
        if (handlers.getAllUsersPermissions) {
            promises.push(
                handlers.getAllUsersPermissions().then((data) => {
                    isMounted() && setAccountUserPermissions(data);
                })
            );
        } else {
            setErrorMessage('No "getAllUsersPermissions" handler provided');
        }

        Promise.all(promises).then(() => {
            isMounted() && setFetching(false);
        });
    }, [handlers]);

    // User Select Options
    const userSelectOptions = useMemo(() => {
        return globalUsers
            .filter((user) => {
                for (let i = 0; i < accountUsers.length; i++) {
                    const accountUser = accountUsers[i];
                    if (accountUser.id === user.id) {
                        return false;
                    }
                }
                return true;
            })
            .map((user) => {
                return {
                    value: user.id,
                    name: user.email,
                };
            });
    }, [accountUsers, globalUsers]);

    // Remove Error Message
    const removeErrorMessage = () => {
        setErrorMessage(null);
    };

    // Handler: Add User
    const onAddUser = () => {
        if (!handlers.addUser) {
            setErrorMessage('No "addUser" handler provided');
            return;
        }

        if (addUserSelectValue) {
            handlers.addUser(addUserSelectValue.toString()).then((data) => {
                isMounted() && setAccountUsers(data);
            });
        }
    };

    // Handler: Remove User
    const onRemoveUser = (user) => {
        if (!handlers.removeUser) {
            setErrorMessage('No "removeUser" handler provided');
            return;
        }

        handlers.removeUser(user.id.toString()).then((data) => {
            isMounted() && setAccountUsers(data);
            setAccountUserPermissions((prevState) => {
                const nextState = { ...prevState };
                delete nextState[user.id];
                return nextState;
            });
        });
    };

    // Handler: User Policy Change
    const onUserPolicyChange = (user, values) => {
        const nextUserPermissionsObject = { ...accountUserPermissions };
        // If user does not exist in userPolicies, add them
        if (!nextUserPermissionsObject.hasOwnProperty(user.id)) nextUserPermissionsObject[user.id] = {};
        // Set email
        nextUserPermissionsObject[user.id]['email'] = user.email;
        // Set policies
        nextUserPermissionsObject[user.id]['policies'] = values;
        setAccountUserPermissions(nextUserPermissionsObject);
    };

    // Handler: Commit User Policies
    const onCommitUserPolicies = (user) => {
        if (!handlers.setUserPermissions) {
            setErrorMessage('No "setUserPolices" handler provided');
            return;
        }
        if (accountUserPermissions.hasOwnProperty(user.id)) {
            setFetching(true);
            handlers.setUserPermissions(user.id.toString(), accountUserPermissions[user.id]).then(isMounted() && setFetching(false));
        }
    };

    if (fetching) {
        return <LoadingIcon />;
    }

    // Component: Policy Select
    const policySelect = (user) => {
        const options = policies.map((policy) => {
            return {
                value: policy.key,
                name: policy.name,
            };
        });

        let selectedValues = [];
        if (accountUserPermissions.hasOwnProperty(user.id) && accountUserPermissions[user.id]['policies']) {
            selectedValues = accountUserPermissions[user.id]['policies'];
        }

        return (
            <SelectMultiple
                field={{
                    options: options,
                    value: selectedValues,
                    onChange: (name, value) => onUserPolicyChange(user, value),
                    onBlur: () => onCommitUserPolicies(user),
                    summaryLabels: {
                        blank: 'No Permissions',
                        single: '1 Permission',
                        multiple: '{count} Permissions',
                        all: '{count} Permissions',
                    },
                }}
            />
        );
    };

    // Account User Rows
    const accountUsersRows = accountUsers.map((user, key) => {
        return (
            <Fragment key={key}>
                <div className='gridItem itemStyleCell'>{user.email}</div>
                <div className='gridItem itemStyleCell'>{user.role.name}</div>
                <div className='gridItem itemStyleCell rightAlign permissionsWrapper'>
                    <div>Permissions: {policySelect(user)}</div>
                </div>
                <div className='gridItem itemStyleCell centerAlign'>
                    <span className='editAction' onClick={() => onRemoveUser(user)}>
                        Remove
                    </span>
                </div>
            </Fragment>
        );
    });

    return (
        <div className={styles.module}>
            {errorMessage && <p>{errorMessage}</p>}

            <h2>Manage Users</h2>
            <p>Configure access and permissions of users to this account.</p>

            <SegmentLine />

            <h4>Add Users to Account</h4>
            <p>Users must be first created before they can be added to an account.</p>
            <div className='userAddWrapper'>
                <Select field={{ options: userSelectOptions, value: addUserSelectValue, onChange: (name, value) => setAddUserSelectValue(value) }} />
                <button className='addUserButton' onClick={onAddUser}>
                    Add User
                </button>
            </div>

            <SegmentLine />

            <h4>User Access Control</h4>
            <div className='userTable'>
                <div className='gridItem itemStyleHeader'>Email</div>
                <div className='gridItem itemStyleHeader'>Role</div>
                <div className='gridItem itemStyleHeader centerAlign'>Permissions</div>
                <div className='gridItem itemStyleHeader centerAlign'>Edit</div>
                {accountUsersRows}
            </div>
        </div>
    );
};

ScreenManageAccountUsers.propTypes = {
    handlers: PropTypes.shape({
        getUsers: PropTypes.func,
        addUser: PropTypes.func,
        removeUser: PropTypes.func,
        getAllUsersPermissions: PropTypes.func,
        setUserPermissions: PropTypes.func,
    }),
    policies: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            name: PropTypes.string,
        })
    ),
};

export default ScreenManageAccountUsers;
