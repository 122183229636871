import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { processVideoArray } from '@/components/metrics-account/reports/_youTubeAnalytics';
import { secondsToMs, minutesToHm, formatDateString, approximateIntegerWithPrefix } from '@/util/format';

const VideoList = ({ videos, sales, analyticsDay, salesDays }) => {
    const processedVideos = useMemo(() => {
        const sortedSales = [...sales].sort((a, b) => {
            const dateA = new Date(a.datetime);
            const dateB = new Date(b.datetime);
            return dateA - dateB;
        });

        const sortedVideos = [...videos].sort((a, b) => {
            const dateA = new Date(a.datetime);
            const dateB = new Date(b.datetime);
            return dateB - dateA;
        });

        return processVideoArray(sortedVideos, sortedSales, analyticsDay, salesDays);
    }, [videos, sales, analyticsDay, salesDays]);

    const videoEntries = processedVideos.map((video) => {
        const preHeaderStats = `${analyticsDay}d`;
        const preHeaderSales = `${salesDays}d`;

        const netSubscriberClass = video.analytics.netSubscribers >= 0 ? 'green' : 'red';
        const likeDislikeRatioClass = video.analytics.netSubscribers >= 0.9 ? 'green' : 'red';
        const salesDiffClass = video.salesDiff > 0 ? 'green' : 'red';

        return (
            <div className='videoItem' key={video.videoId}>
                <div className='section info'>
                    <a href={video.url}>
                        <img src={video.thumbnailUrl} />
                        <h6 className='header'>{video.title}</h6>
                    </a>
                    <p className='date'>{formatDateString(new Date(video.publishDate))}</p>
                </div>

                <div className='section view-data'>
                    <h6 className='header'>Viewing Data ({preHeaderStats})</h6>
                    <div className='statTable'>
                        <div className='cell header'>Total Views</div>
                        <div className='cell data'>{approximateIntegerWithPrefix(video.analytics.views)}</div>

                        <div className='cell header'>Avg. View Duration</div>
                        <div className='cell data'>{secondsToMs(video.analytics.averageViewDuration)}</div>

                        <div className='cell header'>Avg. View Duration</div>
                        <div className='cell data'>{video.analytics.averageViewPercentage}%</div>

                        <div className='cell header'>Total Minutes Watched</div>
                        <div className='cell data'>{minutesToHm(video.analytics.estimatedMinutesWatched)}</div>
                    </div>
                </div>

                <div className='section socials-data'>
                    <h6 className='header'>Socials Data ({preHeaderStats})</h6>
                    <div className='statTable'>
                        <div className='cell header'>Subscribers</div>
                        <div className='cell data'>
                            <span className='green'>+{video.analytics.subscribersGained}</span>
                            <span className='delimiter'>/</span>
                            <span className='red'>-{video.analytics.subscribersLost}</span>
                            <span className={netSubscriberClass}>({video.analytics.netSubscribers})</span>
                        </div>

                        <div className='cell header'>Likes/Dislikes</div>
                        <div className='cell data'>
                            <span className='green'>{approximateIntegerWithPrefix(video.analytics.likes)}</span>
                            <span className='delimiter'>/</span>
                            <span className='red'>{approximateIntegerWithPrefix(video.analytics.dislikes)}</span>
                            <span className={likeDislikeRatioClass}>({video.analytics.likeDislikeRatio * 100}%)</span>
                        </div>

                        <div className='cell header'>Comments</div>
                        <div className='cell data'>{video.analytics.comments}</div>

                        <div className='cell header'>Shares</div>
                        <div className='cell data'>{video.analytics.shares}</div>
                    </div>
                </div>

                <div className='section sales-data'>
                    <h6 className='header'>EDD Sales Data ({preHeaderSales})</h6>
                    <div className='statTable'>
                        <div className='cell header'>Pre Sales</div>
                        <div className='cell data'>{video.preSales.length}</div>

                        <div className='cell header'>Post Sales</div>
                        <div className='cell data'>{video.postSales.length}</div>

                        <div className='cell header'>Sales Diff.</div>
                        <div className='cell data'>
                            <span className={salesDiffClass}>{video.salesDiff}%</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    return <div className='videoList'>{videoEntries}</div>;
};

VideoList.propTypes = {
    videos: PropTypes.array.isRequired,
    sales: PropTypes.array.isRequired,
    analyticsDay: PropTypes.number.isRequired,
    salesDays: PropTypes.number.isRequired,
};

export default VideoList;
