export const exportTemplates = [
    {
        key: "payment",
        templates: [
            {
                name: "Default",
                value: "default"
            },
            {
                name: "Line Items",
                value: "line-items"
            },
            // {
            //     name: "Quaderno Invoice Import",
            //     value: "quaderno-invoice-import"
            // }
        ]
    },
    {
        key: "download",
        templates: [
            {
                name: "Default",
                value: "default"
            }
        ]
    }
]


export const eddDataTypes = [

    {
        name: "Payments",
        value: "payment",
        fields: [
            {
                name: "Total",
                value: "total"
            },
            {
                name: "Status",
                value: "status"
            },
            {
                name: "Payment Gateway",
                value: "gateway"
            },
            {
                name: "Product Info",
                value: "products",
            },
            {
                name: "Customer Email",
                value: "email"
            },
            {
                name: "Billing First Name",
                value: "first_name"
            },
            {
                name: "Billing Last Name",
                value: "last_name"
            },
            {
                name: "Billing Address",
                value: "billing_address"
            },
            {
                name: "Billing City",
                value: "billing_city"
            },
            {
                name: "Billing State",
                value: "billing_state"
            },
            {
                name: "Billing Country",
                value: "billing_country"
            },
            {
                name: "Billing ZIP",
                value: "billing_zip"
            },
            {
                name: "Shipping Recipient",
                value: "shipping_recipient"
            },
            {
                name: "Shipping Phone",
                value: "shipping_phone"
            },
            {
                name: "Shipping Address",
                value: "shipping_address"
            },
            {
                name: "Shipping City",
                value: "shipping_city"
            },
            {
                name: "Shipping State",
                value: "shipping_state"
            },
            {
                name: "Shipping Country",
                value: "shipping_country"
            },
            {
                name: "Shipping ZIP",
                value: "shipping_zip"
            },
            {
                name: "Device IP",
                value: "device_ip"
            },
        ]
    },

    {
        name: "Downloads",
        value: "download",
        fields: [
            {
                name: "Status",
                value: "status"
            },
            {
                name: "Publish Date",
                value: "date"
            },
            {
                name: "SKU",
                value: "sku"
            },
            {
                name: "Categories",
                value: "categories",
            }
        ]
    }

]