import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { SelectMultiple } from '@/components/ui/FormFields';

const GroupSelector = ({ options, selectedOptions, onChange }) => {
    return (
        <SelectMultiple
            field={{
                options: options,
                value: selectedOptions,
                onChange: (name, value) => onChange(value),
                summaryLabels: {
                    blank: 'Select Groups',
                    multiple: '{count} Groups Selected',
                    all: 'All Groups',
                },
            }}
        />
    );
};

GroupSelector.propTypes = {
    options: PropTypes.array.isRequired,
    selectedOptions: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default GroupSelector;
