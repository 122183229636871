import React from 'react';
import PropTypes from 'prop-types';
import ProgressBar from '../ProgressBar';

const BodyKeywordBatch = ({ report }) => {
    // Deconstruct
    const { parameters } = report;

    const Snippet = () => {

        const {keywords} = parameters;
        const keywordArr = keywords.split('\n');

        return (
            <div className='snippet'>
                <p className='snippterHeader'>
                    <span className='snipptTitle'>{keywordArr.length} Terms</span>
                    <ProgressBar report={report} />
                </p>
            </div>
        );
    };

    const Stats = () => {
        return (
            <p className='stats'>
                <span>Keyword (Batch)</span>
                <span className='delimiter'>|</span>
                <span>{parameters.videosNumber}</span>
            </p>
        );
    };

    return (
        <>
            <Snippet />
            <Stats />
        </>
    );
};

BodyKeywordBatch.propTypes = {
    report: PropTypes.object.isRequired,
};

export default BodyKeywordBatch;
