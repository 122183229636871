import * as classes from "./classes";

export const types = [
    {
        id: 'edd-wp-sales',
        name: 'EDD WordPress Sales',
        integrations: [
            "integrations.easy-digital-downloads"
        ],
        templateClass: classes.Account_EDD_WP_Sales,
    },
    {
        id: 'social-media-listening',
        name: 'Social Media Listening',
        integrations: [
            "integrations.social-media-listening"
        ],
        templateClass: classes.Account_Social_Media_Listening,
    },
    {
        id: 'youtube-edd-analytics',
        name: 'YouTube to EDD Analytics',
        integrations: [
            "integrations.easy-digital-downloads",
            "integrations.youtube-analytics-and-reporting"
        ],
        templateClass: classes.Account_YouTube_EDD_Analytics
    }
]

export const getIntegrationIDs = (typeID ) => {
    const type = types.find( (entry) => entry.id === typeID );
    return type ? type.integrations : [];
}

export const getTemplate = ( typeID ) => {
    const type = types.find( (entry) => entry.id === typeID );
    return type ? type.template : null;
}

export const getTemplateClass = ( typeID ) => {
    const type = types.find( (entry) => entry.id === typeID );
    return type ? type.templateClass : classes.Account;
}

export const getTypesAsSelectOptions = () =>{
    return types.map( (type) => {
            return {
                name: type.name,
                value: type.id
            }
    })
}