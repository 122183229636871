import React from "react";
import PropTypes from "prop-types";
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";

const ReportChart = ({chartEntries, selectedChartSalesTypeOptions}) => {

    // Chart Tooltip
    const CustomTooltip = ({ active, payload, label }) => {

        if( !payload || !payload.length ) return "";

        const getLegends = selectedChartSalesTypeOptions.map( (salesTypeOption, key) => {
            const statusPayload = payload.find( (payloadStatus) => { return payloadStatus.dataKey === salesTypeOption.key } );
            if( statusPayload ) {
                return (
                    <p key={key}>{`${salesTypeOption.label}: ${statusPayload.value}`}</p>
                )
            }else{
                return (
                    <p key={key}>{`${salesTypeOption.label}: 0`}</p>
                )
            }
        } );

        if (active && payload && payload.length) {
            return (
                <div className="tooltip">
                    <p className="label">{`${label}`}</p>
                    {getLegends}
                </div>
            );
        }
        return null;
    };

    // Chart Lines
    const generateSalesLines = selectedChartSalesTypeOptions.map( (salesTypeOption, key) => {
        return(
            <Line
                key={key}
                name={salesTypeOption.label}
                yAxisId="right"
                type="monotone"
                dataKey={salesTypeOption.key}
                opacity="0.5"
                stroke={salesTypeOption.fill}
            />
        )
    })

    return (
        <div className="chart">
            <ResponsiveContainer width="100%" height={500}>
                <LineChart
                    width={500}
                    height={600}
                    data={chartEntries}
                    margin={{
                        top: 10,
                        right: 0,
                        left: 0,
                        bottom: 10,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="datetime" height={35} />
                    <YAxis yAxisId="left" orientation="left" mirror={true} tickCount={10} />
                    <YAxis yAxisId="right" orientation="right" tickCount={10} />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend />
                    <Line
                        name="Revenue"
                        yAxisId="left"
                        type="monotone"
                        dataKey="revenue"
                        stroke="#35a1d6"
                        strokeWidth="3"
                        activeDot={{ r: 8 }}
                    />
                    {generateSalesLines}
                </LineChart>
            </ResponsiveContainer>
        </div>
    )

}

ReportChart.propTypes = {
    chartEntries: PropTypes.array.isRequired,
    selectedChartSalesTypeOptions: PropTypes.array.isRequired,
}

export default ReportChart;