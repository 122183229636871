import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectorReport } from '@/store/slices/reports';
import { Select } from '@/components/ui/FormFields';
import ModeWrapper from '../modes/ModeWrapper';
import ReportList from '../partials/sidebar/ReportList/ReportList';
import { modes } from '../modes/modeManifest';
import styles from './ToolWrapper.module.scss';

const ToolWrapper = ({ tool }) => {
    // Router
    const navigate = useNavigate();
    const { reportId } = useParams();
    const location = useLocation();

    // State
    const [mode, setMode] = useState('channel');

    // Selector
    const selectedReport = useSelector((state) => selectorReport(state, tool.uid, reportId));

    // Effect: Set mode if report is selected
    useEffect(() => {
        if (selectedReport) {
            setMode(selectedReport.parameters.mode);
        }
    }, [selectedReport]);

    // Handler: Mode Change
    const onModeChange = (name, value) => {
        setMode(value);
        navigate(tool.url);
    };

    // Handler: New Report
    const onNewReport = () => {
        navigate(tool.url);
    };

    // Handler: Report Select
    const navigateToReport = (reportId) => {
        if (reportId) {
            navigate({
                pathname: `${tool.url}${reportId}/`,
                search: location.search,
            });
        }
    };

    // Mode Options
    const modeOptions = modes.map((mode) => {
        return {
            value: mode.id,
            name: mode.title,
            component: <ModeWrapper modeId={mode.id} tool={tool} />,
        };
    });

    return (
        <div className={styles.module}>
            <div className='main'>
                <div className='actions'>
                    <Select
                        field={{
                            name: 'mode',
                            value: mode,
                            onChange: onModeChange,
                            options: modeOptions,
                            required: true,
                            label: 'Mode',
                            hideRequiredSign: true,
                        }}
                    />
                    <button className='new-report-button' onClick={onNewReport}>
                        New Report
                    </button>
                </div>
                <div className='form'>{modeOptions.find((option) => option.value === mode).component}</div>
            </div>
            <div className='sidebar'>
                <ReportList tool={tool} />
            </div>
        </div>
    );
};

ToolWrapper.propTypes = {
    tool: PropTypes.object.isRequired,
};

export default ToolWrapper;
