import React, { useState, useLayoutEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectorToolSettings } from '@/store/slices/tools';
import { useIsMounted } from '@/util/hooks';
import { LoadingIcon } from '@/components/ui';
import FormHandler from '@/components/ui/FormHandler/FormHandler';
import styles from './AccountSettings.module.scss';

const AccountSettings = ({ tool }) => {
    // States
    const [fetching, setFetching] = useState(true);
    const [error, setError] = useState('');

    // Selector
    const settings = useSelector((state) => selectorToolSettings(state, tool.uid));

    // Hooks
    const isMounted = useIsMounted();

    // Effect
    useLayoutEffect(() => {
        tool.getSettings().then(() => {
            isMounted && setFetching(false);
        });
    }, []);

    // Fields
    const fields = useMemo(() => {
        return [
            {
                name: 'youtube_api_key',
                label: 'YouTube API Key',
                type: 'text',
                required: true,
                wrapperClass: 'wrapper',
                inputClass: 'input',
                value: settings.youtube_api_key || '',
            },
            {
                name: 'submit',
                type: 'submit',
                value: 'Save Settings',
            },
        ];
    }, [settings]);

    // Handlers
    const onFormSubmit = async (data) => {
        setError('');
        setFetching(true);

        const nextSettings = {};
        data.forEach((field) => {
            nextSettings[field.name] = field.value;
        });

        tool.setSettings(nextSettings).then((response) => {
            isMounted && setFetching(false);
            if (response.error) {
                setError(response.error);
                return;
            }
        });
    };

    if (fetching) {
        return <LoadingIcon />;
    }

    return (
        <div className={styles.module}>
            <h3>Tool Settings</h3>
            {error && <p className='error'>{error}</p>}
            <FormHandler
                fields={fields}
                formOptions={{
                    formClass: 'formStyleB',
                }}
                callbacks={{
                    onSubmit: (data) => onFormSubmit(data),
                }}
            />
        </div>
    );
};

AccountSettings.propTypes = {
    tool: PropTypes.object.isRequired,
};

export default AccountSettings;
