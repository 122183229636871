import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FormHandler from '@/components/ui/FormHandler/FormHandler';

const FormKeywordBatch = ({ parameters = {}, onParametersChange, onQuerySubmit }) => {
    // Effect: Set default values
    useEffect(() => {
        const nextParameters = {};
        nextParameters.keywords = parameters.keywords || '';
        nextParameters.videosNumber = parameters.videosNumber || 10;
        nextParameters.submit = parameters.submit || 'Submit';
        onParametersChange(nextParameters);
    }, []);

    // Event Handlers
    const onChange = (name, value) => {
        const nextParameters = { ...parameters };
        nextParameters[name] = value;
        // Trim keywords
        onParametersChange(nextParameters);
    };

    // Constants
    const fields = [
        {
            name: 'keywords',
            label: 'Search terms (one per line)',
            type: 'textarea',
            required: true,
            wrapperClass: 'wrapper xl',
            inputClass: 'input',
            value: parameters.keywords || '',
            popout: true,
        },
        {
            name: 'videosNumber',
            label: 'Videos (per term)',
            type: 'number',
            required: false,
            wrapperClass: 'wrapper sm',
            inputClass: 'input',
            min: 1,
            value: parameters.videosNumber,
        },
        {
            name: 'submit',
            type: 'submit',
            value: parameters.submit,
        },
    ];

    return (
        <div className='queryForm'>
            <FormHandler
                fields={fields}
                formOptions={{
                    formClass: 'formStyleB',
                }}
                callbacks={{
                    onSubmit: (data) => onQuerySubmit(data),
                    onChange: onChange,
                }}
            />
        </div>
    );
};

FormKeywordBatch.propTypes = {
    parameters: PropTypes.object,
    onParametersChange: PropTypes.func.isRequired,
    onQuerySubmit: PropTypes.func.isRequired,
};

export default FormKeywordBatch;
