import React, {useState, useRef, useEffect} from "react";
import PropTypes from "prop-types";
import styles from "./DropdownPanel.module.scss";
import {showByHeight, hideByHeight} from "@/animations";

const DropdownPanel = ({show: initialShow = true, hideButton, labels = {show: "Show", hide: "Hide"}, children}) => {

    // State
    const [show, setShow] = useState(initialShow);

    // Refs
    const panelRef = useRef();

    // Toggle Panel
    const onTogglePanel = () => {
        setShow( !show );
    }

    // Overwrite status via props
    useEffect( () => {
        setShow( initialShow );
    }, [initialShow] )

    // Animation
    useEffect( () => {
        show ? showByHeight(panelRef.current) : hideByHeight(panelRef.current);
    }, [show] )

    return(
        <div className={styles.component}>
            {!hideButton &&
            <p className="label" onClick={onTogglePanel}>
                <span className="icon"><i className="fas fa-bars" /></span>
                {show ? labels.hide : labels.show }
            </p>}
            <div className="panelContent" ref={panelRef}>
                {children}
            </div>
        </div>
    )
}

DropdownPanel.prototype = {
    show: PropTypes.bool,
    hideButton: PropTypes.bool,
    labels: PropTypes.shape({
        show: PropTypes.string,
        hide: PropTypes.string
    }),
    children: PropTypes.node
}

export default DropdownPanel;