import React from "react";
import PropTypes from "prop-types";
import styles from "../Fields.module.scss";
import classnames from "classnames";

const Hidden = ({field}) => {

    return (
        <div ref={field.wrapperRef}
             className={classnames("fieldWrapper typeHidden",
                 styles.typeHidden,
                 field.wrapperClass,
                 !field.value && "empty")
             }
        >
            <div className={classnames("field", field.fieldClass)} >
                <input
                    ref={field.inputRef}
                    id={field.name}
                    className={field.inputClass}
                    name={field.name}
                    value={field.value ? field.value : ""}
                    type="hidden"
                    required={field.required && 'required'}
                    onChange={(e) => field.onChange(field.name, e.target.value)}
                />
                {field.afterInput}
            </div>
        </div>
    );
}

Hidden.propTypes = {
    field: PropTypes.shape({
        // Base props
        name: PropTypes.string,
        value: PropTypes.string,
        required: PropTypes.bool,
        // Ref props
        inputRef: PropTypes.object,
        wrapperRef: PropTypes.object,
        // Callback props
        onChange: PropTypes.func,
        // Class props
        wrapperClass: PropTypes.string,
        fieldClass: PropTypes.string,
        inputClass: PropTypes.string,
        // Other props
        afterInput: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node
        ])
    }),
}

export default Hidden;