import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import FormHandler from '@/components/ui/FormHandler/FormHandler';

const FormKeyword = ({ parameters = {}, onParametersChange, onQuerySubmit }) => {
    // Effect: Set default values
    useLayoutEffect(() => {
        const nextParameters = {};
        nextParameters.keyword = parameters.keyword || '';
        nextParameters.videosNumber = parameters.videosNumber || 10;
        nextParameters.submit = parameters.submit || 'Submit';
        onParametersChange(nextParameters);
    }, []);

    // Event Handlers
    const onChange = (name, value) => {
        const nextParameters = { ...parameters };
        nextParameters[name] = value;
        onParametersChange(nextParameters);
    };

    // Constants
    const fields = [
        {
            name: 'keyword',
            label: 'Seatch term',
            type: 'text',
            required: true,
            wrapperClass: 'wrapper lg',
            inputClass: 'input',
            value: parameters.keyword || '',
        },
        {
            name: 'videosNumber',
            label: 'Number of videos',
            type: 'number',
            required: false,
            wrapperClass: 'wrapper sm',
            inputClass: 'input',
            min: 1,
            value: parameters.videosNumber,
        },
        {
            name: 'submit',
            type: 'submit',
            value: parameters.submit,
        },
    ];

    return (
        <div className='queryForm'>
            <FormHandler
                fields={fields}
                formOptions={{
                    formClass: 'formStyleB',
                }}
                callbacks={{
                    onSubmit: (data) => onQuerySubmit(data),
                    onChange: onChange,
                }}
            />
        </div>
    );
};

FormKeyword.propTypes = {
    parameters: PropTypes.object,
    onParametersChange: PropTypes.func.isRequired,
    onQuerySubmit: PropTypes.func.isRequired,
};

export default FormKeyword;
